import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  AlertDialog,
  CompleteProfileDialog,
  FilledButton,
  OutlinedButton,
  ProfileLayout,
  StandardSelect,
  Switch,
} from '../../components';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { IRootState } from '../../redux/store';
import { apiUpdateProfile } from '../../apis/auth';

interface IBehaviorProfileData {
  use_oil_to_cook: boolean;
  uco_production_period: string;
  recycle_uco: boolean;
  recycle_amount: string;
}

const schema = yup
  .object({
    use_oil_to_cook: yup.boolean().required(),
    uco_production_period: yup.string().required(),
    recycle_uco: yup.boolean().required(),
    recycle_amount: yup.string().required(),
  })
  .required();

export const EditBehaviorProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const auth = useSelector((state: IRootState) => state.auth);

  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, watch, setValue } =
    useForm<IBehaviorProfileData>({
      defaultValues: {
        use_oil_to_cook: auth.ucoOilToCook ?? true,
        uco_production_period: auth.ucoProductionPeriod ?? '',
        recycle_uco: auth.ucoRecycling ?? true,
        recycle_amount: auth.ucoRecyclingAmount ?? '',
      },
      resolver: yupResolver(schema),
    });

  const handleUpdateProfile = async (values: IBehaviorProfileData) => {
    setLoading(true);
    const result = await apiUpdateProfile({
      ...values,
    });
    if (result) {
      CompleteProfileDialog.open({
        amount: result,
        onProfile: () => {
          navigate('/profile');
        },
        onChallenge: () => {
          navigate('/challenges/learn/chapters');
        },
      });
    } else {
      AlertDialog.open({
        message: 'Updated the behavior info successfully.',
        confirmLabel: 'Confirm',
        onConfirm: () => {
          navigate(-1);
        },
      });
    }
    setLoading(false);
  };

  const handleCancel = () => {
    navigate('/profile');
  };

  const [use_oil_to_cook] = watch(['use_oil_to_cook']);

  useEffect(() => {
    if (use_oil_to_cook) {
      setValue('uco_production_period', auth.ucoProductionPeriod ?? 'lt_1');
    } else {
      setValue('uco_production_period', 'lt_1');
    }
  }, [use_oil_to_cook, auth]);

  return (
    <ProfileLayout title="UCO Details" style="light" showBackButton>
      <div className="flex flex-row justify-center">
        <div className="flex w-full max-w-[600px] flex-col px-8">
          <h1 className="mt-12 text-3xl font-semibold text-raisinBlack">
            Cooking oil usage
          </h1>
          <Controller
            control={control}
            name="use_oil_to_cook"
            render={({ field }) => (
              <div className="mt-8">
                <div className="flex flex-row items-start">
                  <span className="mr-8 flex-1 text-lg text-raisinBlack">
                    Do you use cooking oil to prepare your food?
                  </span>
                  <Switch
                    checked={field.value}
                    onChange={field.onChange}
                    className="mt-1"
                  />
                </div>
              </div>
            )}
          />
          {use_oil_to_cook && (
            <Controller
              control={control}
              name="uco_production_period"
              render={({ field, fieldState: { error } }) => (
                <div className="mt-8">
                  <div
                    className={clsx([
                      'text-xs',
                      error ? 'text-red' : 'text-raisinBlack',
                    ])}
                  >
                    MONTHLY ESTIMATED OIL CONSUMPTION (LITERS)
                  </div>
                  <StandardSelect
                    variant="beauBlue"
                    placeholder="John Smith"
                    error={!!error}
                    helperText={error ? error.message : ''}
                    value={field.value}
                    onChangeText={field.onChange}
                  >
                    <option value="lt_1">0-1L</option>
                    <option value="1_5">1-5L</option>
                    <option value="5_10">5-10L</option>
                    <option value="gt_10">+10L</option>
                  </StandardSelect>
                </div>
              )}
            />
          )}
          <Controller
            control={control}
            name="recycle_uco"
            render={({ field }) => (
              <div className="mt-8">
                <div className="flex flex-row items-start">
                  <span className="mr-8 flex-1 text-lg text-raisinBlack">
                    Do you recycle used cooking oil?
                  </span>
                  <Switch
                    checked={field.value}
                    onChange={field.onChange}
                    className="mt-1"
                  />
                </div>
              </div>
            )}
          />
          <Controller
            control={control}
            name="recycle_amount"
            render={({ field, fieldState: { error } }) => (
              <div className="mt-8">
                <div
                  className={clsx([
                    'text-xs',
                    error ? 'text-red' : 'text-raisinBlack',
                  ])}
                >
                  HOW MANY TIMES A MONTH DO YOU EAT OUT/TAKEOUT?
                </div>
                <StandardSelect
                  variant="beauBlue"
                  placeholder="John Smith"
                  error={!!error}
                  helperText={error ? error.message : ''}
                  value={field.value}
                  onChangeText={field.onChange}
                >
                  <option value="lt_1">0 meals</option>
                  <option value="1_5">1-5 meals</option>
                  <option value="5_10">5-10 meals</option>
                  <option value="gt_10">+10 meals</option>
                </StandardSelect>
              </div>
            )}
          />
          <div className="h-40" />
          <div className="fixed bottom-0 left-0 right-0 flex flex-row justify-center">
            <div className="flex w-full max-w-[600px] flex-row gap-4 bg-isabelline px-8 pb-8 pt-4">
              <OutlinedButton
                variant="dark"
                size="large"
                className="flex-1"
                onClick={handleCancel}
              >
                Cancel
              </OutlinedButton>
              <FilledButton
                variant="dark"
                size="large"
                className="flex-[2]"
                loading={loading}
                onClick={handleSubmit(handleUpdateProfile)}
              >
                Save
              </FilledButton>
            </div>
          </div>
        </div>
      </div>
    </ProfileLayout>
  );
};
