import React from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';

export const SharePage: React.FC = () => {
  const [params] = useSearchParams();

  if (params.get('content') && params.get('title') && params.get('message')) {
    return (
      <>
        <Helmet>
          <title>UCO Community</title>

          <meta property="og:image" content={params.get('content') ?? ''} />
          <meta
            property="og:title"
            content={params.get('title') ?? 'UCO Community'}
          />
          <meta
            property="og:description"
            content={params.get('message') ?? 'UCO Community'}
          />
          <meta property="og:url" content="https://oily.uco.network" />
          <meta property="og:site_name" content="UCO Community" />
          <meta property="og:type" content="object" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="360" />
          <meta property="og:image:height" content="460" />
        </Helmet>
      </>
    );
  } else {
    return null;
  }
};
