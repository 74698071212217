import React, { useCallback, useEffect } from 'react';
import { Portal } from 'react-portal';
import {
  AlertDialog,
  FilledButton,
  IconButton,
  OutlinedButton,
  StandardInput,
  Switch,
} from '../../components';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { IRootState } from '../../redux/store';
import { updatePlace } from '../../redux/places.slice';
import { InfoIcon } from 'lucide-react';

interface IOilFormData {
  waste_info: string;
  is_collected_by_wmo: boolean;
  is_company: boolean;
  is_compensating: boolean;
  collector_name?: string;
  collector_email?: string;
  collector_phone_number?: string;
}

const schema = yup
  .object({
    waste_info: yup.string().required('Please fill waste info'),
    is_collected_by_wmo: yup.boolean().required(),
    is_company: yup.boolean().required(),
    is_compensating: yup.boolean().required(),
    collector_name: yup
      .string()
      .when(['is_collected_by_wmo'], (values, schema) => {
        return values[0]
          ? schema.required('Please fill collector name')
          : schema.optional();
      }),
    collector_email: yup
      .string()
      .email('Please fill valid email address')
      .optional(),
    collector_phone_number: yup.string().optional(),
  })
  .required();

export const MappingPlaceOilDataPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const data = useSelector((state: IRootState) =>
    id ? state.places.places[id] : null,
  );

  const { reset, control, handleSubmit, watch, setValue } =
    useForm<IOilFormData>({
      defaultValues: {
        is_collected_by_wmo: false,
        is_company: false,
        is_compensating: false,
        collector_email: '',
        collector_name: '',
        waste_info: '',
      },
      resolver: yupResolver(schema),
    });

  const isCollectedByWmo = watch('is_collected_by_wmo');

  const handleShowWMOGuide = useCallback(() => {
    AlertDialog.open({
      title: 'What is a Waste Management Organization?',
      message:
        'Waste Management Organizations (WMO), are individuals or entities responsible for the systematic collection, transportation, processing, recycling, or disposal of waste materials.',
      canDismiss: true,
      confirmLabel: 'Close',
      confirmStyle: 'outlined',
    });
  }, []);

  const handleDiscard = useCallback(() => {
    navigate(-1);
  }, []);

  const handleSave = useCallback(
    (values: IOilFormData) => {
      if (!id) {
        return;
      }

      if (
        values.is_collected_by_wmo &&
        !values.collector_email &&
        !values.collector_phone_number
      ) {
        AlertDialog.open({
          title: 'Missing information',
          message: "Please fill collector's email address or phone number",
          canDismiss: true,
          confirmLabel: 'Close',
          confirmStyle: 'outlined',
        });
        return;
      }

      dispatch(
        updatePlace({
          id: id,
          info: {
            is_collected_by_wmo: values.is_collected_by_wmo,
            is_company: values.is_company,
            is_compensating: values.is_compensating,
            collector_name: values.collector_name,
            collector_email: values.collector_email,
            collector_phone_number: values.collector_phone_number,
            waste_info: values.waste_info,
          },
        }),
      );
      navigate(-1);
    },
    [id],
  );

  useEffect(() => {
    if (!id) {
      navigate('/mappings');
      return;
    }
    if (data) {
      reset({
        is_collected_by_wmo: data.info?.is_collected_by_wmo ?? false,
        is_company: data.info?.is_company ?? false,
        is_compensating: data.info?.is_compensating ?? false,
        waste_info: data.info?.waste_info ?? '',
        collector_name: data.info?.collector_name ?? '',
        collector_email: data.info?.collector_email ?? '',
        collector_phone_number: data.info?.collector_phone_number ?? '',
      });
    } else {
      navigate('/mappings');
    }
  }, [data, id]);

  return (
    <div className="px-6 pt-24">
      <div>
        <h1 className="text-3xl text-isabelline">Waste production</h1>
        <Controller
          name="waste_info"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className="mt-8">
              <div className="mb-2 text-xs text-isabelline">
                QUANTITY OF UCO PRODUCED PER MONTH
              </div>
              <StandardInput
                type="text"
                variant="gray"
                placeholder="E.g.: 100 liters"
                error={!!error?.message}
                helperText={error?.message}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
      </div>
      <div className="mt-12">
        <div className="flex flex-row">
          <h1 className="flex-1 text-3xl text-isabelline">
            Waste Management Organization (WMO)
          </h1>
          <IconButton
            icon={InfoIcon}
            variant="ghost"
            size="small"
            onClick={handleShowWMOGuide}
          />
        </div>
        <Controller
          name="is_collected_by_wmo"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="mt-8 flex flex-row items-start justify-between">
              <div className="mr-4 flex-1 text-lg leading-normal">
                Is the UCO being collected by someone?
              </div>
              <Switch
                checked={value}
                onChange={(newValue) => {
                  if (!newValue) {
                    setValue('collector_name', '');
                    setValue('collector_email', '');
                    setValue('collector_phone_number', '');
                  }
                  onChange(newValue);
                }}
                className="mt-1"
              />
            </div>
          )}
        />
        {isCollectedByWmo && (
          <>
            <Controller
              name="is_company"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="mt-8 flex flex-row items-start justify-between">
                  <div className="mr-4 flex-1 text-lg leading-normal">
                    Is it a company?
                  </div>
                  <Switch checked={value} onChange={onChange} />
                </div>
              )}
            />
            <Controller
              name="collector_name"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <div className="mt-8">
                  <div className="mb-2 text-xs text-isabelline">
                    COLLECTOR&apos;S NAME
                  </div>
                  <StandardInput
                    type="text"
                    variant="gray"
                    placeholder="e.g. Recyoil Waste"
                    error={!!error?.message}
                    helperText={error?.message}
                    value={value ?? ''}
                    onChange={onChange}
                  />
                </div>
              )}
            />
            <Controller
              name="collector_email"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <div className="mt-8">
                  <div className="mb-2 text-xs text-isabelline">
                    COLLECTOR&apos;S EMAIL ADDRESS
                  </div>
                  <StandardInput
                    type="text"
                    variant="gray"
                    placeholder="e.g. collector@mail.com"
                    error={!!error?.message}
                    helperText={error?.message}
                    value={value ?? ''}
                    onChange={onChange}
                  />
                </div>
              )}
            />
            <Controller
              name="collector_phone_number"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <div className="mt-8">
                  <div className="mb-2 text-xs text-isabelline">
                    COLLECTOR&apos;S EMAIL PHONE NUMBER
                  </div>
                  <StandardInput
                    type="text"
                    variant="gray"
                    placeholder="e.g. +351 555 258 856"
                    error={!!error?.message}
                    helperText={error?.message}
                    value={value ?? ''}
                    onChange={onChange}
                  />
                </div>
              )}
            />
          </>
        )}
      </div>
      <div className="mt-12">
        <h1 className="flex-1 text-3xl text-isabelline">Compensations</h1>
        <Controller
          name="is_compensating"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="mt-8 flex flex-row items-start justify-between">
              <div className="mr-4 flex-1 text-lg leading-normal">
                Is the restaurant being compensated for recycling its UCO?
              </div>
              <Switch checked={value} onChange={onChange} className="mt-1" />
            </div>
          )}
        />
      </div>
      <div className="h-52" />
      <Portal
        node={document && document.getElementById('mapping-details-footer')}
      >
        <OutlinedButton
          size="large"
          variant="light"
          className="flex-1"
          onClick={handleDiscard}
        >
          Discard
        </OutlinedButton>
        <FilledButton
          variant="light"
          size="large"
          className="flex-[2]"
          onClick={handleSubmit(handleSave)}
        >
          Save
        </FilledButton>
      </Portal>
    </div>
  );
};
