import React from 'react';
import clsx from 'clsx';

interface ILinearProgressIndicatorProps {
  value: number;
  total?: number;
  variant?: 'icterine';
  size?: 'small' | 'medium' | 'large';
  className?: string;
}

export const LinearProgressIndicator: React.FC<
  ILinearProgressIndicatorProps
> = ({ value, total, variant = 'icterine', size = 'medium', className }) => {
  return (
    <div className={clsx([className])}>
      <div
        className={clsx([
          'w-full',
          size === 'large' && 'h-3',
          size === 'medium' && 'h-2',
          size === 'small' && 'h-1',
          variant === 'icterine' && 'bg-progressTrackBackground',
          'rounded-full',
          'overflow-hidden',
          'relative',
        ])}
      >
        <div
          className={clsx([
            'absolute',
            'top-0',
            'left-0',
            'h-full',
            variant === 'icterine' && 'bg-icterine',
            'rounded-full',
            'transition-all',
            'duration-300',
          ])}
          style={{
            width: `${(value / (total ? total : 100)) * 100}%`,
          }}
        />
      </div>
    </div>
  );
};
