import React, { useCallback, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import Drawer from 'react-modern-drawer';
import clsx from 'clsx';
import { GoogleMap, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import {
  AlertDialog,
  FilledButton,
  IconButton,
  XPIndicator,
} from '../../components';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import {
  CircleCheckIcon,
  CircleXIcon,
  EllipsisVerticalIcon,
  ListIcon,
  MapIcon,
  MapPinIcon,
  SearchIcon,
  UtensilsIcon,
} from 'lucide-react';
import { useGeolocated } from 'react-geolocated';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { apiGetPlaces } from '../../apis/mapping';
import { IMappingPlaceItem } from '../../apis/types';
import { IRootState } from '../../redux/store';
import { MappingAddOptionsPage } from './MappingAddOptionsPage';

import imgOily from '../../assets/images/oily.png';

export const MappingPlacesPage: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
  });
  const { isGeolocationEnabled, isGeolocationAvailable, coords } =
    useGeolocated();
  const { isLoggedIn, isVerified } = useSelector(
    (state: IRootState) => state.auth,
  );

  const qLat = params.get('lat') ? parseFloat(params.get('lat')!) : null;
  const qLng = params.get('lng') ? parseFloat(params.get('lng')!) : null;

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [view, setView] = useState<'map' | 'list' | 'highlight'>('list');
  const [zoom, setZoom] = useState(16);
  const [center, setCenter] = useState({
    lat: qLat ?? 0,
    lng: qLng ?? 0,
  });
  const [selectedPlace, setSelectedPlace] = useState<IMappingPlaceItem | null>(
    null,
  );
  const [filteredPlaces, setFilteredPlaces] = useState<IMappingPlaceItem[]>([]);
  const [search, setSearch] = useState<string>('');
  const [filter] = useDebounce(search, 300);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const { data: places, isLoading } = useQuery({
    queryKey: ['mapping', 'places', coords?.latitude, coords?.longitude],
    queryFn: async () => {
      if (coords && (coords.latitude !== 0 || coords?.longitude !== 0)) {
        return await apiGetPlaces({
          latitude: coords.latitude,
          longitude: coords.longitude,
          radius: 500,
        });
      }
      return [];
    },
  });

  const toggleMap = () => {
    setView((value) => {
      setSelectedPlace(null);
      if (value === 'map' || value === 'highlight') {
        return 'list';
      } else {
        return 'map';
      }
    });
  };

  const getDistance = (from: { lat: number; lng: number }) => {
    if (coords && coords.latitude !== 0 && coords.longitude !== 0) {
      const R = 6371;
      const dLat = deg2rad(coords?.latitude - from.lat);
      const dLng = deg2rad(coords?.longitude - from.lng);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(from.lat)) *
          Math.cos(deg2rad(coords?.latitude)) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c;
      return distance;
    }
  };

  const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180);
  };

  const onMapMount = useCallback(
    (map: google.maps.Map) => {
      setMap(map);
    },
    [center],
  );

  const onMapUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const onZoomChanged = useCallback(() => {
    if (map) {
      setZoom(map.getZoom() ?? 16);
      setCenter({
        lat: map.getCenter()?.lat() ?? 0,
        lng: map.getCenter()?.lng() ?? 0,
      });
    }
  }, [map]);

  const onCenterChanged = useCallback(() => {
    if (map) {
      setCenter({
        lat: map.getCenter()?.lat() ?? 0,
        lng: map.getCenter()?.lng() ?? 0,
      });
    }
  }, [map]);

  const onSelectPlace = useCallback((place: IMappingPlaceItem) => {
    setSelectedPlace(place);
    setCenter({
      lat: place.latitude,
      lng: place.longitude,
    });
    setView('highlight');
  }, []);

  const onChoosePlace = useCallback(() => {
    if (!isVerified || !isLoggedIn) {
      AlertDialog.open({
        title: 'Please connect your wallet first to continue',
        canDismiss: true,
        confirmLabel: 'Okay, I understand',
      });
      return;
    }

    if (selectedPlace) {
      if (selectedPlace.claimed) {
        navigate(`/mappings/${selectedPlace._id}/details/view`, {
          state: {
            ...selectedPlace,
          },
        });
      } else {
        navigate(`/mappings/${selectedPlace._id}/details`, {
          state: {
            ...selectedPlace,
          },
        });
      }
    }
  }, [selectedPlace, isVerified, isLoggedIn]);

  const toggleMenu = useCallback(() => {
    setIsMenuOpened((prevValue) => {
      return !prevValue;
    });
  }, []);

  useEffect(() => {
    if (center.lat !== 0 || center.lng !== 0) {
      return;
    }
    if (
      coords &&
      (coords.latitude !== center.lat || coords.longitude !== center.lng)
    ) {
      setCenter({
        lat: coords.latitude,
        lng: coords.longitude,
      });
    }
  }, [coords, center]);

  useEffect(() => {
    if (places && places.length > 0) {
      setFilteredPlaces(
        places.filter((item) =>
          item.name.toLowerCase().includes(filter.toLowerCase()),
        ),
      );
    } else {
      setFilteredPlaces([]);
    }
  }, [places, filter]);

  const renderPlaceListItem = useCallback(
    (item: IMappingPlaceItem) => {
      return (
        <div
          key={item._id}
          className={clsx([
            'mb-2 flex w-full flex-row items-center justify-between rounded-2xl py-2 pl-4 pr-2',
            item.status
              ? 'hover:bg-opacity-90 focus:bg-opacity-85 active:bg-opacity-85'
              : 'hover:bg-opacity-30 focus:bg-opacity-15 active:bg-opacity-15',
            item.status ? 'bg-gray200' : 'bg-shadowBlue bg-opacity-20',
          ])}
          onClick={
            item._id === selectedPlace?._id
              ? () => onChoosePlace()
              : () => onSelectPlace(item)
          }
        >
          <div className="mr-4 flex min-h-[80px] flex-1 flex-col items-start justify-start py-2">
            {item.status ? (
              <div
                className={clsx([
                  'mb-2 rounded border p-2',
                  'flex flex-row items-center gap-1',
                  item.status === 'pending' &&
                    'border-gray800 bg-transparent text-gray800',
                  item.status === 'approved' &&
                    'border-green bg-green bg-opacity-10 text-green600',
                  item.status === 'disapproved' &&
                    'border-gray500 bg-gray300 bg-opacity-10 text-gray500',
                ])}
              >
                {item.status === 'disapproved' ? (
                  <CircleXIcon className="size-[16px]" />
                ) : (
                  <CircleCheckIcon className="size-[16px]" />
                )}
                <span className="text-sm">
                  {item.status === 'pending'
                    ? 'Under review'
                    : item.status === 'approved'
                      ? 'Restaurant approved'
                      : 'Restaurant disapproved'}
                </span>
              </div>
            ) : null}
            <span
              className={clsx([
                'line-clamp-2 flex-1 text-lg',
                item.status
                  ? 'font-medium text-raisinBlack'
                  : 'text-isabelline',
              ])}
            >
              {item.name}
            </span>
            <div className="mt-1 flex w-full flex-row items-center justify-between">
              <span className="text-shadowBlue">
                {Number(
                  getDistance({
                    lat: item.latitude,
                    lng: item.longitude,
                  }),
                ).toFixed(2)}
                km away
              </span>
              <XPIndicator
                current={item.total_xp.toFixed(0)}
                textClassName="text-shadowBlue"
              />
            </div>
          </div>
          {item.photo_url ? (
            <img
              src={item.photo_url}
              alt="Place"
              className="h-[80px] w-[80px] rounded-lg"
            />
          ) : (
            <div
              className={clsx([
                'flex h-[80px] w-[80px] items-center justify-center rounded-lg',
                item.status
                  ? 'bg-royalBlueDark bg-opacity-30'
                  : 'bg-isabelline bg-opacity-30',
              ])}
            >
              <UtensilsIcon className="text-icterine" />
            </div>
          )}
        </div>
      );
    },
    [selectedPlace, getDistance],
  );

  return (
    <div
      className={clsx([
        'absolute bottom-0 left-0 right-0 top-0 z-30 bg-isabelline',
        'flex flex-col',
      ])}
    >
      <div
        className={clsx([
          'absolute left-6 right-6 top-8 z-40 h-[40px] bg-transparent',
          'flex flex-row items-center justify-between',
        ])}
      >
        <div
          className={clsx([
            'h-full flex-1 rounded-lg bg-white',
            'flex flex-row items-center pl-3 pr-4',
          ])}
        >
          <input
            className={clsx([
              'flex-1',
              'text-sm text-raisinBlack placeholder:text-shadowBlue',
              'focus:shadow-none focus:outline-none',
            ])}
            placeholder="Search"
            value={search}
            onChange={(ev) => setSearch(ev.currentTarget.value)}
          />
          <SearchIcon className="size-4 text-shadowBlue" />
        </div>
        <IconButton
          icon={EllipsisVerticalIcon}
          variant="dark"
          className="ml-6"
          onClick={toggleMenu}
        />
      </div>
      <div
        className={clsx({
          'transition-all duration-200 ease-in-out': 1,
          'h-[36vh]': view === 'list',
          'h-[100vh]': view === 'map',
          'h-[64vh]': view === 'highlight',
        })}
      >
        {isGeolocationAvailable ? (
          isGeolocationEnabled && isLoaded ? (
            <GoogleMap
              mapContainerClassName="w-full h-full"
              center={center}
              zoom={zoom}
              options={{
                zoomControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
              }}
              onLoad={onMapMount}
              onUnmount={onMapUnmount}
              onDragEnd={onCenterChanged}
              onZoomChanged={onZoomChanged}
            >
              {selectedPlace ? (
                <OverlayView
                  key={`place-${selectedPlace._id}`}
                  position={
                    new google.maps.LatLng(
                      selectedPlace.latitude,
                      selectedPlace.longitude,
                    )
                  }
                  mapPaneName="overlayMouseTarget"
                >
                  <PlaceMarker
                    isSelected={true}
                    isMapped={selectedPlace.claimed}
                    isMuted={false}
                    onClick={() => null}
                  />
                </OverlayView>
              ) : (
                filteredPlaces?.map((item) => {
                  const pos = new google.maps.LatLng(
                    item.latitude,
                    item.longitude,
                  );
                  return (
                    <OverlayView
                      key={`place-${item._id}`}
                      position={pos}
                      mapPaneName="overlayMouseTarget"
                    >
                      <PlaceMarker
                        isSelected={false}
                        isMapped={item.claimed}
                        isMuted={item.status === 'pending'}
                        onClick={() => onSelectPlace(item)}
                      />
                    </OverlayView>
                  );
                })
              )}
            </GoogleMap>
          ) : (
            <div className="flex h-full flex-col items-center justify-center px-8">
              <p className="pb-4 text-center text-raisinBlack">
                Please enable geolocation service.
              </p>
            </div>
          )
        ) : (
          <div className="flex h-full flex-col items-center justify-center px-8">
            <p className="pb-4 text-center text-raisinBlack">
              Your browser does not support geolocation service.
            </p>
          </div>
        )}
      </div>
      <div
        className={clsx({
          'relative px-6 transition-all duration-200 ease-in-out': 1,
          'h-[64vh]': view === 'list',
          'h-[0vh]': view === 'map',
          'h-[36vh]': view === 'highlight',
          'bg-raisinBlack': 1,
        })}
      >
        <div
          className={clsx([
            'absolute -top-8 left-0 right-0 h-8 bg-raisinBlack',
            'translate-y-0 rounded-t-3xl transition-all duration-200 ease-in-out',
            view === 'map' && 'translate-y-8',
          ])}
        />
        <Scrollbars>
          {isGeolocationEnabled ? (
            <>
              {selectedPlace ? (
                renderPlaceListItem(selectedPlace)
              ) : filteredPlaces && filteredPlaces.length > 0 ? (
                filteredPlaces.map((item) => renderPlaceListItem(item))
              ) : (
                <>
                  {isLoading ? (
                    Array(5)
                      .fill(true)
                      .map((_, index) => (
                        <NavLink
                          key={`loading-place-${index}`}
                          to="#"
                          className={clsx([
                            'mb-2 flex flex-row justify-between rounded-2xl bg-shadowBlue bg-opacity-20 py-2 pl-4 pr-2',
                            'hover:bg-opacity-30 focus:bg-opacity-15 active:bg-opacity-15',
                          ])}
                        >
                          <div className="mr-4 flex h-[80px] flex-1 flex-col py-2">
                            <div className="h-5 w-2/3 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
                            <div className="mt-auto flex flex-row items-center justify-between">
                              <div className="h-4 w-36 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
                              <div className="h-4 w-20 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
                            </div>
                          </div>
                          <div className="h-[80px] w-[80px] animate-pulse rounded-lg bg-isabelline bg-opacity-30" />
                        </NavLink>
                      ))
                  ) : (
                    <div className="flex h-full flex-col items-center justify-start">
                      <img src={imgOily} alt="Oily" />
                      <p className="mt-4 max-w-[218px] pb-4 text-center text-sm text-shadowBlue">
                        There are no restaurants mapped in this region yet.
                      </p>
                      <NavLink
                        to="/mappings/add/restaurant"
                        className="mt-8 w-full"
                      >
                        <button
                          className={clsx([
                            'w-full px-4 py-3',
                            'rounded-2xl bg-shadowBlue bg-opacity-20',
                            'hover:bg-opacity-30 focus:bg-opacity-15 active:bg-opacity-15',
                          ])}
                        >
                          <h2 className="text-2xl leading-normal text-white">
                            Add a Restaurant to Map
                          </h2>
                          <p className="leading-relaxed text-beauBlue">
                            Help us map the unmapped restaurants
                          </p>
                        </button>
                      </NavLink>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <div className="flex h-full flex-col items-center justify-center px-8">
              <MapPinIcon className="text-isabelline text-opacity-50" />
              <p className="mt-4 pb-4 text-center text-sm text-isabelline text-opacity-50">
                Please enable geolocation service.
              </p>
            </div>
          )}
          {}
          <div className="h-32" />
        </Scrollbars>
        <FilledButton
          variant="dark"
          endIcon={view === 'map' || view === 'highlight' ? ListIcon : MapIcon}
          className={clsx({
            'absolute transition-all duration-200 ease-in-out': 1,
            'left-1/2 top-[-87px] -translate-x-1/2':
              view === 'list' || view === 'highlight',
            'left-full top-[-160px] translate-x-[-100px]': view === 'map',
          })}
          onClick={toggleMap}
        >
          {view === 'map' || view === 'highlight' ? 'List' : 'Map'}
        </FilledButton>
      </div>
      <Drawer
        open={isMenuOpened}
        onClose={toggleMenu}
        direction="right"
        style={{
          width: '100vw',
          maxWidth: 600,
          zIndex: 9999,
        }}
      >
        <MappingAddOptionsPage onClose={toggleMenu} />
      </Drawer>
    </div>
  );
};

export const PlaceMarker: React.FC<{
  isSelected: boolean;
  isMapped: boolean;
  isMuted: boolean;
  onClick: () => void;
}> = ({ isSelected, isMapped, isMuted, onClick }) => {
  return (
    <div
      className="flex -translate-y-full cursor-pointer flex-col items-center drop-shadow-xl hover:scale-110 active:scale-105"
      onClick={onClick}
    >
      <div
        className={clsx([
          'h-6 w-6 rounded-full',
          'border-4 transition-all',
          isSelected ? 'border-green' : 'border-white',
          !isMuted && (isMapped ? 'bg-icterine' : 'bg-royalBlueDark'),
          isMuted && 'bg-spanishGray',
        ])}
      />
      <div
        className={clsx([
          'h-4 rounded-full',
          '-mt-1 w-1',
          isSelected ? 'bg-green' : 'bg-white',
        ])}
      />
    </div>
  );
};
