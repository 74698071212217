import React from 'react';
import clsx from 'clsx';
import { createRoot } from 'react-dom/client';
import { FilledButton } from '../buttons/FilledButton';
import { ShareOptionsDialog } from './ShareOptionsDialog';
import { apiUploadFile } from '../../apis/files';

import brand from '../../assets/logos/dark-brand.png';

interface IShareEarningDialogProps {
  id?: string | number;
  amount?: number;
}

interface IShareEarningDialogState {
  loaded: boolean;
  sharing: boolean;
}

export class ShareEarningDialog extends React.Component<
  IShareEarningDialogProps,
  IShareEarningDialogState
> {
  constructor(props: IShareEarningDialogProps) {
    super(props);

    this.state = {
      loaded: false,
      sharing: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleShare = this.handleShare.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('popstate', this.handleBackButton);

    setTimeout(() => {
      this.setState({
        loaded: true,
      });
    }, 10);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('popstate', this.handleBackButton);
  }

  handleClose() {
    this.setState({
      loaded: false,
    });
    setTimeout(() => {
      const root = document.getElementById('root');
      if (root) {
        const modal = document.getElementById('modal' + '-' + this.props.id);
        if (modal) {
          root.removeChild(modal);
        }
      }
    }, 300);
  }

  async handleShare() {
    const canvas = document.createElement('canvas');
    canvas.width = 360;
    canvas.height = 460;

    const ctx = canvas.getContext('2d');
    if (ctx) {
      this.setState({
        sharing: true,
      });

      ctx.fillStyle = '#EBF155';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.font = '500 32px Manrope';
      ctx.fillStyle = '#00195A';
      ctx.fillText('I’ve just earn', 32, 224);
      ctx.fillText(`${this.props.amount?.toFixed(0)} XP with UCO`, 32, 264);
      ctx.fillText('Community app.', 32, 304);

      ctx.font = '700 18px Manrope';
      ctx.fillStyle = '#00195A';
      ctx.fillText('Join now!', 32, 381);

      ctx.font = '18px Manrope';
      ctx.fillStyle = '#00195A';
      ctx.fillText('Start earning rewards', 32, 408);

      const img = new Image();
      img.src = brand;
      img.onload = async () => {
        ctx.drawImage(img, 32, 32, 208, 64);

        const file = canvas.toDataURL('image/png');
        const blob = await (await fetch(file)).blob();

        const result = await apiUploadFile({
          key: 'file',
          files: [blob],
        });
        if (result && result.length > 0) {
          const [url] = result;

          ShareOptionsDialog.open({
            title: 'Join UCO.Network',
            message: `I’ve just earn ${this.props.amount?.toFixed(0)} XP with UCO Community app.`,
            url: `https://welcome.oily.uco.network/share?content=${url}&title=${encodeURIComponent('Join UCO.Network')}&message=${encodeURIComponent(`I’ve just earn ${this.props.amount?.toFixed(0)} XP with UCO Community app.`)}&return=${window.location.origin}`,
          });
        }

        this.setState({
          sharing: false,
        });
      };
      img.onerror = () => {
        this.setState({
          sharing: false,
        });
      };
    }
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.handleClose();
    }
  };

  handleBackButton = () => {
    this.handleClose();
  };

  render(): React.ReactNode {
    return (
      <div
        className={clsx({
          'fixed bottom-0 left-0 right-0 top-0 z-[999] flex flex-col items-center justify-between bg-raisinBlack px-2 py-2 transition-all duration-300': 1,
          'bg-opacity-0': !this.state.loaded,
          'bg-opacity-80': this.state.loaded,
        })}
        onClick={this.handleClose}
      >
        <div
          className={clsx({
            'flex min-h-80 w-full max-w-[600px] flex-col rounded-3xl bg-icterine px-8 py-8': 1,
            'transition-all duration-300': 1,
            '-translate-y-full opacity-50': !this.state.loaded,
            'translate-y-0 opacity-100': this.state.loaded,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="">
            <img
              src={brand}
              alt="UCO Network"
              className="h-[64px] w-[208px] object-contain"
            />
          </div>
          <h1 className="mt-[120px] text-3xl font-medium leading-tight text-royalBlueDark">
            I’ve just earn {this.props.amount?.toFixed(0)}
            <br />
            XP with UCO
            <br />
            Community app.
          </h1>
          <div className="mt-[40px]">
            <h6 className="text-lg font-bold text-royalBlueDark">Join now!</h6>
            <p className="text-lg text-royalBlueDark">Start earning rewards</p>
          </div>
        </div>
        <div
          className={clsx({
            'flex w-full max-w-[600px] flex-row gap-4 px-6 pb-6': 1,
            'transition-all duration-300': 1,
            'translate-y-full opacity-50': !this.state.loaded,
            'translate-y-0 opacity-100': this.state.loaded,
          })}
        >
          <FilledButton
            variant="secondary"
            size="large"
            className="flex-[1]"
            onClick={this.handleClose}
          >
            Close
          </FilledButton>
          <FilledButton
            variant="icterine"
            size="large"
            className="flex-[2]"
            loading={this.state.sharing}
            disabled={this.state.sharing}
            onClick={this.handleShare}
          >
            Share now
          </FilledButton>
        </div>
      </div>
    );
  }

  public static open(props: IShareEarningDialogProps | undefined = undefined) {
    const root = document.getElementById('root');
    if (root) {
      const key = Date.now();
      const div = document.createElement('div');
      div.id = 'modal' + '-' + key;
      root.appendChild(div);
      createRoot(div).render(<ShareEarningDialog {...props} id={key} />);
    }
  }
}
