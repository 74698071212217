import React, { useCallback, useEffect, useState } from 'react';
import {
  LessonHeader,
  AppContent,
  FilledButton,
  ShareEarningDialog,
} from '../../components';
import { ZapIcon } from 'lucide-react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { apiGetLessons, apiGetQuestions } from '../../apis/lms';

import img from '../../assets/images/completed-lesson.png';

export const LessonSharingPage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const segments = params.segments;
  if (
    !segments ||
    !segments.includes('-') ||
    segments.split('-').length !== 2 ||
    !params.lessonId
  ) {
    return <Navigate to="/challenges" />;
  }

  const chapterId = segments.split('-')[1];
  const lessonId = params.lessonId;
  const { data: questions, isLoading: isLoadingQuestions } = useQuery({
    queryKey: ['lms', 'chapters', chapterId, 'lessons', lessonId, 'questions'],
    queryFn: async () => {
      return await apiGetQuestions(lessonId);
    },
  });
  const { data: lessons } = useQuery({
    queryKey: ['lms', 'chapters', chapterId, 'lessons'],
    queryFn: async () => {
      return await apiGetLessons(chapterId);
    },
  });

  const [totalXp, setTotalXp] = useState(0);
  const [currentXp, setCurrentXp] = useState(0);

  const handleCancel = useCallback(() => {
    navigate(-1);
  }, []);

  const handleShare = useCallback(() => {
    if (currentXp) {
      ShareEarningDialog.open({
        amount: currentXp,
      });
    }
  }, [currentXp]);

  useEffect(() => {
    if (lessons) {
      for (const lesson of lessons) {
        if (lesson._id === lessonId) {
          setTotalXp(lesson.total_xp);
          setCurrentXp(lesson.user_xp);
          return;
        }
      }
    }
  }, [lessons]);

  return (
    <div className="relative min-h-[100vh] w-full max-w-[600px] bg-raisinBlack">
      <LessonHeader
        currentPage={0}
        currentXp={currentXp}
        totalPages={questions ? questions.length : 0}
        totalXp={questions ? totalXp : 0}
        onClose={handleCancel}
        showOnlyCloseButton
      />
      <AppContent className="pt-0">
        <div
          className="flex flex-col pt-[90px]"
          style={{
            minHeight: 'calc(100vh - 90px)',
          }}
        >
          {isLoadingQuestions ? (
            <>
              <div className="h-[300px] w-full animate-pulse bg-isabelline bg-opacity-25" />
              <div className="mt-8 animate-pulse px-8">
                <div className="h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/2 rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/4 rounded-full bg-isabelline bg-opacity-25" />
              </div>
              <div className="mt-8 animate-pulse px-8">
                <div className="h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/2 rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/4 rounded-full bg-isabelline bg-opacity-25" />
              </div>
              <div className="mt-8 animate-pulse px-8">
                <div className="h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/2 rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/4 rounded-full bg-isabelline bg-opacity-25" />
              </div>
            </>
          ) : (
            <>
              {questions && questions.length > 0 ? (
                <>
                  <div className="p-2">
                    <img
                      src={img}
                      className="w-full overflow-hidden rounded-3xl object-cover"
                      style={{
                        aspectRatio: '2/1',
                      }}
                    />
                  </div>
                  <div className="mt-4 flex-1 px-8">
                    <h1 className="text-3xl text-isabelline">
                      Congratulations
                    </h1>
                    <h1 className="mt-2 text-3xl text-beauBlue">
                      You{"'"}ve earned
                    </h1>
                    <div className="my-12 mt-8 flex flex-row items-center justify-end">
                      <ZapIcon className="mr-1 fill-icterine stroke-icterine" />
                      <h1 className="mr-1 text-7xl text-white">
                        {currentXp.toFixed(0)}
                      </h1>
                      <span className="self-start text-3xl text-isabelline">
                        XP
                      </span>
                    </div>
                    <p className="leading-normal text-isabelline">
                      You’ve earned {currentXp.toFixed(0)} XP as a reward for
                      your time to improve your knowledge.
                      <br />
                      <br />
                      Share with the community and continue with your journey
                      learning and contributing for UCO Community.
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <></>
                </>
              )}
            </>
          )}

          <div className="flex flex-row items-center gap-4 p-8">
            <FilledButton
              size="large"
              variant="secondary"
              className="flex-1"
              onClick={handleCancel}
            >
              Close
            </FilledButton>
            <FilledButton
              size="large"
              variant="icterine"
              className="flex-[2]"
              onClick={handleShare}
            >
              Share
            </FilledButton>
          </div>
        </div>
      </AppContent>
    </div>
  );
};
