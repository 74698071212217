import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';

interface IStandardInputProps {
  type?: InputHTMLAttributes<HTMLInputElement>['type'];
  error?: boolean;
  helperText?: string;
  value?: string;
  placeholder?: string;
  readonly?: boolean;
  variant?: 'gray' | 'beauBlue';
  endIcon?: React.FC<any>;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeText?: (value: string) => void;
  onClick?: () => void;
  onFocus?: () => void;
}

export const StandardInput: React.FC<IStandardInputProps> = ({
  type,
  error,
  helperText,
  value,
  placeholder,
  readonly,
  endIcon: EndIcon,
  variant = 'beauBlue',
  onChangeText,
  onChange,
  onClick,
  onFocus,
}) => {
  return (
    <div className="relative">
      <input
        type={type ? type : 'text'}
        className={clsx({
          'block w-full border-b-2 bg-transparent py-2 text-lg leading-normal focus:shadow-none focus:outline-none': 1,
          'border-b-shadowBlue hover:border-b-royalBlueDark active:border-b-royalBlueDark':
            variant === 'beauBlue' && !error,
          'text-raisinBlack placeholder-shadowBlue caret-raisinBlack':
            variant === 'beauBlue' && !error,
          'border-b-shadowBlue hover:border-b-beauBlue active:border-b-beauBlue':
            variant === 'gray' && !error,
          'text-isabelline placeholder-spanishGray caret-isabelline':
            variant === 'gray' && !error,
          'border-b-red text-red placeholder-red hover:border-b-red active:border-b-red':
            error,
        })}
        placeholder={placeholder}
        value={value}
        readOnly={readonly}
        onChange={(ev) => {
          if (onChangeText) {
            onChangeText(ev.target.value);
          }
          if (onChange) {
            onChange(ev);
          }
        }}
        onClick={onClick}
        onFocus={onFocus}
      />
      {EndIcon ? (
        <div className="absolute right-0 top-[8.5px]">
          <EndIcon
            className={clsx({
              'text-spanishGray': variant === 'gray',
            })}
          />
        </div>
      ) : null}
      {helperText && (
        <div
          className={clsx([
            'mt-2 text-sm',
            error ? 'text-red' : 'text-shadowBlue',
          ])}
        >
          {helperText}
        </div>
      )}
    </div>
  );
};
