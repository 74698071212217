import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Carousel } from 'react-responsive-carousel';
import { AlertDialog, FilledButton, XPIndicator } from '../../components';
import { ConnectOptionsDialog } from '../../components/layouts/app-layout/ConnectOptionsDialog';
import { LockIcon } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useWallet } from '../../providers/WalletProvider';
import { IRootState } from '../../redux/store';
import { apiGetChapters } from '../../apis/lms';

import { ReactComponent as SvgTriangleFilled } from '../../assets/images/shape-triangle-filled-2.svg';
import { ReactComponent as SvgTriangleBordered } from '../../assets/images/shape-triangle-bordered.svg';
import { ReactComponent as SvgSquareFilled } from '../../assets/images/shape-square-filled-2.svg';
import { ReactComponent as SvgSquareBordered } from '../../assets/images/shape-square-bordered.svg';
import { ReactComponent as SvgCircleFilled } from '../../assets/images/shape-circle-filled.svg';
import { ReactComponent as SvgCircleBordered } from '../../assets/images/shape-circle-bordered.svg';
import { ReactComponent as SvgBtnCircleBordered } from '../../assets/images/btn-circle-bordered.svg';
import { ReactComponent as SvgBtnCircleFilled } from '../../assets/images/btn-circle-filled.svg';
import { ReactComponent as SvgBtnCircleLocked } from '../../assets/images/btn-circle-locked.svg';
import { ReactComponent as SvgBtnTriangleBordered } from '../../assets/images/btn-triangle-bordered.svg';
import { ReactComponent as SvgBtnTriangleFilled } from '../../assets/images/btn-triangle-filled.svg';
import { ReactComponent as SvgBtnTriangleLocked } from '../../assets/images/btn-triangle-locked.svg';
import { ReactComponent as SvgBtnSquareBordered } from '../../assets/images/btn-square-bordered.svg';
import { ReactComponent as SvgBtnSquareFilled } from '../../assets/images/btn-square-filled.svg';
import { ReactComponent as SvgBtnSquareLocked } from '../../assets/images/btn-square-locked.svg';
import imgFocusShape from '../../assets/images/shape-highlight-area.png';

export const ChallengesPage = () => {
  const navigate = useNavigate();
  const { totalXp, totalChapters } = useSelector(
    (state: IRootState) => state.app.summary,
  );
  const { isVerified, isLoggedIn, isProfileCompleted, m2eLocked } = useSelector(
    (state: IRootState) => state.auth,
  );

  const { data: chapters } = useQuery({
    queryKey: ['lms', 'chapters'],
    queryFn: apiGetChapters,
    enabled: isVerified && isLoggedIn && isProfileCompleted,
  });

  const [section, setSection] = useState(0);
  const [userXp, setUserXp] = useState(0);

  const [startTouchPosition, setStartTouchPosition] = useState<number | null>(
    null,
  );
  const [touchDiff, setTouchDiff] = useState<number>(0);

  const handleTouchStart = useCallback(
    (ev: React.TouchEvent<HTMLButtonElement>) => {
      setStartTouchPosition(ev.touches[0].clientX);
    },
    [],
  );

  const handleTouchMove = useCallback(
    (ev: React.TouchEvent<HTMLButtonElement>) => {
      if (startTouchPosition === null) {
        return;
      }
      const diff = ev.touches[0].clientX - startTouchPosition;
      setTouchDiff(diff);
    },
    [startTouchPosition],
  );

  const handleTouchEnd = useCallback(() => {
    if (startTouchPosition === null) {
      return;
    }

    if (touchDiff < -50) {
      setSection((prev) => Math.min(prev + 1, 2));
    } else if (touchDiff > 50) {
      setSection((prev) => Math.max(prev - 1, 0));
    }
    setStartTouchPosition(null);
    setTouchDiff(0);
  }, [startTouchPosition, touchDiff]);

  const handleClickSectionIndicator = useCallback(
    (value: number) => {
      if (section === value) {
        if (!isVerified || !isLoggedIn) {
          AlertDialog.open({
            title: 'Locked challenge',
            message: 'Connect your wallet first to start this challenge.',
            cancelLabel: 'Okay, I understand',
            confirmLabel: 'Connect',
            confirmStyle: 'filled',
            confirmVariant: 'dark',
            onConfirm: () => open(),
            canDismiss: true,
          });
          return;
        }
        if (!isProfileCompleted) {
          AlertDialog.open({
            title: 'Locked challenge',
            message: 'Complete your profile first to start this challenge.',
            confirmLabel: 'Okay, I understand',
            confirmStyle: 'filled',
            confirmVariant: 'dark',
            onConfirm: () => {
              navigate('/profile');
            },
            canDismiss: true,
          });
          return;
        }

        if (section === 0) {
          navigate('/challenges/learn/chapters');
          return;
        }
        if (section === 1) {
          if (m2eLocked) {
            AlertDialog.open({
              title: 'Locked challenge',
              message: 'Complete the previous challenges to unlock.',
              confirmLabel: 'Okay, I understand',
              canDismiss: true,
            });
            return;
          }
          navigate('/mappings');
          return;
        }
        if (section === 2) {
          AlertDialog.open({
            title: 'Locked challenge',
            message: 'Complete the previous challenges to unlock.',
            confirmLabel: 'Okay, I understand',
            canDismiss: true,
          });
          return;
        }
      } else {
        setSection(value);
      }
    },
    [section, isVerified, isLoggedIn, isProfileCompleted],
  );

  useEffect(() => {
    if (chapters) {
      let sum = 0;
      for (const chapter of chapters) {
        sum += chapter.user_xp;
      }
      setUserXp(sum);
    }
  }, [chapters]);

  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-row justify-center px-8 pt-16">
        <div className="w-full max-w-[600px]">
          <Carousel
            selectedItem={section}
            emulateTouch={false}
            showThumbs={false}
            showIndicators={false}
            showArrows={false}
            showStatus={false}
            centerMode={false}
            width={150}
          >
            <h1 className="text-left text-[40px] font-semibold text-icterine">
              Learn
            </h1>
            <h1 className="text-left text-[40px] font-semibold text-icterine">
              Map
            </h1>
            <h1 className="text-left text-[40px] font-semibold text-icterine">
              Recycle
            </h1>
          </Carousel>
          <div className="flex flex-row items-end justify-between">
            <h1 className="text-md text-[40px] font-semibold text-isabelline">
              and earn
            </h1>
            {section === 0 ? (
              <span className="text-md text-isabelline">
                {totalChapters} Challenges
              </span>
            ) : null}
          </div>
          <div className="mt-2 flex flex-row justify-end">
            {section === 0 ? (
              <XPIndicator
                current={
                  isVerified && isLoggedIn && isProfileCompleted
                    ? userXp?.toFixed(0)
                    : undefined
                }
                total={totalXp?.toFixed(0)}
              />
            ) : (
              <div className="h-4" />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col justify-center pb-32 pt-10">
        <div className="cursor-pointer">
          <Carousel
            emulateTouch
            selectedItem={section}
            showThumbs={false}
            showIndicators={false}
            showArrows={false}
            showStatus={false}
            onChange={setSection}
          >
            <ChallengeSection locked={false} type="learn" />
            <ChallengeSection locked={m2eLocked ?? true} type="map" />
            <ChallengeSection locked={true} type="recycle" />
          </Carousel>
          <div className="relative mt-2 flex flex-row items-center justify-center overflow-hidden">
            <img
              src={imgFocusShape}
              alt="Shape"
              style={{
                maxHeight: '15vh',
              }}
            />
            <div
              className={clsx({
                'absolute bottom-[1.88vh] left-1/2 flex flex-row flex-nowrap': 1,
                'transition-all': touchDiff === 0,
              })}
              style={{
                transform: `translate(calc(${section === 0 ? '0vh' : section === 1 ? '-9.9vh' : '-19.8vh'} + ${touchDiff}px), 0px)`,
              }}
            >
              <ChallengeSectionIndicator
                locked={false}
                type="learn"
                selected={section === 0}
                onClick={handleClickSectionIndicator}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              />
              <ChallengeSectionIndicator
                locked={m2eLocked ?? true}
                type="map"
                selected={section === 1}
                onClick={handleClickSectionIndicator}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              />
              <ChallengeSectionIndicator
                locked
                type="recycle"
                selected={section === 2}
                onClick={handleClickSectionIndicator}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ChallengeSection: React.FC<{
  type: 'learn' | 'map' | 'recycle';
  locked: boolean;
}> = ({ type, locked }) => {
  const navigate = useNavigate();
  const { isVerified, isLoggedIn, isProfileCompleted } = useSelector(
    (state: IRootState) => state.auth,
  );
  const { connectWallet } = useWallet();

  const onClick = useCallback(() => {
    if (!isVerified || !isLoggedIn) {
      AlertDialog.open({
        title: 'Locked challenge',
        message: 'Connect your wallet first to start this challenge.',
        cancelLabel: 'Okay, I understand',
        confirmLabel: 'Connect',
        confirmStyle: 'filled',
        confirmVariant: 'dark',
        onConfirm: () => {
          ConnectOptionsDialog.open({
            onChooseVechain: () => {
              connectWallet('vechain');
            },
            onChooseXDC: async () => {
              connectWallet('xdc');
            },
          });
        },
        canDismiss: true,
      });
      return;
    }
    if (!isProfileCompleted) {
      AlertDialog.open({
        title: 'Locked challenge',
        message: 'Complete your profile first to start this challenge.',
        confirmLabel: 'Okay, I understand',
        confirmStyle: 'filled',
        confirmVariant: 'dark',
        onConfirm: () => {
          navigate('/profile');
        },
        canDismiss: true,
      });
      return;
    }
    if (locked) {
      AlertDialog.open({
        title: 'Locked challenge',
        message: 'Complete the previous challenges to unlock.',
        confirmLabel: 'Okay, I understand',
        canDismiss: true,
      });
      return;
    }

    if (type === 'learn') {
      navigate('/challenges/' + type + '/chapters');
    }
    if (type === 'map') {
      navigate('/mappings');
    }
  }, [type, locked, isVerified, isLoggedIn, isProfileCompleted]);

  const styles = useMemo(
    () => ({
      width: '240px',
      height: '240px',
      maxWidth: '22vh',
      maxHeight: '22vh',
    }),
    [],
  );

  const renderShape = useCallback(() => {
    switch (type) {
      case 'learn':
        if (locked || !isVerified || !isLoggedIn || !isProfileCompleted) {
          return <SvgTriangleBordered style={styles} />;
        } else {
          return <SvgTriangleFilled style={styles} />;
        }
      case 'map':
        if (locked || !isVerified || !isLoggedIn || !isProfileCompleted) {
          return <SvgSquareBordered style={styles} />;
        } else {
          return <SvgSquareFilled style={styles} />;
        }
      case 'recycle':
        if (locked || !isVerified || !isLoggedIn || !isProfileCompleted) {
          return <SvgCircleBordered style={styles} />;
        } else {
          return <SvgCircleFilled style={styles} />;
        }
    }
  }, [type, locked, isVerified, isLoggedIn, isProfileCompleted]);

  return (
    <div className="relative">
      <div className="flex flex-row justify-center">
        <button onClick={onClick}>{renderShape()}</button>
      </div>
      <FilledButton
        className="absolute bottom-4 left-1/2 -translate-x-1/2"
        startIcon={
          locked || !isVerified || !isLoggedIn || !isProfileCompleted
            ? LockIcon
            : null
        }
        size="small"
        variant={
          locked || !isVerified || !isLoggedIn || !isProfileCompleted
            ? 'dark'
            : 'icterine'
        }
        onClick={onClick}
      >
        Start Challenge
      </FilledButton>
    </div>
  );
};

export const ChallengeSectionIndicator: React.FC<{
  type: 'learn' | 'map' | 'recycle';
  locked: boolean;
  selected?: boolean;
  onClick?: (arg: number) => void;
  onTouchStart: (ev: React.TouchEvent<HTMLButtonElement>) => void;
  onTouchMove: (ev: React.TouchEvent<HTMLButtonElement>) => void;
  onTouchEnd: (ev: React.TouchEvent<HTMLButtonElement>) => void;
}> = ({
  type,
  locked,
  selected,
  onClick,
  onTouchStart,
  onTouchMove,
  onTouchEnd,
}) => {
  const { isVerified, isLoggedIn, isProfileCompleted } = useSelector(
    (state: IRootState) => state.auth,
  );

  const renderShape = useCallback(() => {
    switch (type) {
      case 'learn':
        if (locked || !isVerified || !isLoggedIn || !isProfileCompleted) {
          return <SvgBtnTriangleLocked />;
        } else {
          if (selected) {
            return <SvgBtnTriangleFilled />;
          } else {
            return <SvgBtnTriangleBordered />;
          }
        }
      case 'map':
        if (locked || !isVerified || !isLoggedIn || !isProfileCompleted) {
          return <SvgBtnSquareLocked />;
        } else {
          if (selected) {
            return <SvgBtnSquareFilled />;
          } else {
            return <SvgBtnSquareBordered />;
          }
        }
      case 'recycle':
        if (locked || !isVerified || !isLoggedIn || !isProfileCompleted) {
          return <SvgBtnCircleLocked />;
        } else {
          if (selected) {
            return <SvgBtnCircleFilled />;
          } else {
            return <SvgBtnCircleBordered />;
          }
        }
    }
  }, [type, locked, selected, isVerified, isLoggedIn, isProfileCompleted]);

  return (
    <>
      <button
        className={clsx({
          'flex h-[7vh] w-[7vh] flex-row items-center justify-center rounded-full': 1,
          'mr-[2.9vh] -translate-x-1/2 transition-all': 1,
          'opacity-30': !selected,
          'bg-white': 1,
          'bg-opacity-0 hover:bg-opacity-20 active:bg-opacity-30': 1,
        })}
        onClick={() => {
          if (onClick) {
            onClick(type === 'learn' ? 0 : type === 'map' ? 1 : 2);
          }
        }}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {renderShape()}
      </button>
    </>
  );
};
