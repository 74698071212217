import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import {
  AppLayout,
  ProtectedRoute,
  MappingDetailsLayout,
  LeaderboardLayout,
} from '../components';
import { HomePage } from '../pages/home/HomePage';
import { ChallengesPage } from '../pages/challenges/ChallengesPage';
import { ChaptersPage } from '../pages/challenges/ChaptersPage';
import { LessonsPage } from '../pages/challenges/LessonsPage';
import { LessonContentsPage } from '../pages/challenges/LessonContentsPage';
import { LessonQuestionsPage } from '../pages/challenges/LessonQuestionsPage';
import { LessonSharingPage } from '../pages/challenges/LessonSharingPage';
import { ProfilePage } from '../pages/profile/ProfilePage';
import { EditRegionPage } from '../pages/profile/EditRegionPage';
import { EditGeneralProfilePage } from '../pages/profile/EditGeneralProfilePage';
import { EditBehaviorProfilePage } from '../pages/profile/EditBehaviorProfilePage';
import { EditZealyIdProfilePage } from '../pages/profile/EditZealyIdProfilePage';
import { MappingPlacesPage } from '../pages/mapping/MappingPlacesPage';
import { MappingPlaceDetailsPage } from '../pages/mapping/MappingPlaceDetailsPage';
import { MappingPlaceGeneralDataPage } from '../pages/mapping/MappingPlaceGeneralDataPage';
import { MappingPlaceOilDataPage } from '../pages/mapping/MappingPlaceOilDataPage';
import { MappingPlaceShareClaimPage } from '../pages/mapping/MappingPlaceShareClaimPage';
import { MappingPlaceClaimedView } from '../pages/mapping/MappingPlaceClaimedView';
import { MappingAddRestaurantPage } from '../pages/mapping/MappingAddRestaurantPage';
import { MappingAddWmoPage } from '../pages/mapping/MappingAddWmoPage';
import { LeaderboardPage } from '../pages/leaderboard/LeaderboardPage';
import { RedeemPage } from '../pages/redeem/RedeemPage';
import { ArticlesPage } from '../pages/articles/ArticlesPage';
import { ReferralPage } from '../pages/referral/ReferralPage';
import { SharePage } from '../pages/share/SharePage';

export const routes = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/home" replace />,
      },
      {
        path: '/home',
        index: true,
        element: <HomePage />,
      },
      {
        path: '/mappings',
        element: (
          <ProtectedRoute>
            <MappingPlacesPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/challenges',
        element: <ChallengesPage />,
      },
      {
        path: '/challenges/:section/chapters',
        element: (
          <ProtectedRoute>
            <ChaptersPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/challenges/:sectionId/chapters/:segments/lessons',
        element: (
          <ProtectedRoute>
            <LessonsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ],
  },
  {
    path: '/profile',
    element: (
      <ProtectedRoute>
        <ProfilePage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/profile/general',
    element: (
      <ProtectedRoute>
        <EditGeneralProfilePage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/profile/general/country',
    element: (
      <ProtectedRoute>
        <EditRegionPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/profile/behavior',
    element: (
      <ProtectedRoute>
        <EditBehaviorProfilePage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/profile/zealy',
    element: (
      <ProtectedRoute>
        <EditZealyIdProfilePage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/challenges/:sectionId/chapters/:segments/lessons/:lessonId/contents',
    element: (
      <ProtectedRoute>
        <LessonContentsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/challenges/:sectionId/chapters/:segments/lessons/:lessonId/questions',
    element: (
      <ProtectedRoute>
        <LessonQuestionsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/challenges/:sectionId/chapters/:segments/lessons/:lessonId/share',
    element: (
      <ProtectedRoute>
        <LessonSharingPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/mappings/:id/details',
    element: <MappingDetailsLayout />,
    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            <MappingPlaceDetailsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'view',
        element: (
          <ProtectedRoute>
            <MappingPlaceClaimedView />
          </ProtectedRoute>
        ),
      },
      {
        path: 'general',
        element: (
          <ProtectedRoute>
            <MappingPlaceGeneralDataPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'info',
        element: (
          <ProtectedRoute>
            <MappingPlaceOilDataPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'claimed',
        element: (
          <ProtectedRoute>
            <MappingPlaceShareClaimPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/mappings/add/restaurant',
    element: (
      <ProtectedRoute>
        <MappingAddRestaurantPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/mappings/add/wmo',
    element: (
      <ProtectedRoute>
        <MappingAddWmoPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/leaderboard',
    element: <LeaderboardLayout />,
    children: [
      {
        path: '',
        element: (
          <ProtectedRoute>
            <LeaderboardPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: '/redeem',
    element: (
      <ProtectedRoute>
        <RedeemPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/articles',
    element: <ArticlesPage />,
  },
  {
    path: '/referral',
    element: <ReferralPage />,
  },
  {
    path: '/share',
    element: <SharePage />,
  },
]);
