import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import clsx from 'clsx';
import { createRoot } from 'react-dom/client';
import { XIcon } from 'lucide-react';
import { IconButton } from '../../buttons/IconButton';

import vechain from '../../../assets/networks/vechain.png';
import xdc from '../../../assets/networks/xdc.png';

interface IConnectOptionsDialogProps {
  id?: string | number;
  onChooseVechain: () => void;
  onChooseXDC: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

export class ConnectOptionsDialog extends React.Component<
  IConnectOptionsDialogProps,
  { loaded: boolean }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      loaded: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.renderConnectionButton = this.renderConnectionButton.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('popstate', this.handleBackButton);

    setTimeout(() => {
      this.setState({
        loaded: true,
      });
    }, 10);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('popstate', this.handleBackButton);
  }

  handleClose() {
    this.props.onClose && this.props.onClose();
    this.setState({
      loaded: false,
    });
    setTimeout(() => {
      const root = document.getElementById('root');
      if (root) {
        const modal = document.getElementById('modal' + '-' + this.props.id);
        if (modal) {
          root.removeChild(modal);
        }
      }
    }, 300);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.handleClose();
    }
  };

  handleBackButton = () => {
    this.handleClose();
  };

  renderConnectionButton({
    name,
    icon,
    onClick,
  }: {
    name: string;
    icon: string;
    onClick: () => void;
  }) {
    return (
      <button
        className={clsx([
          'flex w-full flex-row items-center',
          'rounded-lg border border-gray500 px-6 py-4 transition-all duration-300',
          'hover:bg-gray500 hover:bg-opacity-10 active:bg-gray500 active:bg-opacity-30',
        ])}
        onClick={() => {
          this.handleClose();
          onClick();
        }}
      >
        <img src={icon} alt="Vechain" className="size-8" />
        <div className="ml-3 text-lg font-medium text-raisinBlack">{name}</div>
      </button>
    );
  }

  render(): React.ReactNode {
    return (
      <div
        className={clsx({
          'fixed bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-end bg-raisinBlack transition-all duration-300': 1,
          'bg-opacity-0': !this.state.loaded,
          'bg-opacity-60': this.state.loaded,
        })}
        onClick={this.handleClose}
      >
        <div
          className={clsx({
            'flex h-[40vh] w-full max-w-[600px] flex-col rounded-t-3xl bg-isabelline': 1,
            'transition-all duration-300': 1,
            'translate-y-full opacity-50': !this.state.loaded,
            'translate-y-0 opacity-100': this.state.loaded,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="mt-8 flex flex-row items-center justify-between px-8">
            <h1 className="text-2xl font-semibold leading-normal text-raisinBlack">
              Choose Network
            </h1>
            <IconButton
              icon={XIcon}
              variant="ghost-dark"
              onClick={this.handleClose}
            />
          </div>
          <div className="mt-4 flex-1">
            <Scrollbars
              style={{
                flex: 1,
              }}
            >
              <div className="flex flex-col gap-2 px-8 pb-8">
                {this.renderConnectionButton({
                  name: 'Vechain',
                  icon: vechain,
                  onClick: this.props.onChooseVechain,
                })}
                {this.renderConnectionButton({
                  name: 'XDC Network',
                  icon: xdc,
                  onClick: this.props.onChooseXDC,
                })}
              </div>
              <div className="mt-8 text-center text-raisinBlack text-opacity-70">
                More networks coming!
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  }

  public static open(props: IConnectOptionsDialogProps) {
    const root = document.getElementById('root');
    if (root) {
      const key = Date.now();
      const div = document.createElement('div');
      div.id = 'modal' + '-' + key;
      root.appendChild(div);
      createRoot(div).render(<ConnectOptionsDialog {...props} id={key} />);
    }
  }
}
