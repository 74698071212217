import React from 'react';
import VerificationInput from 'react-verification-input';
import clsx from 'clsx';

interface IOtpInputProps {
  value: string;
  onChange: (value: string) => void;
  onComplete?: (value: string) => void;
}

export const OtpInput: React.FC<IOtpInputProps> = ({
  value,
  onChange,
  onComplete,
}) => {
  return (
    <VerificationInput
      autoFocus
      length={6}
      classNames={{
        container: '',
        character: clsx(
          'w-[48px] h-[45px] border border-inputBorder hover:border-inputBorderHover focus:border-inputBorderHover rounded-lg text-center bg-yellow400 focus:outline focus:outline-2 focus:outline-icterine50 aspect-square flex flex-row justify-center items-center',
        ),
        characterSelected: clsx(
          'outline outline-icterine outline-2 border-inputBorderActive text-raisinBlack',
        ),
        characterInactive: 'text-[#6D6D6D]',
        characterFilled: clsx('border-green'),
      }}
      value={value}
      onChange={onChange}
      onComplete={onComplete}
    />
  );
};
