import axios from 'axios';
import { toast } from 'react-toastify';
import { authorize, login } from '../redux/auth.slice';
import { store } from '../redux/store';
import { setM2ESummary } from '../redux/app.slice';

export const apiRequestToken = (data: {
  wallet_address: string;
  chain: string;
}) => {
  return new Promise((resolve) => {
    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(
          process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
          { action: 'login' },
        );
        const response = await axios.put<{
          message: string;
          verified: boolean;
          data: string;
        }>('/api/client/v1/user/token', {
          ...data,
          recaptcha_token: token,
        });
        if (response.data.data) {
          if (response.data.verified) {
            store.dispatch(
              authorize({
                isVerified: true,
                isLoggedIn: true,
                address: data.wallet_address,
                chain: data.chain,
                accessToken: response.data.data,
              }),
            );
            return resolve('verified');
          } else {
            store.dispatch(
              login({
                address: data.wallet_address,
                chain: data.chain,
                accessToken: response.data.data,
              }),
            );
          }
        }
        return resolve(true);
      } catch {
        return resolve(false);
      }
    });
  });
};

export const apiVerifyToken = async (data: { promo_code: string }) => {
  try {
    const referral = localStorage.getItem('referral-code');

    const response = await axios.post('/api/client/v1/user/verify', {
      promo_code: data.promo_code,
      ...(referral && {
        referral_code: referral,
      }),
    });
    if (response.data.data) {
      if (referral) {
        localStorage.removeItem('referral-code');
      }
      store.dispatch(
        login({
          accessToken: response.data.data,
        }),
      );
    }
    return true;
  } catch {
    return false;
  }
};

export const apiGetProfile = async (params?: any) => {
  try {
    const response = await axios.post('/api/client/v1/user/profile', params);
    store.dispatch(
      authorize({
        id: response.data.data._id,
        address: response.data.data.wallet_address,
        chain: response.data.data.chain,
        username: response.data.data.username,
        avatar: response.data.data.profile_image,
        scammed: response.data.data.scammed,
        disabled: response.data.data.disabled,
        telegramId: response.data.data.telegram_id,
        xp: response.data.data.xp,
        totalXp: response.data.data.total_xp,
        reward: response.data.data.reward_amount,
        country: response.data.data.country,
        city: response.data.data.city,
        gender: response.data.data.gender,
        referralCode: response.data.data.referral_code,
        age: response.data.data.age_gap,
        m2eLocked: response.data.data.m2e_locked,
        ucoOilToCook: response.data.data.use_oil_to_cook,
        ucoRecycling: response.data.data.recycle_uco,
        ucoRecyclingAmount: response.data.data.recycle_amount,
        ucoProductionPeriod: response.data.data.uco_production_period,
        zealyId: response.data.data.zealy_id,
        ratio: response.data.data.ratio,
        isProfileCompleted:
          response.data.data.username &&
          response.data.data.age_gap &&
          response.data.data.country &&
          response.data.data.city &&
          response.data.data.gender &&
          response.data.data.uco_production_period
            ? true
            : false,
      }),
    );
    store.dispatch(
      setM2ESummary({
        m2eCreatePlaceXp: response.data.data.m2e_create_place_xp,
        m2eCreateWmoXp: response.data.data.m2e_create_wmo_xp,
      }),
    );
    return true;
  } catch {
    return false;
  }
};

export const apiUpdateProfile = async (data: {
  username?: string;
  profile_image?: string;
  country?: string;
  city?: string;
  zealy_id?: string;
  age_gap?: string;
  gender?: string;
  uco_production_period?: string;
  use_oil_to_cook?: boolean;
  recycle_uco?: boolean;
}) => {
  try {
    const response = await axios.put('/api/client/v1/user/profile', {
      ...data,
    });
    await apiGetProfile();

    return response.data.award_xp ?? 0;
  } catch {
    toast('Failed to update your profile, please try again later');
    return false;
  }
};
