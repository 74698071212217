import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { colors } from '../../config/theme';
import 'react-circular-progressbar/dist/styles.css';

interface ICircularProgressIndicatorProps {
  value?: number;
  size?: 'small' | 'medium' | 'large';
  progress?: number;
  variant?: 'dark' | 'light' | 'gray';
  className?: string;
}

export const CircularProgressIndicator: React.FC<
  ICircularProgressIndicatorProps
> = ({ value, className, variant }) => {
  return (
    <CircularProgressbar
      value={value ? value : 0}
      strokeWidth={4}
      styles={buildStyles({
        rotation: 0,
        trailColor:
          variant === 'dark'
            ? colors.royalBlueDark
            : variant === 'gray'
              ? colors.purpleNavy
              : colors.shadowBlue,
        pathColor: colors.icterine,
      })}
      className={className}
    />
  );
};
