import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import {
  RedeemDialog,
  FilledButton,
  IconButton,
  SecondaryLayout,
} from '../../components';
import {
  ArrowRightIcon,
  ClipboardCheckIcon,
  CopyIcon,
  XIcon,
  ZapIcon,
} from 'lucide-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useInfiniteQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { IRootState } from '../../redux/store';
import { apiGetProfile } from '../../apis/auth';
import { apiClaimReward, apiGetTransactions } from '../../apis/rewards';

export const RedeemPage: React.FC = () => {
  const navigate = useNavigate();
  const { address, xp, ratio, totalXp } = useSelector(
    (state: IRootState) => state.auth,
  );

  const {
    data: transactions,
    status,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['transactions'],
    queryFn: async ({ pageParam }) => {
      return await apiGetTransactions({
        skip: pageParam,
        limit: 100,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < 100) {
        return undefined;
      }
      return allPages.length * 100;
    },
  });

  const [copied, setCopied] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [percentage, setPercentage] = useState(100);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  const handleCopyAddress = useCallback(() => {
    if (address) {
      copy(address);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [address]);

  const handleRedeem = useCallback(() => {
    if (!xp || xp <= 0 || processing || !ratio) {
      return;
    }

    RedeemDialog.open({
      initialValue: percentage,
      total: xp,
      ratio: ratio,
      onCancel: (value: number) => {
        setPercentage(value);
      },
      onConfirm: async (value: number) => {
        if (value > 0) {
          setProcessing(true);
          const result = await apiClaimReward({
            percentage: value,
          });
          if (result && result > 0) {
            toast(
              `You have converted ${((xp * value) / 100).toFixed(0)} XP into ${Number(result).toFixed(2)} B3TR`,
            );
            await refetch();
          }
          setProcessing(false);
        }
      },
    });
  }, [xp, ratio, percentage]);

  useEffect(() => {
    apiGetProfile({
      need_balance: true,
      need_ratio: true,
    });
  }, []);

  const LoadingTransactions = useMemo(() => {
    return Array(10)
      .fill(true)
      .map((_, index) => (
        <div
          key={`loading-${index}`}
          className="mb-2 flex flex-row items-center rounded-2xl px-4 py-3"
        >
          <div className="flex-1">
            <div className="flex flex-row items-center gap-2">
              <div className="h-4 w-40 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
            </div>
            <div className="mt-1 h-3 w-20 animate-pulse rounded-full bg-isabelline bg-opacity-20" />
          </div>
          <div className="h-4 w-20 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
        </div>
      ));
  }, []);

  return (
    <SecondaryLayout>
      <div
        className={clsx([
          'absolute left-0 right-0 top-0 z-20 ',
          'flex flex-row justify-center bg-raisinBlack px-4 py-6',
        ])}
      >
        <div className="flex w-full max-w-[600px] flex-row items-center justify-between">
          <IconButton
            variant="dark"
            size="small"
            icon={XIcon}
            onClick={handleBack}
          />
          <FilledButton
            variant="icterine"
            loading={processing}
            disabled={processing || !xp || xp <= 0}
            onClick={handleRedeem}
          >
            Redeem
          </FilledButton>
        </div>
      </div>
      <SecondaryLayout.Content>
        <div className="px-6 pt-40">
          <div className="text-lg font-bold text-isabelline">
            Current balance
          </div>
          <div className="mt-2 flex flex-row items-center">
            <ZapIcon className="mr-1 fill-icterine stroke-icterine" />
            <h1 className="ml-1 text-3xl text-white">
              {xp?.toFixed(0) ?? '0'}
              <span className="text-shadowBlue">
                &nbsp;/&nbsp;
                {totalXp?.toFixed(0) ?? '0'} XP
              </span>
            </h1>
          </div>
          <div className="mt-2 text-sm leading-relaxed text-beauBlue">
            ~{' '}
            {(
              ((((xp ? xp : 0) * percentage) / 100) * (ratio ?? 1)) /
              100
            ).toFixed(3)}{' '}
            B3TR
          </div>
          <div className="text-xs leading-relaxed text-shadowBlue">
            ~{(1 / (ratio ? ratio / 100 : 1)).toLocaleString()} XP per 1 B3TR
          </div>
          {xp === 0 && (
            <NavLink to="/challenges">
              <button
                className={clsx([
                  'mt-6 w-full px-4 py-3',
                  'rounded-2xl bg-shadowBlue bg-opacity-20',
                  'hover:bg-opacity-30 focus:bg-opacity-15 active:bg-opacity-15',
                  'flex flex-col items-start',
                ])}
              >
                <h2 className="w-full text-center text-xl leading-normal text-white">
                  You didn&apos;t earn any reward
                </h2>
                <p className="w-full text-center text-sm leading-relaxed text-beauBlue">
                  Head on to Challenges
                </p>
              </button>
            </NavLink>
          )}
          <div className="mt-16 font-bold text-isabelline">
            Your wallet address
          </div>
          <div className="mt-1 flex flex-row items-center">
            <div className="text-sm text-isabelline">
              {address?.substr(0, 10)}...{address?.substr(-8, 8)}
            </div>
            <IconButton
              size="small"
              variant="dark"
              icon={copied ? ClipboardCheckIcon : CopyIcon}
              className="ml-2"
              onClick={handleCopyAddress}
            />
          </div>
        </div>
        <div className="mt-16 px-6">
          <div className="mb-3 font-bold text-isabelline">History</div>
          {transactions &&
            transactions.pages &&
            (transactions.pages.length === 0 ||
              transactions.pages[0].length === 0) && (
              <div className="-mt-2 text-sm text-isabelline">
                No transactions yet
              </div>
            )}
          {status === 'pending' ? (
            LoadingTransactions
          ) : (
            <>
              <div>
                {transactions?.pages?.map((page, index) => (
                  <React.Fragment key={`page-${index}`}>
                    {page.map((item, ii) => (
                      <a
                        key={`page-${index}-${ii}`}
                        className="mb-2 flex flex-row items-center rounded-2xl bg-purpleNavy bg-opacity-20 px-4 py-3"
                        href={item.transaction_link}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <div className="flex-1">
                          <div className="flex flex-row items-center gap-2">
                            <span className="text-isabelline">
                              {item.xp.toFixed(0)} XP
                            </span>
                            <ArrowRightIcon
                              className="text-beauBlue"
                              size={16}
                            />
                            <span className="text-isabelline">
                              {Number(item.rewarded_amount).toFixed(2)} B3TR
                            </span>
                          </div>
                          <div className="mt-1 text-xs text-beauBlue">
                            {moment(item.date).format('DD MMMM YYYY')}
                          </div>
                        </div>
                        <div className="uppercase text-beauBlue">
                          {item.status}
                        </div>
                      </a>
                    ))}
                  </React.Fragment>
                ))}
              </div>
              <div className="mb-8 mt-8 flex flex-row justify-center">
                {hasNextPage ? (
                  <FilledButton onClick={fetchNextPage}>
                    {isFetchingNextPage ? 'Loading...' : 'Load more'}
                  </FilledButton>
                ) : null}
              </div>
              {isFetchingNextPage && LoadingTransactions}
            </>
          )}
          <div className="h-40" />
        </div>
      </SecondaryLayout.Content>
    </SecondaryLayout>
  );
};
