import React, { useCallback, useEffect, useState } from 'react';
import {
  LessonHeader,
  AppContent,
  FilledButton,
  AlertDialog,
  RadioGroup,
} from '../../components';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  apiAnswerQuestion,
  apiGetLessons,
  apiGetQuestions,
} from '../../apis/lms';

export const LessonQuestionsPage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const segments = params.segments;
  if (
    !segments ||
    !segments.includes('-') ||
    segments.split('-').length !== 2 ||
    !params.lessonId
  ) {
    return <Navigate to="/challenges" />;
  }

  const chapterId = segments.split('-')[1];
  const lessonId = params.lessonId;
  const sectionId = params.sectionId;
  const { data: questions, isLoading: isLoadingQuestions } = useQuery({
    queryKey: ['lms', 'chapters', chapterId, 'lessons', lessonId, 'questions'],
    queryFn: async () => {
      return await apiGetQuestions(lessonId);
    },
  });
  const { data: lessons, refetch } = useQuery({
    queryKey: ['lms', 'chapters', chapterId, 'lessons'],
    queryFn: async () => {
      return await apiGetLessons(chapterId);
    },
  });

  const [totalXp, setTotalXp] = useState(0);
  const [currentXp, setCurrentXp] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [answeredResults, setAnsweredResults] = useState<Map<string, boolean>>(
    new Map(),
  );
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [isAnswering, setIsAnswering] = useState(false);

  const handleCancel = useCallback(() => {
    AlertDialog.open({
      title: 'Are you sure you want to leave this lesson?',
      message: 'Rest assured, any progress you make will be saved an stored.',
      cancelLabel: 'Back',
      confirmLabel: 'Leave lesson',
      confirmStyle: 'filled',
      confirmVariant: 'pink',
      onConfirm: () => navigate(-1),
    });
  }, []);

  const handleContinue = useCallback(async () => {
    if (!questions) {
      return;
    }

    if (selectedItem) {
      setIsAnswering(true);
      const result = await apiAnswerQuestion({
        questionId: questions[currentQuestion - 1]._id,
        answers: questions[currentQuestion - 1].answers.map((item) => ({
          _id: item._id,
          checked: item._id === selectedItem,
        })),
      });
      if (result) {
        await refetch();
        if (currentQuestion === questions.length) {
          navigate(
            `/challenges/${sectionId}/chapters/${segments}/lessons/${lessonId}/share`,
            {
              replace: true,
            },
          );
        } else {
          setSelectedItem('');
          setCurrentQuestion(currentQuestion + 1);
        }
      }
      setAnsweredResults((values) => {
        const newValues = new Map(values);
        newValues.set(selectedItem, result);
        return newValues;
      });
      setIsAnswering(false);
    }
  }, [currentQuestion, selectedItem, questions]);

  useEffect(() => {
    if (lessons) {
      for (const lesson of lessons) {
        if (lesson._id === lessonId) {
          setTotalXp(lesson.total_xp);
          setCurrentXp(lesson.user_xp);
          return;
        }
      }
    }
  }, [lessons]);

  useEffect(() => {
    if (questions) {
      for (let i = 0; i < questions.length; i++) {
        if (!questions[i].answered) {
          return setCurrentQuestion(i + 1);
        }
      }
    }
  }, [questions]);

  useEffect(() => {
    AlertDialog.open({
      title: 'Stay Sharp, Score Big!',
      message: (
        <>
          <p className="text-raisinBlack">
            Every question counts. Incorrect answers will result in fewer
            points! Maximize your score by answering carefully.
          </p>
          <p className="mt-8 font-bold text-raisinBlack">
            Ready to prove your knowledge?
          </p>
        </>
      ),
      cancelLabel: 'Continue',
    });
  }, []);

  return (
    <div className="relative min-h-[100vh] w-full max-w-[600px] bg-raisinBlack">
      <LessonHeader
        currentPage={currentQuestion}
        currentXp={currentXp}
        totalPages={questions ? questions.length : 0}
        totalXp={questions ? totalXp : 0}
        onClose={handleCancel}
      />
      <AppContent className="pt-[90px]">
        <div
          className="flex flex-col"
          style={{
            minHeight: 'calc(100vh - 90px)',
          }}
        >
          {isLoadingQuestions ? (
            <>
              <div className="h-[300px] w-full animate-pulse bg-isabelline bg-opacity-25" />
              <div className="mt-8 animate-pulse px-8">
                <div className="h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/2 rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/4 rounded-full bg-isabelline bg-opacity-25" />
              </div>
              <div className="mt-8 animate-pulse px-8">
                <div className="h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/2 rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/4 rounded-full bg-isabelline bg-opacity-25" />
              </div>
              <div className="mt-8 animate-pulse px-8">
                <div className="h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/2 rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/4 rounded-full bg-isabelline bg-opacity-25" />
              </div>
            </>
          ) : (
            <>
              {questions && questions.length > 0 ? (
                <>
                  <div className="mt-8 flex-1">
                    {questions[currentQuestion - 1] ? (
                      <h1 className="px-8 text-3xl text-white">
                        {questions[currentQuestion - 1].question}
                      </h1>
                    ) : null}
                    <div className="mt-4 px-8">
                      <RadioGroup
                        items={questions[currentQuestion - 1].answers.map(
                          (answer) => ({
                            label: answer.answer,
                            value: answer._id,
                            highlight: answer._id === selectedItem,
                            error: answeredResults.get(answer._id) === false,
                          }),
                        )}
                        value={selectedItem}
                        onChange={setSelectedItem}
                      />
                      {answeredResults.get(selectedItem) === false && (
                        <div className="mt-4 rounded-lg bg-red400 bg-opacity-30 p-2 text-red400">
                          Incorrect answer: Please review your choice.
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <></>
                </>
              )}
            </>
          )}

          <div className="flex flex-row items-center gap-4 p-8">
            <FilledButton
              size="large"
              variant="secondary"
              className="flex-1"
              onClick={handleCancel}
            >
              Close
            </FilledButton>
            <FilledButton
              size="large"
              variant="icterine"
              className="flex-[2]"
              loading={isAnswering}
              disabled={isAnswering}
              onClick={handleContinue}
            >
              Continue
            </FilledButton>
          </div>
        </div>
      </AppContent>
    </div>
  );
};
