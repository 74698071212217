import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authSlice } from './auth.slice';
import { appSlice } from './app.slice';
import { placesSlice } from './places.slice';
import { lessonsSlice } from './lessons.slice';
import { walletSlice } from './wallet.slice';

const persistConfig = {
  key: 'uco-admin-portal',
  storage,
  blacklist: [],
};

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  app: appSlice.reducer,
  places: placesSlice.reducer,
  lessons: lessonsSlice.reducer,
  wallet: walletSlice.reducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export const persistor = persistStore(store, {}, () => {
  console.log('Backed up stored authentication data...');
});

export type IRootState = ReturnType<typeof rootReducer>;
