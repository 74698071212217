import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SecurityProvider } from './providers/SecurityProvider';
import { BootstrapProvider } from './providers/BootstrapProvider';
import { persistor, store } from './redux/store';
import { routes } from './routes/routes';
import { WalletProvider } from './providers/WalletProvider';

const queryClient = new QueryClient();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <WalletProvider>
          <SecurityProvider>
            <BootstrapProvider>
              <QueryClientProvider client={queryClient}>
                <RouterProvider router={routes} />
              </QueryClientProvider>
              <ToastContainer
                position="bottom-center"
                theme="dark"
                hideProgressBar
                closeOnClick
                autoClose={3000}
                pauseOnHover={false}
                pauseOnFocusLoss={false}
              />
            </BootstrapProvider>
          </SecurityProvider>
        </WalletProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
