import React from 'react';
import clsx from 'clsx';

export const MappingDetailsFooter: React.FC = () => {
  return (
    <div
      className={clsx([
        'flex flex-row justify-center px-6 pb-6',
        'absolute bottom-0 left-0 right-0 z-30',
      ])}
    >
      <div
        id="mapping-details-footer"
        className="flex w-full max-w-[600px] flex-row items-center gap-4"
      >
        <></>
      </div>
    </div>
  );
};
