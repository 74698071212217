import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IWalletState {
  account?: string;
  chainName?: string;
  chainId?: number;
}

const initialState: IWalletState = {
  account: undefined,
  chainName: undefined,
  chainId: undefined,
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: initialState as IWalletState,
  reducers: {
    setWallet(state, action: PayloadAction<IWalletState>) {
      return action.payload;
    },
    updateWallet(state, action: PayloadAction<Partial<IWalletState>>) {
      return { ...state, ...action.payload };
    },
    clearWallet() {
      return initialState;
    },
  },
});

export const { setWallet, updateWallet, clearWallet } = walletSlice.actions;
