import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import {
  ClipboardCheckIcon,
  CopyIcon,
  SquareArrowOutUpRightIcon,
} from 'lucide-react';
import { Spinner, OtpInput, IconButton, FilledButton } from '../components';
import { useDispatch, useSelector } from 'react-redux';
import { useWallet } from './WalletProvider';
import { apiGetProfile, apiRequestToken, apiVerifyToken } from '../apis/auth';
import { IRootState } from '../redux/store';
import { logout } from '../redux/auth.slice';

export const SecurityProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { account, chainName } = useSelector(
    (state: IRootState) => state.wallet,
  );
  const { isLoggedIn, isVerified, accessToken, scammed, disabled } =
    useSelector((state: IRootState) => state.auth);
  const { disconnectWallet } = useWallet();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [prompting, setPrompting] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [failedVerify, setFailedVerify] = useState(false);

  const [promoCode, setPromoCode] = useState('');
  const [promoCodeCopyButton, setPromoCodeCopyButton] =
    useState('Copy Address');

  const getToken = useCallback(async () => {
    if (account && chainName) {
      setLoading(true);
      setConnecting(true);
      const result = await apiRequestToken({
        wallet_address: account,
        chain: chainName,
      });
      setConnecting(false);
      if (!result) {
        setLoading(false);
      }
      if (result === 'verified') {
        setPrompting(false);
        setLoading(false);
        setVerifying(false);

        setConnecting(true);
        await apiGetProfile({
          need_balance: true,
          need_ratio: true,
        });
        setConnecting(false);
      }
    }
  }, [account, chainName]);

  const verifyToken = useCallback(async () => {
    setLoading(true);
    setPrompting(true);
  }, []);

  const handleCopyAddress = useCallback(() => {
    if (account) {
      copy(account ?? '');
      setPromoCodeCopyButton('Copied');
      setTimeout(() => {
        setPromoCodeCopyButton('Copy Address');
      }, 3000);
    }
  }, [account]);

  const handleCompletePromoCode = async (code: string) => {
    setVerifying(true);
    setFailedVerify(false);
    const result = await apiVerifyToken({
      promo_code: code,
    });
    setVerifying(false);
    if (result) {
      setPrompting(false);
      setLoading(false);

      const result = await apiGetProfile();
      if (result) {
        setConnecting(false);
      } else {
        disconnectWallet();
      }
    } else {
      setPrompting(true);
      setFailedVerify(true);
    }
  };

  useEffect(() => {
    if (account && chainName) {
      if (!isLoggedIn) {
        getToken();
        return;
      }
      if (!isVerified && accessToken) {
        verifyToken();
        return;
      }

      setLoading(false);
      setPrompting(false);
      setConnecting(false);
      setVerifying(false);
      return;
    } else {
      dispatch(logout());
      setLoading(false);
      setPrompting(false);
      setConnecting(false);
      setVerifying(false);
    }
  }, [account, chainName, isLoggedIn, isVerified, accessToken]);

  return (
    <>
      <>{children}</>
      <div
        className={clsx([
          'fixed bottom-0 left-0 right-0 top-0 z-50 bg-raisinBlack transition-all duration-300',
          'bg-opacity-60',
          loading ? 'block' : 'hidden',
        ])}
      >
        <div className="flex h-full flex-row items-center justify-center">
          {connecting && (
            <div className="flex flex-col items-center justify-center rounded-3xl bg-isabelline p-8">
              <Spinner />
              <span className="text-x1 mt-4 text-raisinBlack">Connecting</span>
            </div>
          )}
          {prompting && (
            <div className="w-[360px] rounded-3xl bg-isabelline p-6 pt-12">
              <div className="text-2xl font-semibold text-raisinBlack">
                Access code
              </div>
              <div className="mt-6">
                <div className="text font-bold text-raisinBlack">
                  Your wallet address
                </div>
                <div className="mt-1 flex flex-row items-center">
                  <div className="text-sm text-raisinBlack">
                    {account?.substring(0, 6)}
                    ...
                    {account?.substring(
                      (account?.length ?? 0) - 4,
                      account?.length,
                    )}
                  </div>
                  <IconButton
                    size="small"
                    icon={
                      promoCodeCopyButton != 'Copy Address'
                        ? ClipboardCheckIcon
                        : CopyIcon
                    }
                    variant="ghost-dark"
                    onClick={handleCopyAddress}
                  />
                </div>
              </div>
              <div className="mt-6">
                {verifying ? (
                  <div className="flex flex-row items-center justify-center">
                    <Spinner />
                    <span className="ml-2 leading-none text-raisinBlack">
                      Verifying
                    </span>
                  </div>
                ) : (
                  <OtpInput
                    value={promoCode}
                    onChange={setPromoCode}
                    onComplete={handleCompletePromoCode}
                  />
                )}
              </div>
              {failedVerify && (
                <div className="mt-4 text-center text-sm text-red">
                  Provide correct promo-code you received
                </div>
              )}
              <div className="mt-6">
                <h5 className="font-bold text-raisinBlack">Instructions</h5>
                <p className="mt-1 text-sm leading-normal text-raisinBlack">
                  To get your code, please click the link below to join our
                  Telegram group and type{' '}
                  <span className="font-mono">/oilybot</span>
                  &nbsp;in the chat.
                </p>
                <div className="mt-2 flex flex-row items-center">
                  <p className="text-sm leading-normal text-raisinBlack">
                    Join telegram group
                  </p>
                  <a
                    className="ml-3 text-raisinBlack"
                    href="https://t.me/uco_network_official/1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SquareArrowOutUpRightIcon size={14} />
                  </a>
                </div>
              </div>
              <div className="mt-6 text-xs text-raisinBlack text-opacity-70">
                This site is protected by <strong>reCAPTCHA</strong> and the
                Google&nbsp;
                <a
                  href="https://policies.google.com/privacy"
                  className="text-royalBlueDark hover:underline active:underline"
                >
                  Privacy Policy
                </a>
                &nbsp;and&nbsp;
                <a
                  href="https://policies.google.com/terms"
                  className="text-royalBlueDark hover:underline active:underline"
                >
                  Terms of Service
                </a>
                &nbsp;apply.
              </div>
              <div className="h-12" />
            </div>
          )}
        </div>
      </div>
      <div
        className={clsx([
          'fixed bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-end bg-raisinBlack px-2 pb-2 transition-all duration-300',
          'bg-opacity-60',
          disabled || scammed ? 'block' : 'hidden',
        ])}
      >
        <div
          className={clsx({
            'flex min-h-80 w-full max-w-[580px] flex-col rounded-3xl bg-isabelline px-8 py-8': 1,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex-1">
            <h1 className="text-[32px] font-semibold leading-normal text-raisinBlack">
              Account Locked
            </h1>
            <p
              className={clsx([
                'font-normal leading-normal text-raisinBlack',
                'mt-4',
              ])}
            >
              Your account has been flagged for suspicious behavior such as
              multiple accounts/wallets or identified as a bot and has been
              blocked. If you feel this has been done in error you can raise a
              support ticket on our Telegram support channel.
            </p>
            <a
              className="mt-4 flex flex-row items-center font-normal leading-normal text-raisinBlack"
              href="https://t.me/uco_network_official/74"
              target="_blank"
              rel="noreferrer"
            >
              <span>Open Telegram support</span>
              <SquareArrowOutUpRightIcon size={14} className="ml-2" />
            </a>
          </div>
          <div className="mt-16 flex flex-row items-center justify-start">
            <FilledButton
              size="large"
              variant="pink"
              onClick={disconnectWallet}
            >
              Log out
            </FilledButton>
          </div>
        </div>
      </div>
    </>
  );
};
