import React, { useCallback } from 'react';
import clsx from 'clsx';
import { CheckIcon } from 'lucide-react';

interface ICheckboxProps {
  checked: boolean;
  className?: string;
  onChange: (arg: boolean) => void;
}

export const Checkbox: React.FC<ICheckboxProps> = ({
  checked,
  className,
  onChange,
}) => {
  const onClick = useCallback(() => {
    onChange(!checked);
  }, [checked]);

  return (
    <button
      className={clsx([
        'inline-block h-[24px] w-[24px]',
        'flex flex-row items-center justify-center',
        'rounded-md bg-purpleNavy',
        className ?? '',
      ])}
      onClick={onClick}
    >
      {checked && (
        <CheckIcon className="h-[16px] w-[16px] text-white" strokeWidth={4} />
      )}
    </button>
  );
};
