import React, { PropsWithChildren, useCallback } from 'react';
import clsx from 'clsx';
import Scrollbars from 'react-custom-scrollbars-2';
import { IconButton } from '../buttons/IconButton';
import { ArrowLeftIcon, XIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface IProfileLayoutProps {
  style?: 'dark' | 'light';
  title?: string;
  showBackButton?: boolean;
}

export const ProfileLayout: React.FC<
  PropsWithChildren<IProfileLayoutProps>
> = ({ children, title, showBackButton, style = 'dark' }) => {
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  const handleClose = useCallback(() => {
    navigate('/home');
  }, []);

  return (
    <Scrollbars
      style={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <div className="flex flex-row justify-center">
        <div
          className={clsx([
            'min-h-[100vh] w-full max-w-[600px]',
            style === 'dark' ? 'bg-raisinBlack' : 'bg-isabelline',
          ])}
        >
          <div className="flex flex-row justify-center">
            <div className="flex w-full max-w-[600px] flex-row items-center justify-between px-8 py-8">
              <div>
                {showBackButton ? (
                  <IconButton
                    icon={ArrowLeftIcon}
                    size="small"
                    variant={style === 'dark' ? 'ghost' : 'ghost-dark'}
                    onClick={handleBack}
                  />
                ) : (
                  <div className="size-[28px]" />
                )}
              </div>
              <div
                className={clsx([
                  'leading-none',
                  style === 'dark' ? 'text-isabelline' : 'text-raisinBlack',
                ])}
              >
                {title ?? 'Settings'}
              </div>
              <div>
                <IconButton
                  icon={XIcon}
                  size="small"
                  variant={style === 'dark' ? 'ghost' : 'ghost-dark'}
                  onClick={handleClose}
                />
              </div>
            </div>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </Scrollbars>
  );
};
