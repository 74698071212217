import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import ordinal from 'ordinal';
import Scrollbars from 'react-custom-scrollbars-2';
import { FilledButton, XPIndicator } from '../../components';
import { useSelector } from 'react-redux';
import { useInfiniteQuery } from '@tanstack/react-query';
import { apiGetLeaderboardData } from '../../apis/leaderboard';
import { IRankingItem } from '../../apis/types';
import { IRootState } from '../../redux/store';

export const LeaderboardPage: React.FC = () => {
  const { id: authId } = useSelector((state: IRootState) => state.auth);

  const startAt = useMemo(() => moment('2024-04-01').startOf('month'), []);
  const endAt = useMemo(() => moment().endOf('month'), []);

  const scrollbar = useRef<Scrollbars>(null);
  const [selectedMonth, setSelectedMonth] = useState(moment());

  const [firstRanking, setFirstRanking] = useState<IRankingItem | null>(null);
  const [secondRanking, setSecondRanking] = useState<IRankingItem | null>(null);
  const [thirdRanking, setThirdRanking] = useState<IRankingItem | null>(null);
  const [userRanking, setUserRanking] = useState<IRankingItem | null>(null);

  const { data, status, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ['leaderboard', selectedMonth.year(), selectedMonth.month()],
      queryFn: async ({ pageParam }) => {
        const result = await apiGetLeaderboardData({
          month: selectedMonth.month() + 1,
          year: selectedMonth.year(),
          limit: 100,
          skip: pageParam,
        });

        if (result.first3.length > 0) {
          for (const item of result.first3) {
            if (item.rank === 1) {
              setFirstRanking(item);
            }
            if (item.rank === 2) {
              setSecondRanking(item);
            }
            if (item.rank === 3) {
              setThirdRanking(item);
            }
          }
        }
        if (result.me) {
          setUserRanking(result.me);
        }
        return result.data;
      },
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < 100) {
          return undefined;
        }
        return allPages.length * 100;
      },
    });

  const Months = useMemo(() => {
    const t = moment(startAt);
    const months = [];
    while (t.isSameOrBefore(endAt)) {
      const tick = moment(t);

      months.push(
        <button
          key={`month-${tick.format('YYYY-MM')}`}
          className={clsx([
            'text-nowrap text-3xl',
            'last-of-type:pr-6',
            selectedMonth.year() === tick.year() &&
            selectedMonth.month() === tick.month()
              ? 'text-isabelline'
              : 'text-shadowBlue hover:text-isabelline hover:text-opacity-80 active:text-isabelline active:text-opacity-60',
          ])}
          onClick={() =>
            setSelectedMonth(
              moment(moment(tick).format('YYYY-MM-DD')).startOf('month'),
            )
          }
        >
          {tick.month() === 11 ||
          (tick.month() === endAt.month() && tick.year() === endAt.year())
            ? tick.format('MMMM YYYY')
            : tick.format('MMMM')}
        </button>,
      );
      t.add(1, 'month');
    }
    return months;
  }, [startAt, endAt, selectedMonth]);

  const LoadingPlaceholders = useMemo(() => {
    return Array(10)
      .fill(true)
      .map((_, index) => (
        <div
          key={`loading-${index}`}
          className={clsx([
            'rounded-2xl bg-purpleNavy bg-opacity-20 py-2 pl-2 pr-4',
            'mb-2 flex flex-row items-center',
          ])}
        >
          <div className="mr-2 h-[40px] w-[40px] animate-pulse rounded-full bg-isabelline bg-opacity-30" />
          <div className="flex-1">
            <div className="h-3 w-8 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
            <div className="mt-1 h-3 w-20 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
          </div>
          <div className="h-5 w-16 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
        </div>
      ));
  }, []);

  useEffect(() => {
    scrollbar.current?.scrollToRight();
  }, [scrollbar]);

  useEffect(() => {
    const subscribe = (e: Event) => {
      console.log(e);
    };

    window.addEventListener('scroll', subscribe);

    return () => {
      window.removeEventListener('scroll', subscribe);
    };
  }, []);

  return (
    <>
      <div className="px-6 pt-24">
        <Scrollbars
          style={{
            height: 46,
          }}
          ref={scrollbar}
        >
          <div className="flex flex-row items-center gap-4">{Months}</div>
        </Scrollbars>
        <h1 className="text-3xl text-beauBlue">Leaderboard</h1>
        <div className="mb-16 mt-16">
          <div className="flex flex-row items-end justify-around">
            <div className="flex flex-col items-center">
              <div className="relative">
                {secondRanking ? (
                  <img
                    src={secondRanking.userimage}
                    className="h-[40px] w-[40px] rounded-full object-cover"
                  />
                ) : (
                  <div className="h-[40px] w-[40px] animate-pulse rounded-full bg-isabelline bg-opacity-30" />
                )}
                <div
                  className={clsx([
                    'absolute right-0 top-0 -translate-y-1/2 translate-x-1/2',
                    'rounded-full bg-isabelline px-3 py-1 text-xs font-bold text-raisinBlack',
                  ])}
                >
                  2nd
                </div>
              </div>
              {secondRanking ? (
                <div className="mb-2 mt-2 text-sm font-bold text-isabelline">
                  {secondRanking.username}
                </div>
              ) : (
                <div className="mb-2 mt-2 h-3 w-20 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
              )}
              {secondRanking ? (
                <XPIndicator
                  current={secondRanking.xp.toFixed(0)}
                  textClassName="text-beauBlue"
                />
              ) : (
                <div className="h-4 w-16 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
              )}
            </div>
            <div className="flex flex-col items-center">
              <div className="relative">
                {firstRanking ? (
                  <img
                    src={firstRanking.userimage}
                    className="h-[64px] w-[64px] rounded-full object-cover"
                  />
                ) : (
                  <div className="h-[64px] w-[64px] animate-pulse rounded-full bg-isabelline bg-opacity-30" />
                )}
                <div
                  className={clsx([
                    'absolute right-0 top-0 -translate-y-1/2 translate-x-1/2',
                    'rounded-full bg-icterine px-3 py-1 text-xs font-bold text-raisinBlack',
                  ])}
                >
                  1st
                </div>
              </div>
              {firstRanking ? (
                <div className="mb-2 mt-2 text-sm font-bold text-isabelline">
                  {firstRanking.username}
                </div>
              ) : (
                <div className="mb-2 mt-2 h-3 w-20 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
              )}
              {firstRanking ? (
                <XPIndicator
                  current={firstRanking.xp.toFixed(0)}
                  textClassName="text-beauBlue"
                />
              ) : (
                <div className="h-4 w-16 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
              )}
            </div>
            <div className="flex flex-col items-center">
              <div className="relative">
                {thirdRanking ? (
                  <img
                    src={thirdRanking.userimage}
                    className="h-[40px] w-[40px] rounded-full object-cover"
                  />
                ) : (
                  <div className="h-[40px] w-[40px] animate-pulse rounded-full bg-isabelline bg-opacity-30" />
                )}
                <div
                  className={clsx([
                    'absolute right-0 top-0 -translate-y-1/2 translate-x-1/2',
                    'rounded-full bg-beauBlue px-3 py-1 text-xs font-bold text-royalBlueDark',
                  ])}
                >
                  3rd
                </div>
              </div>
              {thirdRanking ? (
                <div className="mb-2 mt-2 text-sm font-bold text-isabelline">
                  {thirdRanking.username}
                </div>
              ) : (
                <div className="mb-2 mt-2 h-3 w-20 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
              )}
              {thirdRanking ? (
                <XPIndicator
                  current={thirdRanking.xp.toFixed(0)}
                  textClassName="text-beauBlue"
                />
              ) : (
                <div className="h-4 w-16 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
              )}
            </div>
          </div>
        </div>
        <div>
          {status === 'pending' ? (
            LoadingPlaceholders
          ) : (
            <>
              <div className="min-h-[40vh]">
                {data?.pages.map((page, index) => (
                  <React.Fragment key={`page-${index}`}>
                    {page.map((item) =>
                      item.rank >= 4 && item.user_id !== authId ? (
                        <div
                          key={item.user_id}
                          className={clsx([
                            'rounded-2xl bg-purpleNavy bg-opacity-20 py-3 pl-2 pr-4',
                            'mb-2 flex flex-row items-center',
                          ])}
                        >
                          <div className="mr-2 h-[40px] w-[40px] rounded-full">
                            <img
                              src={item.userimage}
                              className="h-[40px] w-[40px] rounded-full object-cover"
                            />
                          </div>
                          <div className="flex-1">
                            <div className="text-sm font-bold text-beauBlue">
                              {ordinal(item.rank)}
                            </div>
                            <div className="text-sm font-bold text-isabelline">
                              {item.username}
                            </div>
                          </div>
                          <XPIndicator
                            current={item.xp.toFixed(0)}
                            textClassName="text-beauBlue"
                          />
                        </div>
                      ) : null,
                    )}
                  </React.Fragment>
                ))}
              </div>
              <div className="mb-8 mt-8 flex flex-row justify-center">
                {hasNextPage ? (
                  <FilledButton onClick={fetchNextPage}>
                    {isFetchingNextPage ? 'Loading...' : 'Load more'}
                  </FilledButton>
                ) : (
                  <FilledButton variant="dark" disabled>
                    Nothing more to load
                  </FilledButton>
                )}
              </div>
              {isFetchingNextPage && LoadingPlaceholders}
            </>
          )}
          <div className="h-40" />
        </div>
      </div>
      {userRanking && (
        <div
          className={clsx([
            'fixed bottom-6 left-6 right-6 z-50',
            'flex flex-row justify-center',
          ])}
        >
          <div
            className={clsx([
              'w-full max-w-[556px]',
              'rounded-2xl bg-isabelline py-3 pl-2 pr-4',
              'mb-2 flex flex-row items-center',
            ])}
          >
            <img
              src={userRanking.userimage}
              className="mr-2 h-[40px] w-[40px] rounded-full object-cover"
            />
            <div className="flex-1">
              <div className="text-sm font-bold text-shadowBlue">
                {ordinal(userRanking.rank)}
              </div>
              <div className="text-sm font-bold text-royalBlueDark">
                {userRanking.username}
              </div>
            </div>
            <XPIndicator
              current={userRanking.xp.toFixed(0)}
              textClassName="text-royalBlueDark"
              iconClassName="text-beauBlue fill-beauBlue stroke-beauBlue"
            />
          </div>
        </div>
      )}
    </>
  );
};
