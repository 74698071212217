import React from 'react';
import clsx from 'clsx';
import { GiftIcon, HomeIcon, LockIcon, MapIcon, MedalIcon } from 'lucide-react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../redux/store';

import { ReactComponent as Logo } from '../../../assets/logos/logo.svg';

const TabItem: React.FC<{
  active?: boolean;
  disabled?: boolean;
  to: string;
  label: string;
  icon: React.FC<any>;
}> = ({ active = false, disabled = false, to, label, icon: Icon }) => {
  return (
    <NavLink
      to={disabled ? '#' : to}
      className={clsx([
        'flex h-full flex-1 flex-col items-center justify-center',
        !disabled && 'hover:bg-beauBlue10',
      ])}
    >
      {disabled ? (
        <LockIcon
          size={24}
          className={clsx({
            'mb-1 text-spanishGray': 1,
            'text-spanishGray': 1,
          })}
        />
      ) : (
        <Icon
          size={24}
          className={clsx({
            'mb-1 text-beauBlue': 1,
            'text-white': active,
          })}
        />
      )}
      <span
        className={clsx({
          'text-[10px] text-beauBlue transition-all': 1,
          'text-white': active,
          'text-spanishGray': disabled,
        })}
      >
        {label}
      </span>
    </NavLink>
  );
};

export const AppFooter: React.FC = () => {
  const location = useLocation();
  const { m2eLocked, isLoggedIn, isVerified } = useSelector(
    (state: IRootState) => state.auth,
  );

  return (
    <div className="absolute bottom-0 left-0 right-0 z-40 flex flex-row justify-center">
      <div className="relative w-full max-w-[600px] px-4">
        <div className="relative mb-4 flex h-[60px] flex-row items-center justify-around overflow-hidden rounded-[24px] bg-[#0c0c0ddd] px-[0px]">
          <TabItem
            active={location.pathname === '/home'}
            to="/home"
            label="Home"
            icon={HomeIcon}
          />
          <TabItem
            active={location.pathname === '/mappings'}
            disabled={m2eLocked || !isLoggedIn || !isVerified}
            to="/mappings"
            label="Mapping"
            icon={MapIcon}
          />
          <div className="flex h-full flex-1 flex-col items-center justify-center hover:bg-beauBlue10">
            <span className="relative flex h-[24px] w-[24px] flex-row justify-center">
              <div className="relative"></div>
            </span>
            <span
              className={clsx({
                'mt-1 text-[10px] text-beauBlue transition-all': 1,
                'text-white': location.pathname === '/challenges',
              })}
            >
              Challenges
            </span>
          </div>
          <TabItem
            active={location.pathname === '/leaderboard'}
            disabled={!isLoggedIn || !isVerified}
            to="/leaderboard"
            label="Leader"
            icon={MedalIcon}
          />
          <TabItem
            active={location.pathname === '/wallet'}
            disabled={!isLoggedIn || !isVerified}
            to="/redeem"
            label="Redeem"
            icon={GiftIcon}
          />
          <div
            className={clsx([
              'absolute bottom-0 left-0 -translate-x-1/2 transition-all ease-in-out',
              'h-1 w-5 rounded-t-full bg-icterine',
              location.pathname === '/home' && 'left-[10%]',
              location.pathname === '/mappings' && 'left-[30%]',
              location.pathname === '/challenges' && 'left-[50%]',
              location.pathname === '/leaderboard' && 'left-[70%]',
              location.pathname === '/wallet' && 'left-[90%]',
            ])}
          />
        </div>
        <NavLink
          to="/challenges"
          className={clsx([
            'absolute h-[60px] w-[60px] rounded-full bg-icterine',
            'left-1/2 top-[-30px] -translate-x-1/2',
            'flex flex-col items-center justify-center',
            'transition-all hover:scale-105',
            location.pathname === '/challenges' &&
              'shadow-[0px_0px_12px_0px_rgba(235,241,85,0.70)]',
          ])}
        >
          <Logo className="text-raisinBlack" />
        </NavLink>
      </div>
    </div>
  );
};
