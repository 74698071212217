import React, { useEffect, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Outlet, useLocation } from 'react-router-dom';
import { MappingDetailsHeader } from './mapping-layout/MappingDetailsHeader';
import { MappingDetailsFooter } from './mapping-layout/MappingDetailsFooter';

export const MappingDetailsLayout: React.FC = () => {
  const location = useLocation();

  const scrollbar = useRef<Scrollbars>(null);

  useEffect(() => {
    scrollbar.current?.scrollToTop();
  }, [location]);

  return (
    <div className="relative h-[100vh] w-full max-w-[600px] overflow-hidden bg-raisinBlack">
      <MappingDetailsHeader />
      <div className="h-full w-full pb-[104px]">
        <Scrollbars ref={scrollbar}>
          <div className="flex flex-row justify-center">
            <div className="w-full max-w-[600px]">
              <Outlet />
            </div>
          </div>
        </Scrollbars>
      </div>
      <MappingDetailsFooter />
    </div>
  );
};
