import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const ReferralPage: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  useEffect(() => {
    const code = params.get('code');
    if (code) {
      localStorage.setItem('referral-code', code);
      if (process.env.REACT_APP_ENV !== 'production') {
        alert('[INFO] you are on referral link');
      }
    }
    navigate('/home');
  }, [params]);

  return (
    <>
      <></>
    </>
  );
};
