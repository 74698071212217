import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IAuthState {
  isLoggedIn: boolean;
  isVerified: boolean;
  isProfileCompleted: boolean;
  id: string | null;
  address: string | null;
  chain: string | null;
  avatar: string | null;
  username: string | null;
  email: string | null;
  disabled: boolean | null;
  scammed: boolean | null;
  regions: string[];
  telegramId: string | null;
  accessToken: string | null;
  xp: number | null;
  totalXp: number | null;
  reward: number | null;
  country: string | null;
  city: string | null;
  gender: string | null;
  age: string | null;
  referralCode: string | null;
  m2eLocked: boolean | null;
  ucoProductionPeriod: string | null;
  ucoOilToCook: boolean | null;
  ucoRecycling: boolean | null;
  ucoRecyclingAmount: string | null;
  zealyId: string | null;
  ratio: number | null;
}

const initialState: IAuthState = {
  isLoggedIn: false,
  isVerified: false,
  isProfileCompleted: false,
  id: null,
  address: null,
  chain: null,
  avatar: null,
  username: null,
  email: null,
  disabled: null,
  scammed: null,
  regions: [],
  telegramId: null,
  accessToken: null,
  xp: null,
  totalXp: null,
  reward: null,
  country: null,
  city: null,
  gender: null,
  age: null,
  referralCode: null,
  m2eLocked: null,
  ucoProductionPeriod: null,
  ucoOilToCook: null,
  ucoRecycling: null,
  ucoRecyclingAmount: null,
  zealyId: null,
  ratio: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState as IAuthState,
  reducers: {
    login(state, action: PayloadAction<Partial<IAuthState>>) {
      state.isLoggedIn = true;
      state.isVerified = false;

      if (action.payload.address) {
        state.address = action.payload.address;
      }
      if (action.payload.accessToken) {
        state.accessToken = action.payload.accessToken;
      }
      if (action.payload.chain) {
        state.chain = action.payload.chain;
      }
    },
    authorize(state, action: PayloadAction<Partial<IAuthState>>) {
      state.isLoggedIn = true;
      state.isVerified = true;

      if (action.payload.address) {
        state.address = action.payload.address;
      }
      if (action.payload.chain) {
        state.chain = action.payload.chain;
      }
      if (action.payload.accessToken) {
        state.accessToken = action.payload.accessToken;
      }
      if (action.payload.id) {
        state.id = action.payload.id;
      }
      if (action.payload.avatar) {
        state.avatar = action.payload.avatar;
      }
      if (action.payload.username) {
        state.username = action.payload.username;
      }
      if (action.payload.email) {
        state.email = action.payload.email;
      }
      if (action.payload.telegramId) {
        state.telegramId = action.payload.telegramId;
      }
      if (action.payload.regions) {
        state.regions = action.payload.regions;
      }
      if (action.payload.xp !== undefined && action.payload.reward !== null) {
        state.xp = action.payload.xp;
      }
      if (
        action.payload.totalXp !== undefined &&
        action.payload.reward !== null
      ) {
        state.totalXp = action.payload.totalXp;
      }
      if (
        action.payload.reward !== undefined &&
        action.payload.reward !== null
      ) {
        state.reward = action.payload.reward;
      }
      if (action.payload.country) {
        state.country = action.payload.country;
      }
      if (action.payload.city) {
        state.city = action.payload.city;
      }
      if (action.payload.gender) {
        state.gender = action.payload.gender;
      }
      if (action.payload.age) {
        state.age = action.payload.age;
      }
      if (action.payload.referralCode) {
        state.referralCode = action.payload.referralCode;
      }
      if (
        action.payload.m2eLocked !== undefined &&
        action.payload.m2eLocked !== null
      ) {
        state.m2eLocked = action.payload.m2eLocked;
      }
      if (action.payload.ucoProductionPeriod) {
        state.ucoProductionPeriod = action.payload.ucoProductionPeriod;
      }
      if (action.payload.ucoRecyclingAmount) {
        state.ucoRecyclingAmount = action.payload.ucoRecyclingAmount;
      }
      if (typeof action.payload.ucoOilToCook === 'boolean') {
        state.ucoOilToCook = action.payload.ucoOilToCook;
      }
      if (typeof action.payload.ucoRecycling === 'boolean') {
        state.ucoRecycling = action.payload.ucoRecycling;
      }
      if (action.payload.zealyId) {
        state.zealyId = action.payload.zealyId;
      }
      if (action.payload.ratio) {
        state.ratio = action.payload.ratio;
      }
      if (action.payload.isProfileCompleted !== undefined) {
        state.isProfileCompleted = action.payload.isProfileCompleted;
      }
      if (typeof action.payload.scammed === 'boolean') {
        state.scammed = action.payload.scammed;
      }
      if (typeof action.payload.disabled === 'boolean') {
        state.disabled = action.payload.disabled;
      }
    },
    logout() {
      return initialState;
    },
  },
});

export const { login, authorize, logout } = authSlice.actions;
