import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import Web3 from 'web3';
import { DAppKit, WalletConnectOptions } from '@vechain/dapp-kit';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../redux/store';
import { clearWallet, setWallet } from '../redux/wallet.slice';
import { toast } from 'react-toastify';

const VechainId = 100009;
const XDCId = process.env.REACT_APP_ENV === 'production' ? 50 : 51;

declare global {
  interface Window {
    ethereum: any;
  }
}

export const WalletContext = React.createContext({
  connectWallet: (arg: 'vechain' | 'xdc') => {
    console.log(arg);
  },
  disconnectWallet: () => {
    // TODO
  },
});

export const useWallet = () => {
  return React.useContext(WalletContext);
};

const getVechainSdk = () => {
  const walletConnectOptions: WalletConnectOptions = {
    projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || '',
    metadata: {
      name: process.env.REACT_APP_WALLETCONNECT_NAME || '',
      description: process.env.REACT_APP_WALLETCONNECT_DESCRIPTION || '',
      url: window.location.origin,
      icons: [`${window.location.origin}/logo512.png`],
    },
  };
  return new DAppKit({
    nodeUrl:
      process.env.REACT_APP_WALLETCONNECT_NODE_URL ||
      'https://testnet.vechain.org/',
    genesis: process.env.REACT_APP_WALLETCONNECT_GENESIS,
    walletConnectOptions: walletConnectOptions,
    usePersistence: true,
    useFirstDetectedSource: false,
  });
};

const getMetamaskSdk = () => {
  return new Web3(window.ethereum);
};

export const WalletProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();

  const { account, chainName } = useSelector(
    (state: IRootState) => state.wallet,
  );

  const connectWallet = useCallback(async (target: 'vechain' | 'xdc') => {
    if (target === 'vechain') {
      try {
        const sdk = getVechainSdk();
        sdk.wallet.setSource('veworld');
        const result = await sdk.wallet.connect();
        if (result.account) {
          dispatch(
            setWallet({
              account: result.account,
              chainId: VechainId,
              chainName: 'vechain',
            }),
          );
          return;
        }
        throw new Error();
      } catch (e) {
        toast('Failed to Vechain Wallet, Please try again later');
      }
    }
    if (target === 'xdc') {
      if (window.ethereum) {
        try {
          const sdk = getMetamaskSdk();
          const chainId = await sdk.eth.getChainId();
          if (chainId.toString(10) !== XDCId.toString(10)) {
            toast('Please connect to XDC Network');
            return;
          }
          const accounts = await sdk.eth.requestAccounts();
          if (accounts.length > 0) {
            dispatch(
              setWallet({
                account: accounts[0],
                chainName: 'xdc',
                chainId: XDCId,
              }),
            );
          } else {
            toast('No account found in Metamask');
          }
        } catch {
          toast('Failed to connect to XDC Network, Please try again later');
        }
      } else {
        toast('Please install Metamask to connect to XDC Network');
      }
    }
  }, []);

  const disconnectWallet = useCallback(() => {
    if (chainName === 'vechain') {
      const sdk = getVechainSdk();
      sdk.wallet.disconnect();
    }
    if (chainName === 'xdc') {
      toast('Please disconnect your account from MetaMask app');
    }

    dispatch(clearWallet());
  }, [account, chainName]);

  useEffect(() => {
    if (chainName === 'vechain') {
      const { wallet } = getVechainSdk();
      if (wallet.state.address) {
        dispatch(
          setWallet({
            account: wallet.state.address,
            chainName: 'vechain',
            chainId: VechainId,
          }),
        );
      } else {
        dispatch(clearWallet());
      }
      return;
    }
    if (chainName === 'xdc') {
      if (window.ethereum) {
        const web3 = getMetamaskSdk();
        web3.eth.getChainId().then((chainId) => {
          if (chainId.toString(10) === XDCId.toString(10)) {
            web3.eth.getAccounts().then((accounts) => {
              if (accounts.length > 0) {
                dispatch(
                  setWallet({
                    account: accounts[0],
                    chainName: 'xdc',
                    chainId: XDCId,
                  }),
                );
              } else {
                dispatch(clearWallet());
              }
            });
          } else {
            dispatch(clearWallet());
          }
        });
        web3.eth.getAccounts().then((accounts) => {
          console.log(accounts);
          if (accounts.length > 0) {
            dispatch(
              setWallet({
                account: accounts[0],
                chainName: 'xdc',
                chainId: XDCId,
              }),
            );
          } else {
            dispatch(clearWallet());
          }
        });
      } else {
        dispatch(clearWallet());
      }
      return;
    }

    dispatch(clearWallet());
  }, [account, chainName]);

  return (
    <WalletContext.Provider
      value={{
        connectWallet: connectWallet,
        disconnectWallet: disconnectWallet,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
