import React from 'react';
import clsx from 'clsx';
import { createRoot } from 'react-dom/client';
import { OutlinedButton } from '../buttons/OutlinedButton';

interface IAddRestaurantInfoDialogProps {
  id?: string | number;
}

export class AddRestaurantInfoDialog extends React.Component<
  IAddRestaurantInfoDialogProps,
  { loaded: boolean }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      loaded: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('popstate', this.handleBackButton);

    setTimeout(() => {
      this.setState({
        loaded: true,
      });
    }, 10);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('popstate', this.handleBackButton);
  }

  handleClose() {
    this.setState({
      loaded: false,
    });
    setTimeout(() => {
      const root = document.getElementById('root');
      if (root) {
        const modal = document.getElementById('modal' + '-' + this.props.id);
        if (modal) {
          root.removeChild(modal);
        }
      }
    }, 300);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.handleClose();
    }
  };

  handleBackButton = () => {
    this.handleClose();
  };

  handleNo = () => {
    this.handleClose();
  };

  render(): React.ReactNode {
    return (
      <div
        className={clsx({
          'fixed bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-end bg-raisinBlack px-2 pb-2 transition-all duration-300': 1,
          'bg-opacity-0': !this.state.loaded,
          'bg-opacity-60': this.state.loaded,
        })}
        onClick={this.handleClose}
      >
        <div
          className={clsx({
            'flex min-h-80 w-full max-w-[600px] flex-col rounded-3xl bg-isabelline px-8 py-8': 1,
            'transition-all duration-300': 1,
            'translate-y-full opacity-50': !this.state.loaded,
            'translate-y-0 opacity-100': this.state.loaded,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <div>
            <h1 className="text-[32px] font-semibold leading-normal text-raisinBlack">
              Adding the restaurant to the map
            </h1>
            <div className="mt-6 flex flex-row gap-2 leading-relaxed text-raisinBlack">
              <div>1.</div>
              <p className="flex-1">
                You can just navigate on the map and find the place near you
                where the restaurant will be added.
              </p>
            </div>
            <div className="mt-6 flex flex-row gap-2 leading-relaxed text-raisinBlack">
              <div>2.</div>
              <p className="flex-1">
                Next, tap on the location and hold down for 2 seconds until the
                pin is attached to the place.
              </p>
            </div>
            <div className="mt-6 flex flex-row gap-2 leading-relaxed text-raisinBlack">
              <div>3.</div>
              <p className="flex-1">
                In the pop-up that opens, fill in the name and address fields.
              </p>
            </div>
          </div>
          <div className="mt-16 flex flex-row items-center justify-start">
            <OutlinedButton size="large" variant="dark" onClick={this.handleNo}>
              Ok, I understand
            </OutlinedButton>
          </div>
        </div>
      </div>
    );
  }

  public static open() {
    const root = document.getElementById('root');
    if (root) {
      const key = Date.now();
      const div = document.createElement('div');
      div.id = 'modal' + '-' + key;
      root.appendChild(div);
      createRoot(div).render(<AddRestaurantInfoDialog id={key} />);
    }
  }
}
