import React, { useEffect } from 'react';
import { Portal } from 'react-portal';
import { ImageIcon } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { IMappingPlaceItem } from '../../apis/types';
import { apiGetPlaceDetails } from '../../apis/mapping';

export const MappingPlaceClaimedView: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state as IMappingPlaceItem;

  const { data: details, isLoading } = useQuery({
    queryKey: ['mapping', 'places', data?._id],
    queryFn: async () => {
      if (data?._id) {
        return await apiGetPlaceDetails(data._id);
      }
      return null;
    },
  });

  useEffect(() => {
    if (!data) {
      navigate('/mappings');
    }
  }, []);

  return (
    <>
      <div className="h-16" />
      <div className="relative flex flex-row justify-center">
        <div className="relative flex h-[160px] w-[160px] rotate-6 items-center justify-center overflow-hidden rounded-3xl bg-isabelline">
          {data?.photo_url || details?.photo ? (
            <img
              src={details?.photo ? details.photo : data.photo_url}
              className="h-full w-full -rotate-6 scale-110 object-cover"
            />
          ) : (
            <ImageIcon className="-rotate-6 text-spanishGray" />
          )}
        </div>
      </div>
      <div className="mt-16">
        <h1 className="text-center text-2xl text-isabelline">{data?.name}</h1>
        <div className="mt-4 flex flex-row items-center justify-center gap-8">
          <span className="text-sm text-shadowBlue">Restaurant</span>
        </div>
      </div>
      <div className="mt-16">
        <h1 className="text-center text-xl text-isabelline">Address</h1>
        <div className="mt-4 flex flex-row justify-center">
          <p className="w-full max-w-[200px] text-center text-sm leading-loose text-isabelline">
            {data?.address}
          </p>
        </div>
      </div>
      <div className="mt-4">
        <div className="mt-4 flex flex-row items-center justify-center gap-8">
          <a className="cursor-pointer text-sm text-shadowBlue underline hover:text-beauBlue active:text-spanishGray">
            Get directions
          </a>
        </div>
      </div>
      <Portal
        node={document && document.getElementById('mapping-details-footer')}
      >
        {isLoading ? (
          <div className="flex flex-1 flex-row justify-center pb-8">
            <div className="h-4 w-48 animate-pulse rounded-full bg-shadowBlue bg-opacity-50" />
          </div>
        ) : (
          <div className="flex-1 pb-8 text-center text-sm text-shadowBlue">
            Mapped by{' '}
            <span className="underline">
              @{details?.claimedBy ?? 'unknown'}
            </span>
          </div>
        )}
      </Portal>
    </>
  );
};
