import axios from 'axios';
import { ICreatedPlaceItem, ICreatedWmoItem, IMappingPlaceItem } from './types';
import { apiGetProfile } from './auth';
import { toast } from 'react-toastify';

export const apiGetPlaces = async (params: {
  latitude: number;
  longitude: number;
  radius: number;
}): Promise<IMappingPlaceItem[]> => {
  try {
    const response = await axios.post('/api/client/v1/m2e/places', {
      latitude: params.latitude,
      longitude: params.longitude,
      radius: params.radius,
    });

    const data: IMappingPlaceItem[] = response.data.data.map(
      (item: Partial<IMappingPlaceItem>) => ({
        ...item,
        total_xp: response.data.total_xp - response.data.initial_xp,
        initial_xp: response.data.initial_xp,
      }),
    );
    return data;
  } catch {
    return [];
  }
};

export const apiGetMyPlaces = async (): Promise<ICreatedPlaceItem[]> => {
  try {
    const response = await axios.get('/api/client/v1/m2e/place');
    return response.data.data;
  } catch {
    return [];
  }
};

export const apiGetMyWMOs = async (): Promise<ICreatedWmoItem[]> => {
  try {
    const response = await axios.get('/api/client/v1/m2e/wmo');
    return response.data.data;
  } catch {
    return [];
  }
};

export const apiGetPlaceDetails = async (id: string) => {
  try {
    const response = await axios.get(`/api/client/v1/m2e/place/${id}`);
    return {
      id: id,
      claimed: !!response.data?.data?.claimed_by,
      claimedBy: response.data?.data?.claimed_by,
      photo: response.data?.data?.photo_url,
    };
  } catch {
    return {
      id: id,
      claimed: false,
    };
  }
};

export const apiClaimPlace = async (params: {
  place_id: string;
  contact_name: string;
  contact_email: string;
  contact_phone_number: string;
  charge_name: string;
  charge_email: string;
  photo_url?: string;
  waste_info: string;
  is_collected_by_wmo?: boolean;
  is_company?: boolean;
  is_compensating?: boolean;
  collector_name?: string;
  collector_email?: string;
  collector_phone_number?: string;
  spoken_language: string;
}) => {
  try {
    await axios.post('/api/client/v1/m2e/claim', params);
    await apiGetProfile({
      need_balance: true,
      need_ratio: true,
    });
    return true;
  } catch {
    return false;
  }
};

export const apiAddWmo = async (params: {
  type: string;
  collector_name: string;
  collector_email: string;
  collector_phone_number: string;
  count_vehicles: number;
}) => {
  try {
    await axios.post('/api/client/v1/m2e/wmo', params);
    toast.success('You have created WMO successfully', {
      position: 'top-center',
      theme: 'colored',
    });
    return true;
  } catch (e: any) {
    if (e?.response?.data?.error === 'DAILY_LIMIT_REACHED') {
      return 'DAILY_LIMIT_REACHED';
    } else {
      toast('Please check your data and try again');
      return false;
    }
  }
};

export const apiAddRestaurant = async (params: {
  name: string;
  address: string;
  latitude: number;
  longitude: number;
}) => {
  try {
    await axios.post('/api/client/v1/m2e/place', {
      ...params,
      type: 'restaurant',
    });
    toast.success('You have created restaurant successfully', {
      position: 'top-center',
      theme: 'colored',
    });
    return true;
  } catch (e: any) {
    if (e?.response?.data?.error === 'DAILY_LIMIT_REACHED') {
      return 'DAILY_LIMIT_REACHED';
    } else {
      toast('Please check your data and try again');
      return false;
    }
  }
};
