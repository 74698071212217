import React from 'react';
import clsx from 'clsx';
import { ZapIcon } from 'lucide-react';
import { colors } from '../../config/theme';

interface IXPIndicatorProps {
  current?: number | string;
  total?: number | string;
  textClassName?: string;
  iconClassName?: string;
  containerClassName?: string;
}

export const XPIndicator: React.FC<IXPIndicatorProps> = ({
  current,
  total,
  textClassName,
  iconClassName,
  containerClassName,
}) => {
  return (
    <div
      className={clsx([
        'flex flex-row items-center justify-center',
        containerClassName,
      ])}
    >
      <ZapIcon
        size={16}
        color={colors.icterine}
        fill={colors.icterine}
        strokeWidth={0}
        className={clsx(['mr-1', iconClassName ? iconClassName : ''])}
      />
      <span
        className={clsx([
          'text-md font-semibold leading-none',
          textClassName ? textClassName : 'text-isabelline',
        ])}
      >
        {current}
        {current !== undefined && total !== undefined ? '/' : ''}
        {total} XP
      </span>
    </div>
  );
};
