import React from 'react';
import { LinearProgressIndicator } from '../indicators/LinearProgressIndicator';
import { XPIndicator } from '../core/XPIndicator';
import { IconButton } from '../buttons/IconButton';
import { XIcon } from 'lucide-react';

interface ILessonHeaderProps {
  currentPage: number;
  totalPages: number;
  totalXp: number;
  currentXp: number;
  showOnlyCloseButton?: boolean;
  onClose?: () => void;
}

export const LessonHeader: React.FC<ILessonHeaderProps> = ({
  currentPage,
  totalPages,
  totalXp,
  currentXp,
  showOnlyCloseButton,
  onClose,
}) => {
  return (
    <div className="absolute left-0 top-0 z-[100] flex h-[90px] w-full flex-row items-center px-8">
      {!showOnlyCloseButton && (
        <span className="text-sm text-isabelline">
          {currentPage > totalPages ? totalPages : currentPage} of {totalPages}
        </span>
      )}
      {!showOnlyCloseButton && (
        <LinearProgressIndicator
          value={Math.ceil((currentPage / totalPages) * 100)}
          className="mx-4 max-w-[140px] flex-1"
        />
      )}
      {!showOnlyCloseButton && (
        <XPIndicator
          current={currentXp.toFixed(0)}
          total={totalXp.toFixed(0)}
          containerClassName="ml-4"
        />
      )}
      <IconButton
        icon={XIcon}
        size="medium"
        className="ml-auto"
        onClick={onClose}
      />
    </div>
  );
};
