import React, { useCallback } from 'react';
import clsx from 'clsx';
import { ArrowLeftIcon, XIcon } from 'lucide-react';
import { IconButton } from '../../buttons/IconButton';
import { XPIndicator } from '../../core/XPIndicator';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../redux/store';

export const MappingDetailsHeader: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { xp } = useSelector((state: IRootState) => state.auth);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  if (
    location.pathname.includes('/details/general') ||
    location.pathname.includes('/details/info')
  ) {
    return (
      <div
        className={clsx([
          'absolute left-0 right-0 top-0 z-20 flex flex-row justify-center px-4 py-6',
          'bg-raisinBlack',
        ])}
      >
        <div className="flex w-full max-w-[600px] flex-row items-center justify-between">
          <IconButton
            variant="dark"
            size="small"
            icon={ArrowLeftIcon}
            onClick={handleBack}
          />
          <span className="text-sm text-isabelline">
            {location.pathname.includes('/details/general')
              ? 'Restaurant Details'
              : ''}
            {location.pathname.includes('/details/info') ? 'UCO Details' : ''}
          </span>
          <IconButton
            variant="dark"
            size="small"
            icon={XIcon}
            onClick={handleBack}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx([
        'absolute left-0 right-0 top-0 z-20 flex flex-row justify-center px-4 pt-4',
      ])}
    >
      <div className="flex w-full max-w-[600px] flex-row items-center justify-between">
        <IconButton
          variant="dark"
          size="small"
          icon={ArrowLeftIcon}
          onClick={handleBack}
        />
        <XPIndicator current={xp ? xp.toFixed(0) : 0} />
      </div>
    </div>
  );
};
