import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IRootState } from '../../redux/store';

export const ProtectedRoute: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { isLoggedIn, isVerified } = useSelector(
    (state: IRootState) => state.auth,
  );

  if (!isLoggedIn || !isVerified) {
    return <Navigate to="/home" replace />;
  }

  return <>{children}</>;
};
