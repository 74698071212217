import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import Scrollbars from 'react-custom-scrollbars-2';
import { IconButton } from '../buttons/IconButton';
import { ArrowLeftIcon, XIcon } from 'lucide-react';

const SecondaryLayout = ({ children }: PropsWithChildren) => {
  return (
    <div className="relative h-[100vh] w-full max-w-[600px] overflow-hidden bg-raisinBlack">
      {children}
    </div>
  );
};

SecondaryLayout.Content = function Content({ children }: PropsWithChildren) {
  return (
    <div className="h-full w-full">
      <Scrollbars>
        <div className="flex flex-row justify-center">
          <div className="w-full max-w-[600px]">{children}</div>
        </div>
      </Scrollbars>
    </div>
  );
};

interface ISecondaryLayoutHeaderProps {
  title?: string;
  startIcon?: React.FC<any>;
  endIcon?: React.FC<any>;
  hideStartIcon?: boolean;
  hideEndIcon?: boolean;
  onStartIconClick?: () => void;
  onEndIconClick?: () => void;
}

SecondaryLayout.Header = function Header({
  title,
  startIcon: StartIcon,
  endIcon: EndIcon,
  hideStartIcon,
  hideEndIcon,
  onStartIconClick,
  onEndIconClick,
}: ISecondaryLayoutHeaderProps) {
  return (
    <div
      className={clsx([
        'absolute left-0 right-0 top-0 z-20 flex flex-row justify-center bg-raisinBlack px-4 py-6',
      ])}
    >
      <div className="flex w-full max-w-[600px] flex-row items-center justify-between">
        {hideStartIcon ? (
          <div className="h-[28px] w-[28px]" />
        ) : (
          <IconButton
            variant="dark"
            size="small"
            icon={StartIcon ? StartIcon : ArrowLeftIcon}
            onClick={onStartIconClick}
          />
        )}
        <span className="text-sm text-white">{title}</span>
        {hideEndIcon ? (
          <div className="h-[28px] w-[28px]" />
        ) : (
          <IconButton
            variant="dark"
            size="small"
            icon={EndIcon ? EndIcon : XIcon}
            onClick={onEndIconClick}
          />
        )}
      </div>
    </div>
  );
};

export { SecondaryLayout };
