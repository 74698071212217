import React, { useCallback } from 'react';
import clsx from 'clsx';
import { SecondaryLayout } from '../../components';
import { Img } from 'react-image';
import { XIcon } from 'lucide-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { apiGetArticles } from '../../apis/articles';

export const ArticlesPage: React.FC = () => {
  const navigate = useNavigate();

  const { data: articles, isLoading: isLoadingArticles } = useQuery({
    queryKey: ['articles'],
    queryFn: async () => {
      return await apiGetArticles({
        limit: 100,
        skip: 0,
      });
    },
  });

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <SecondaryLayout>
      <SecondaryLayout.Header
        title="Latest Articles"
        startIcon={XIcon}
        hideEndIcon
        onStartIconClick={handleBack}
      />
      <SecondaryLayout.Content>
        <div className="mt-24">
          {isLoadingArticles
            ? Array(10)
                .fill(1)
                .map((_, index) => (
                  <div
                    key={`loading-article-${index}`}
                    className={clsx(['flex flex-row items-center px-8 py-4'])}
                  >
                    <div className="flex-1">
                      <div className="h-4 w-full animate-pulse rounded-full bg-isabelline bg-opacity-30" />
                      <div className="mt-1 h-4 w-2/3 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
                      <div className="mt-2 h-3 w-full animate-pulse rounded-full bg-isabelline bg-opacity-30" />
                      <div className="mt-1 h-3 w-full animate-pulse rounded-full bg-isabelline bg-opacity-30" />
                      <div className="mt-1 h-3 w-1/3 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
                    </div>
                    <div className="ml-4 h-[80px] w-[80px] animate-pulse rounded-lg bg-isabelline bg-opacity-30" />
                  </div>
                ))
            : articles?.map((article, index) => (
                <NavLink
                  key={`article-${index}`}
                  to={article.url}
                  target="_blank"
                >
                  <div
                    className={clsx([
                      'flex flex-row items-center px-8 py-4',
                      'hover:bg-isabelline hover:bg-opacity-10',
                      'active:bg-isabelline active:bg-opacity-5',
                    ])}
                  >
                    <div className="flex-1">
                      <div className="line-clamp-2 overflow-hidden text-ellipsis text-isabelline">
                        {article.title}
                      </div>
                      <div className="mt-2 line-clamp-2 flex-1 overflow-hidden text-ellipsis text-sm text-spanishGray">
                        {article.description}...
                      </div>
                    </div>
                    {article.image_url ? (
                      <Img
                        src={[article.image_url, '/logo512.png']}
                        className="ml-4 aspect-square h-[80px] w-[80px] rounded-lg object-cover"
                      />
                    ) : null}
                  </div>
                </NavLink>
              ))}
        </div>
      </SecondaryLayout.Content>
    </SecondaryLayout>
  );
};
