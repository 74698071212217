import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ILessonsState } from '../apis/types';

const initialState: ILessonsState = {
  savedPages: {},
};

export const lessonsSlice = createSlice({
  name: 'lessons',
  initialState,
  reducers: {
    saveLessonProgress: (
      state,
      action: PayloadAction<{ lessonId: string; page: number }>,
    ) => {
      state.savedPages[action.payload.lessonId] = action.payload.page;
    },
  },
});

export const { saveLessonProgress } = lessonsSlice.actions;
