import axios from 'axios';
import { IRankingItem } from './types';

export const apiGetLeaderboardData = async (params: {
  year: number;
  month: number;
  skip: number;
  limit: number;
}) => {
  try {
    const response = await axios.post<{
      message: string;
      data: {
        first3: IRankingItem[];
        data: IRankingItem[];
        me: IRankingItem;
      };
    }>('/api/client/v1/user/leaderboard', params);
    return response.data.data;
  } catch {
    return {
      data: [],
      first3: [],
      me: null,
    };
  }
};
