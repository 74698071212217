import axios from 'axios';
import { store } from '../redux/store';

(() => {
  axios.defaults.baseURL = process.env.REACT_APP_API_SERVER;

  axios.interceptors.request.use((config) => {
    const accessToken = store.getState().auth?.accessToken;
    if (accessToken) {
      config.headers.set('x-access-token', accessToken);
    }
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    },
  );
})();
