import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { Outlet } from 'react-router-dom';
import { IOptions, RecursivePartial } from '@tsparticles/engine';
import { loadBasic } from '@tsparticles/basic';

import { AppPrimaryHeader } from './app-layout/AppPrimaryHeader';
import { AppContent } from './app-layout/AppContent';
import { AppFooter } from './app-layout/AppFooter';
import { colors } from '../../config/theme';

export function AppLayout(): JSX.Element {
  const [initialized, setInitialized] = useState(false);

  const options = useMemo<RecursivePartial<IOptions>>(
    () => ({
      autoPlay: true,
      background: {
        color: colors.royalBlueDark,
        opacity: 1,
      },
      clear: true,
      fullScreen: {
        enable: false,
        zIndex: 0,
      },
      fpsLimit: 120,
      interactivity: {
        detectsOn: 'window',
        events: {
          onClick: {
            enable: true,
            mode: 'push',
          },
          onDiv: {
            selectors: [],
            enable: false,
            mode: [],
            type: 'circle',
          },
          onHover: {
            enable: false,
            mode: 'repulse',
            parallax: {
              enable: false,
              force: 60,
              smooth: 10,
            },
          },
          resize: {
            delay: 0.5,
            enable: true,
          },
        },
      },
      particles: {
        color: {
          value: '#ffffff',
        },
        collisions: {
          mode: 'bounce',
          bounce: {
            horizontal: {
              value: 1,
            },
            vertical: {
              value: 1,
            },
          },
          enable: true,
          maxSpeed: {
            min: 10,
            max: 20,
          },
        },
        move: {
          direction: 'none',
          enable: true,
          outModes: {
            default: 'bounce',
          },
          random: false,
          speed: 1,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 80,
        },
        opacity: {
          value: 0.1,
        },
        shape: {
          type: 'circle',
        },
        size: {
          value: { min: 10, max: 20 },
        },
      },
      detectRetina: true,
    }),
    [],
  );

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadBasic(engine);
    }).then(() => {
      setInitialized(true);
    });
  }, []);

  const onParticlesLoaded = useCallback(async () => {
    console.log('Loaded Particles Background...');
  }, []);

  return (
    <div className="relative h-[100vh] w-full max-w-[600px] overflow-hidden bg-royalBlueDark">
      <AppPrimaryHeader />
      {initialized && (
        <div className="absolute left-0 top-0 z-0 h-[100vh] w-full">
          <Particles
            id="tsparticles"
            particlesLoaded={onParticlesLoaded}
            options={options}
          />
        </div>
      )}
      <AppContent>
        <Outlet />
      </AppContent>
      <AppFooter />
    </div>
  );
}
