export const colors = {
  transparent: 'transparent',
  black: '#000000',
  white: '#FFFFFF',
  royalBlueDark: '#00195A',
  isabelline: '#EEECE7',
  icterine: '#EBF155',
  beauBlue: '#B4C7D6',
  shadowBlue: '#7784A1',
  raisinBlack: '#23222D',
  purpleNavy: '#475985',
};
