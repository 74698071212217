import React from 'react';
import clsx from 'clsx';
import { createRoot } from 'react-dom/client';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  RedditShareButton,
  RedditIcon,
} from 'react-share';
import { OutlinedButton } from '../buttons/OutlinedButton';

interface IShareOptionsDialogProps {
  id?: string | number;
  title: string;
  message: string;
  url: string;
}

export class ShareOptionsDialog extends React.Component<
  IShareOptionsDialogProps,
  { loaded: boolean }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      loaded: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('popstate', this.handleBackButton);

    setTimeout(() => {
      this.setState({
        loaded: true,
      });
    }, 10);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('popstate', this.handleBackButton);
  }

  handleClose() {
    this.setState({
      loaded: false,
    });
    setTimeout(() => {
      const root = document.getElementById('root');
      if (root) {
        const modal = document.getElementById('modal' + '-' + this.props.id);
        if (modal) {
          root.removeChild(modal);
        }
      }
    }, 300);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.handleClose();
    }
  };

  handleBackButton = () => {
    this.handleClose();
  };

  render(): React.ReactNode {
    return (
      <div
        className={clsx({
          'fixed bottom-0 left-0 right-0 top-0 z-[9999] flex flex-col items-center justify-end px-2 pb-2 transition-all duration-300': 1,
          'bg-transparent': 1,
        })}
        onClick={this.handleClose}
      >
        <div
          className={clsx({
            'flex w-full max-w-[600px] flex-col rounded-3xl bg-isabelline px-8 py-8': 1,
            'transition-all duration-300': 1,
            'translate-y-full opacity-50': !this.state.loaded,
            'translate-y-0 opacity-100': this.state.loaded,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex-1">
            <h1 className="mb-8 text-[32px] font-semibold leading-normal text-raisinBlack">
              Choose where to share
            </h1>
            <div className="grid grid-cols-6 gap-4">
              <TelegramShareButton
                url={this.props.url}
                title={this.props.title}
                className="aspect-square overflow-hidden rounded-full transition-all hover:scale-110 active:scale-95"
                onClick={this.handleClose}
              >
                <TelegramIcon className="h-full w-full" />
              </TelegramShareButton>
              <WhatsappShareButton
                url={this.props.url}
                title={this.props.title}
                className="aspect-square overflow-hidden rounded-full transition-all hover:scale-110 active:scale-95"
                onClick={this.handleClose}
              >
                <WhatsappIcon className="h-full w-full" />
              </WhatsappShareButton>
              <TwitterShareButton
                url={this.props.url}
                title={this.props.title}
                className="aspect-square overflow-hidden rounded-full transition-all hover:scale-110 active:scale-95"
                onClick={this.handleClose}
              >
                <TwitterIcon className="h-full w-full" />
              </TwitterShareButton>
              <FacebookShareButton
                url={this.props.url}
                title={this.props.title}
                className="aspect-square overflow-hidden rounded-full transition-all hover:scale-110 active:scale-95"
                onClick={this.handleClose}
              >
                <FacebookIcon className="h-full w-full" />
              </FacebookShareButton>
              <LinkedinShareButton
                url={this.props.url}
                title={this.props.title}
                className="aspect-square overflow-hidden rounded-full transition-all hover:scale-110 active:scale-95"
                onClick={this.handleClose}
              >
                <LinkedinIcon className="h-full w-full" />
              </LinkedinShareButton>
              <RedditShareButton
                url={this.props.url}
                title={this.props.title}
                className="aspect-square overflow-hidden rounded-full transition-all hover:scale-110 active:scale-95"
                onClick={this.handleClose}
              >
                <RedditIcon className="h-full w-full" />
              </RedditShareButton>
            </div>
          </div>
          <div className="mt-8">
            <OutlinedButton
              variant="dark"
              size="large"
              onClick={this.handleClose}
            >
              Cancel
            </OutlinedButton>
          </div>
        </div>
      </div>
    );
  }

  public static open(props: IShareOptionsDialogProps) {
    const root = document.getElementById('root');
    if (root) {
      const key = Date.now();
      const div = document.createElement('div');
      div.id = 'modal' + '-' + key;
      root.appendChild(div);
      createRoot(div).render(<ShareOptionsDialog {...props} id={key} />);
    }
  }
}
