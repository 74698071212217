import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { createRoot } from 'react-dom/client';
import { Controller, useForm } from 'react-hook-form';
import { StandardInput } from '../inputs/StandardInput';
import { FilledButton } from '../buttons/FilledButton';
import { OutlinedButton } from '../buttons/OutlinedButton';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { apiAddRestaurant } from '../../apis/mapping';
import { DailyPlaceLimitDialog } from './DailyPlaceLimitDialog';

interface IAddRestaurantFormDialogProps {
  id?: string | number;
  initialData: {
    latitude: number;
    longitude: number;
  };
  onFinish: () => void;
}

const scheme = yup.object({
  name: yup.string().required('Please fill restaurant name'),
  address: yup.string().required('Please fill restaurant address'),
  longitude: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue === '' ||
        Number.isNaN(Number(originalValue)) ||
        !originalValue
        ? null
        : value;
    })
    .required('Please fill longitude value'),
  latitude: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue === '' ||
        Number.isNaN(Number(originalValue)) ||
        !originalValue
        ? null
        : value;
    })
    .required('Please fill latitude value'),
});

interface IAddRestaurantFormData {
  name: string;
  address: string;
  longitude: number;
  latitude: number;
}

const AddRestaurantFormDialog = ({
  id,
  initialData,
  onFinish,
}: IAddRestaurantFormDialogProps) => {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<IAddRestaurantFormData>({
    defaultValues: {
      name: '',
      address: '',
      latitude: initialData.latitude,
      longitude: initialData.longitude,
    },
    resolver: yupResolver(scheme),
  });

  const handleClose = useCallback(() => {
    setLoaded(false);
    setTimeout(() => {
      const root = document.getElementById('root');
      if (root) {
        const modal = document.getElementById('modal' + '-' + id);
        if (modal) {
          root.removeChild(modal);
        }
      }
    }, 300);
  }, []);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  }, []);

  const handleBackButton = useCallback(() => {
    handleClose();
  }, []);

  const handleNo = useCallback(() => {
    handleClose();
  }, []);

  const handleSave = useCallback(async (data: IAddRestaurantFormData) => {
    setLoading(true);
    const result = await apiAddRestaurant(data);
    setLoading(false);

    if (result) {
      if (result === 'DAILY_LIMIT_REACHED') {
        DailyPlaceLimitDialog.open();
        handleClose();
      } else {
        onFinish();
        handleClose();
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('popstate', handleBackButton);

    setTimeout(() => {
      setLoaded(true);
    }, 10);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <div
      className={clsx({
        'fixed bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-end bg-raisinBlack px-2 pb-2 transition-all duration-300': 1,
        'bg-opacity-0': !loaded,
        'bg-opacity-60': loaded,
      })}
      onClick={handleClose}
    >
      <div
        className={clsx({
          'flex min-h-80 w-full max-w-[600px] flex-col rounded-3xl bg-isabelline px-8 py-8': 1,
          'transition-all duration-300': 1,
          'translate-y-full opacity-50': !loaded,
          'translate-y-0 opacity-100': loaded,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <h1 className="text-[32px] font-semibold leading-normal text-raisinBlack">
            Restaurant Details
          </h1>
          <div>
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState: { error } }) => (
                <div className="mt-8">
                  <div
                    className={clsx([
                      'text-xs',
                      error ? 'text-red' : 'text-raisinBlack',
                    ])}
                  >
                    PLACE NAME
                  </div>
                  <StandardInput
                    variant="beauBlue"
                    placeholder="E.g.: Bounty Cool Burger"
                    error={!!error}
                    helperText={error ? error.message : ''}
                    value={field.value}
                    onChangeText={field.onChange}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="address"
              render={({ field, fieldState: { error } }) => (
                <div className="mt-8">
                  <div
                    className={clsx([
                      'text-xs',
                      error ? 'text-red' : 'text-raisinBlack',
                    ])}
                  >
                    ADDRESS
                  </div>
                  <StandardInput
                    variant="beauBlue"
                    placeholder="E.g.: Vestergade 21"
                    error={!!error}
                    helperText={error ? error.message : ''}
                    value={field.value}
                    onChangeText={field.onChange}
                  />
                </div>
              )}
            />
          </div>
        </div>
        <div className="mt-16 flex flex-row items-center justify-start gap-4">
          <OutlinedButton
            size="large"
            variant="dark"
            className="flex-1"
            onClick={handleNo}
          >
            Close
          </OutlinedButton>
          <FilledButton
            variant="dark"
            size="large"
            loading={loading}
            className="flex-[2]"
            onClick={handleSubmit(handleSave)}
          >
            Save
          </FilledButton>
        </div>
      </div>
    </div>
  );
};

AddRestaurantFormDialog.open = ({
  initialData,
  onFinish,
}: IAddRestaurantFormDialogProps) => {
  const root = document.getElementById('root');
  if (root) {
    const key = Date.now();
    const div = document.createElement('div');
    div.id = 'modal' + '-' + key;
    root.appendChild(div);
    createRoot(div).render(
      <AddRestaurantFormDialog
        id={key}
        initialData={initialData}
        onFinish={onFinish}
      />,
    );
  }
};

export { AddRestaurantFormDialog };
