import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Portal } from 'react-portal';
import {
  AlertDialog,
  FilledButton,
  RequestUCOInfoDialog,
} from '../../components';
import { CheckIcon, CloudUploadIcon, ImageIcon } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGeolocated } from 'react-geolocated';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import moment from 'moment';
import { IRootState } from '../../redux/store';
import { insertPlace, updatePlace } from '../../redux/places.slice';
import { apiUploadFile } from '../../apis/files';
import { apiClaimPlace } from '../../apis/mapping';
import {
  setDisableRequestUCOInfoModal,
  setLastRequestUCOInfoModalShownAt,
} from '../../redux/app.slice';

export const MappingPlaceDetailsPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { coords, isGeolocationEnabled, isGeolocationAvailable } =
    useGeolocated();

  const params = useParams();
  const id = params.id;

  const data = useSelector((state: IRootState) =>
    id ? state.places.places[id] : null,
  );
  const lastShownAt = useSelector(
    (state: IRootState) => state.app.lastRequestUCOInfoModalShownAt,
  );
  const disableRequestUCOInfoModal = useSelector(
    (state: IRootState) => state.app.disableRequestUCOInfoModal,
  );

  const [status, setStatus] = useState({
    general: false,
    info: false,
  });
  const [uploading, setUploading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDropAccepted: async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setUploading(true);
        const urls = await apiUploadFile({
          key: 'places',
          files: [file],
        });
        setUploading(false);

        if (urls) {
          dispatch(
            updatePlace({
              id: data?.id,
              image: urls[0],
            }),
          );
        } else {
          toast('Failed to upload image');
        }
      }
    },
  });

  const handleClose = useCallback(() => {
    AlertDialog.open({
      title: 'The data will be kept for 30 days',
      message: 'Hurry up, before someone claims this place before you.',
      canDismiss: true,
      confirmLabel: 'Okay, I understand',
      onConfirm: () => navigate(-1),
    });
  }, []);

  const handleClickGeneral = useCallback(() => {
    navigate(`/mappings/${id}/details/general`);
  }, []);

  const handleClickInfo = useCallback(() => {
    navigate(`/mappings/${id}/details/info`);
  }, []);

  const handleClickCheck = useCallback(() => {
    if (data?.checked || (!data?.latitude && !data?.longitude)) {
      return;
    }

    if (
      window.location.origin.includes('localhost') ||
      window.location.origin.includes('dev')
    ) {
      dispatch(
        updatePlace({
          id: data.id,
          checked: true,
        }),
      );
      toast('Checked in successfully!');
      return;
    }

    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      const dLat = Math.abs(data.latitude - coords.latitude);
      const dLng = Math.abs(data.longitude - coords.longitude);
      if (dLat < 0.0009 && dLng < 0.0009) {
        dispatch(
          updatePlace({
            id: data.id,
            checked: true,
          }),
        );
        toast('Checked in successfully!');
      } else {
        AlertDialog.open({
          title: 'You are not at the location',
          message: 'Please go to the location to check-in.',
          confirmLabel: 'Okay, I understand',
          canDismiss: true,
        });
      }
    } else {
      AlertDialog.open({
        title: 'Please enable geolocation service',
        confirmLabel: 'Okay, I understand',
        canDismiss: true,
      });
      return;
    }
  }, [data?.checked, isGeolocationAvailable, isGeolocationEnabled, coords]);

  const handleClaim = useCallback(async () => {
    if (status.general && status.info && data?.checked) {
      setSaving(true);
      const result = await apiClaimPlace({
        place_id: data.id,
        contact_name: data.general!.contact_name!,
        contact_email: data.general!.contact_email!,
        contact_phone_number: data.general!.contact_phone_number!,
        charge_name: data.general!.charge_name!,
        charge_email: data.general!.charge_email!,
        photo_url: data.image,
        waste_info: data.info!.waste_info!,
        is_collected_by_wmo: data.info!.is_collected_by_wmo!,
        is_company: data.info!.is_company!,
        is_compensating: data.info!.is_compensating!,
        collector_name: data.info!.collector_name,
        collector_email: data.info!.collector_email,
        collector_phone_number: data.info!.collector_phone_number,
        spoken_language: data.general!.spoken_language!,
      });
      setSaving(false);
      if (result) {
        navigate(`/mappings/${data.id}/details/claimed`, {
          replace: true,
        });
      } else {
        AlertDialog.open({
          title: 'Apologies. Someone claimed the place before.',
          message: 'Don’t give up; plenty of places remain to be claimed.',
          confirmLabel: 'Okay, I understand',
          canDismiss: true,
        });
      }
    } else {
      AlertDialog.open({
        title: 'Please fill all the mandatory fields to claim a place',
        canDismiss: true,
        confirmLabel: 'Okay, I understand',
      });
    }
  }, [data, status]);

  useEffect(() => {
    if (
      moment().format('YYYY-MM-DD') !== lastShownAt &&
      !disableRequestUCOInfoModal
    ) {
      RequestUCOInfoDialog.open({
        onDisable: () => {
          dispatch(setDisableRequestUCOInfoModal(true));
        },
      });
      dispatch(
        setLastRequestUCOInfoModalShownAt(moment().format('YYYY-MM-DD')),
      );
    }
  }, [disableRequestUCOInfoModal, lastShownAt]);

  useEffect(() => {
    if (!id) {
      navigate('/mappings');
      return;
    }

    if (data) {
      setStatus((values) => {
        const result = { ...values };
        if (
          data.general?.contact_name &&
          data.general?.contact_email &&
          data.general?.charge_name &&
          data.general?.charge_email &&
          data.general?.spoken_language
        ) {
          result.general = true;
        } else {
          result.general = false;
        }
        if (data.info?.waste_info) {
          result.info = true;
        } else {
          result.info = false;
        }
        return result;
      });
      dispatch(
        updatePlace({
          initial_xp: location.state?.initial_xp ?? 25,
          total_xp: location.state?.total_xp ?? 150,
        }),
      );
    } else {
      if (
        location.state?.name &&
        location.state?.latitude !== undefined &&
        location.state?.longitude !== undefined
      ) {
        dispatch(
          insertPlace({
            id: id,
            externalId: location.state?.id ?? '',
            name: location.state.name,
            type: location.state?.type ?? 'restaurant',
            initial_xp: location.state?.initial_xp ?? 25,
            total_xp: location.state?.total_xp ?? 150,
            latitude: location.state.latitude,
            longitude: location.state.longitude,
            checked: false,
            image: undefined,
          }),
        );
      } else {
        navigate(-1);
      }
    }
  }, [data, id]);

  return (
    <>
      <div className="h-16" />
      <div className="relative flex flex-row justify-center">
        <div className="relative flex h-[160px] w-[160px] rotate-6 items-center justify-center overflow-hidden rounded-3xl bg-isabelline">
          {data?.image ? (
            <img
              src={data?.image}
              className="h-full w-full -rotate-6 scale-110 object-cover"
            />
          ) : (
            <ImageIcon className="-rotate-6 text-spanishGray" />
          )}
        </div>
        <div {...getRootProps()} className="absolute -bottom-5 w-[132px]">
          <input {...getInputProps()} />
          <FilledButton
            variant="beauBlue"
            size="small"
            endIcon={CloudUploadIcon}
            className="w-full"
            loading={uploading}
            disabled={uploading}
            enableEventPropagation
          >
            Upload image
          </FilledButton>
        </div>
      </div>
      <div className="mt-16">
        <h1 className="text-center text-2xl text-isabelline">{data?.name}</h1>
        <div className="mt-4 flex flex-row items-center justify-center gap-8">
          <span className="text-sm text-shadowBlue">Restaurant</span>
          <a className="cursor-pointer text-sm text-shadowBlue underline hover:text-beauBlue active:text-spanishGray">
            Get directions
          </a>
        </div>
      </div>
      <div className="mt-12 px-6">
        <SectionButton
          title="UCO Details"
          description="Person in charge, quantity produced, and who collects it"
          checked={status.info}
          onClick={handleClickInfo}
        />
        <SectionButton
          title="Restaurant Details"
          description="Contacts, photos, language spoken"
          checked={status.general}
          onClick={handleClickGeneral}
        />
        <SectionButton
          title="Check-in"
          description={
            data?.checked
              ? formatLatLong(data?.latitude ?? 0, data?.longitude ?? 0)
              : ''
          }
          checked={!!data?.checked}
          onClick={handleClickCheck}
        />
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <SectionButton title="Photos" optional checked={!!data?.image} />
        </div>
        <div className="h-20" />
      </div>
      <Portal
        node={document && document.getElementById('mapping-details-footer')}
      >
        <FilledButton
          size="large"
          variant="secondary"
          className="flex-1"
          onClick={handleClose}
        >
          Close
        </FilledButton>
        <FilledButton
          variant="icterine"
          size="large"
          className="flex-[2]"
          loading={saving}
          disabled={saving}
          onClick={handleClaim}
        >
          Claim
        </FilledButton>
      </Portal>
    </>
  );
};

const SectionButton: React.FC<{
  title: string;
  description?: string;
  checked: boolean;
  optional?: boolean;
  onClick?: (...args: any) => void;
}> = ({ title, description, checked, optional, onClick }) => {
  return (
    <button
      className={clsx([
        'flex w-full flex-row justify-between bg-[#47598533]',
        'mb-2 rounded-2xl px-4 py-3',
        'hover:bg-[#47598566] active:bg-[#47598522]',
        !description && 'items-center',
      ])}
      onClick={onClick}
    >
      <div className="mr-4 flex-1">
        <h5 className="text-left text-xl text-white">{title}</h5>
        {description && (
          <p className="mt-1 text-left text-sm leading-relaxed text-beauBlue">
            {description}
          </p>
        )}
      </div>
      {optional && (
        <span className="mr-4 text-[10px] font-bold text-shadowBlue">
          Optional
        </span>
      )}
      <div
        className={clsx([
          'h-[32px] w-[32px] rounded-full border-2',
          'flex flex-col items-center justify-center',
          checked ? 'border-icterine' : 'border-purpleNavy',
        ])}
      >
        {checked && <CheckIcon className="text-icterine" />}
      </div>
    </button>
  );
};

function formatLatLong(latitude: number, longitude: number) {
  const latDirection = latitude >= 0 ? 'N' : 'S';
  const lonDirection = longitude >= 0 ? 'E' : 'W';

  const latDegrees = Math.floor(Math.abs(latitude));
  const latMinutes = Math.floor((Math.abs(latitude) - latDegrees) * 60);
  const latSeconds = Math.floor(
    ((Math.abs(latitude) - latDegrees) * 60 - latMinutes) * 60,
  );

  const lonDegrees = Math.floor(Math.abs(longitude));
  const lonMinutes = Math.floor((Math.abs(longitude) - lonDegrees) * 60);
  const lonSeconds = Math.floor(
    ((Math.abs(longitude) - lonDegrees) * 60 - lonMinutes) * 60,
  );

  return `${latDirection} ${latDegrees}° ${latMinutes}' ${latSeconds}'' ${lonDirection} ${lonDegrees}° ${lonMinutes}' ${lonSeconds}''`;
}
