import React from 'react';
import clsx from 'clsx';
import { createRoot } from 'react-dom/client';
import { OutlinedButton } from '../buttons/OutlinedButton';
import { Checkbox } from '../inputs/Checkbox';
import Scrollbars from 'react-custom-scrollbars-2';

interface IRequestUCOInfoDialogProps {
  id?: string | number;
  onDisable: () => void;
}

export class RequestUCOInfoDialog extends React.Component<
  IRequestUCOInfoDialogProps,
  { loaded: boolean; disable: boolean }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      loaded: false,
      disable: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChangeDisable = this.handleChangeDisable.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('popstate', this.handleBackButton);

    setTimeout(() => {
      this.setState({
        loaded: true,
      });
    }, 10);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('popstate', this.handleBackButton);
  }

  handleClose() {
    if (this.state.disable) {
      this.props.onDisable();
    }

    this.setState({
      loaded: false,
    });
    setTimeout(() => {
      const root = document.getElementById('root');
      if (root) {
        const modal = document.getElementById('modal' + '-' + this.props.id);
        if (modal) {
          root.removeChild(modal);
        }
      }
    }, 300);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.handleClose();
    }
  };

  handleBackButton = () => {
    this.handleClose();
  };

  handleNo = () => {
    this.handleClose();
  };

  handleChangeDisable = (checked: boolean) => {
    this.setState({
      disable: checked,
    });
  };

  render(): React.ReactNode {
    return (
      <div
        className={clsx({
          'fixed bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-end bg-raisinBlack px-2 pb-2 transition-all duration-300': 1,
          'bg-opacity-0': !this.state.loaded,
          'bg-opacity-60': this.state.loaded,
        })}
        onClick={this.handleClose}
      >
        <div
          className={clsx({
            'flex min-h-80 w-full max-w-[600px] flex-col rounded-3xl bg-isabelline px-8 py-8': 1,
            'transition-all duration-300': 1,
            'translate-y-full opacity-50': !this.state.loaded,
            'translate-y-0 opacity-100': this.state.loaded,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <Scrollbars
            style={{
              height: '70vh',
            }}
          >
            <div>
              <h1 className="text-[32px] font-semibold leading-normal text-raisinBlack">
                How to Request UCO Info
              </h1>
              <p
                className={clsx([
                  'leading-normal text-raisinBlack',
                  'mt-4 font-medium',
                ])}
              >
                <strong className="font-bold">Tip:</strong> Most of the
                restaurant details you can input by looking out the info online.
                <br />
                But we really need your help to know if their Used Cooking Oil
                is being collected and by whom {':)'}
              </p>
              <p className="mt-6 text-lg font-bold leading-normal text-raisinBlack">
                1. First, introduce yourself and explain the challenge -
                something like this:
              </p>
              <p className="mt-2 leading-normal text-raisinBlack">
                &quot;I&apos;m using Oily that rewards me for mapping
                restaurants that use cooking oil! The goal is to map every drop
                of used cooking oil in the world to know if it&apos;s being
                recycled!&quot;
              </p>
              <p className="mt-6 text-lg font-bold leading-normal text-raisinBlack">
                2. Now strike with the questions!
              </p>
              <p className="mt-2 leading-normal text-raisinBlack">
                &quot;If it&apos;s okay for you, I would like to know if you fry
                food here and if you recycle the Used Cooking Oil. Can I have a
                2min of your time?&quot; & thank you for mapping!
              </p>
            </div>
            <div className="mt-8 flex flex-row items-center">
              <Checkbox
                checked={this.state.disable}
                onChange={this.handleChangeDisable}
              />
              <div className="ml-2 font-bold text-raisinBlack">
                Don&apos;t show this again
              </div>
            </div>
            <div className="mt-16 flex flex-row items-center justify-start">
              <OutlinedButton
                size="large"
                variant="dark"
                onClick={this.handleNo}
              >
                Ok, I understand
              </OutlinedButton>
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }

  public static open(props: IRequestUCOInfoDialogProps) {
    const root = document.getElementById('root');
    if (root) {
      const key = Date.now();
      const div = document.createElement('div');
      div.id = 'modal' + '-' + key;
      root.appendChild(div);
      createRoot(div).render(
        <RequestUCOInfoDialog id={key} onDisable={props.onDisable} />,
      );
    }
  }
}
