import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Scrollbars from 'react-custom-scrollbars-2';
import { ProfileLayout, StandardInput } from '../../components';
import { CheckIcon } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { countries } from 'countries-list';
import { IRootState } from '../../redux/store';

export const EditRegionPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state: IRootState) => state.auth);

  const [country, setCountry] = useState(
    auth.regions.length > 0 ? auth.regions[0] : '',
  );
  const [search, setSearch] = useState('');

  const handleSelectCountry = async (value: string) => {
    navigate('/profile/general', {
      replace: true,
      state: {
        ...location.state,
        country: value,
      },
    });
  };

  useEffect(() => {
    if (location.state?.country) {
      setCountry(location.state.country);
    } else {
      setCountry('');
    }
  }, [location.state]);

  return (
    <Scrollbars
      style={{
        width: '100%',
        height: '100vh',
      }}
    >
      <ProfileLayout title="Select your country" showBackButton>
        <div className="flex flex-row justify-center">
          <div className="flex w-full max-w-[600px] flex-col px-8">
            <div className="">
              <StandardInput
                variant="gray"
                placeholder="Search country"
                value={search}
                onChangeText={setSearch}
              />
            </div>
            <div className="pb-32 pt-8">
              {Object.keys(countries)
                .filter((key) => {
                  return (
                    search.toLowerCase() === key.toLowerCase() ||
                    search === '' ||
                    (countries as any)[key].name
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  );
                })
                .map((key) => (
                  <button
                    key={`country-${key}`}
                    className={clsx([
                      'flex w-full flex-row items-center justify-between gap-2 border-b border-b-raisinBlackHover px-3 py-4 text-isabelline',
                      'hover:bg-raisinBlackHover hover:bg-opacity-15',
                      'active:bg-raisinBlackActive active:bg-opacity-10',
                    ])}
                    onClick={() => handleSelectCountry(key)}
                  >
                    <div className="flex flex-col items-start gap-2">
                      <div>
                        {(countries as any)[key].name} {`(${key})`}
                      </div>
                    </div>
                    {key === country ? (
                      <CheckIcon className="text-green" />
                    ) : null}
                  </button>
                ))}
            </div>
          </div>
        </div>
      </ProfileLayout>
    </Scrollbars>
  );
};
