import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './config/axios';
import './index.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-modern-drawer/dist/index.css';

if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [
          'https://devcommunityapi.uco.network/api/common/v1/file/upload',
        ],
        networkCaptureBodies: true,
        networkRequestHeaders: ['x-access-token'],
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/uco\.network/],
    replaysSessionSampleRate: process.env.REACT_APP_SENTRY_SESSION_SAMPLE_RATE
      ? Number(process.env.REACT_APP_SENTRY_SESSION_SAMPLE_RATE)
      : 1,
    replaysOnErrorSampleRate: process.env.REACT_APP_SENTRY_ERROR_SAMPLE_RATE
      ? Number(process.env.REACT_APP_SENTRY_ERROR_SAMPLE_RATE)
      : 1,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
