import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { ArrowLeftIcon, ZapIcon } from 'lucide-react';
import { FilledButton } from '../../buttons/FilledButton';
import { IconButton } from '../../buttons/IconButton';
import { ConnectOptionsDialog } from './ConnectOptionsDialog';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useWallet } from '../../../providers/WalletProvider';
import { IRootState } from '../../../redux/store';
import { colors } from '../../../config/theme';

export const AppPrimaryHeader: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isLoggedIn, isVerified, avatar, xp } = useSelector(
    (state: IRootState) => state.auth,
  );
  const { connectWallet } = useWallet();

  const [showBack, setShowBack] = useState(false);
  const [showHeader, setShowHeader] = useState(true);

  const handleConnect = useCallback(async () => {
    ConnectOptionsDialog.open({
      onChooseVechain: () => {
        connectWallet('vechain');
      },
      onChooseXDC: async () => {
        connectWallet('xdc');
      },
    });
  }, []);

  const handleRedeem = useCallback(() => {
    navigate('/redeem');
  }, []);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  useEffect(() => {
    if (
      ['/home', '/mappings', '/challenges', '/leaderboard', '/redeem'].includes(
        location.pathname,
      )
    ) {
      setShowBack(false);
    } else {
      setShowBack(true);
    }

    if (['/mappings'].includes(location.pathname)) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [location]);

  return (
    <div
      className={clsx([
        'absolute left-0 right-0 top-0 z-20 flex flex-row items-center justify-between px-4 pt-4',
        !showHeader && 'hidden',
      ])}
    >
      <div>
        {showBack ? (
          <IconButton
            variant="dark"
            size="small"
            icon={ArrowLeftIcon}
            onClick={handleBack}
          />
        ) : isLoggedIn && isVerified ? (
          <>
            <NavLink
              className={clsx([
                'box-border block h-[28px] w-[28px]',
                'flex flex-row items-center justify-center overflow-hidden rounded-full bg-raisinBlack',
              ])}
              to="/profile"
            >
              <img
                src={avatar ?? ''}
                className="h-[20px] w-[20px] rounded-full object-contain"
                alt="Profile Image"
              />
            </NavLink>
          </>
        ) : null}
      </div>
      <div>
        {isLoggedIn && isVerified ? (
          <button onClick={handleRedeem}>
            <div className="flex h-[28px] flex-row items-center justify-center rounded-full bg-raisinBlack px-4">
              <ZapIcon
                size={16}
                fill={colors.icterine}
                stroke={colors.icterine}
              />
              <span className="text-x1 ml-1">{xp?.toFixed(0)} XP</span>
            </div>
          </button>
        ) : (
          <FilledButton
            size="small"
            className="w-[80px]"
            loading={isLoggedIn && !isVerified}
            onClick={handleConnect}
          >
            Connect
          </FilledButton>
        )}
      </div>
    </div>
  );
};
