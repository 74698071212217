import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  AlertDialog,
  CircularProgressIndicator,
  XPIndicator,
} from '../../components';
import { CheckIcon, LockIcon, PlayIcon } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { apiGetChapters } from '../../apis/lms';
import { IRootState } from '../../redux/store';
import { IChapterItem } from '../../apis/types';

import background from '../../assets/backgrounds/background3.png';

export const ChaptersPage: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const section = params.section;
  const { isVerified, isLoggedIn, isProfileCompleted } = useSelector(
    (state: IRootState) => state.auth,
  );
  const { totalXp, totalChapters } = useSelector(
    (state: IRootState) => state.app.summary,
  );
  const [userXp, setUserXp] = useState(0);

  const { data: chapters, isLoading } = useQuery({
    queryKey: ['lms', 'chapters'],
    queryFn: apiGetChapters,
    enabled: isVerified && isLoggedIn && isProfileCompleted,
  });
  const [isNotStarted, setIsNotStarted] = useState(true);

  const handlePlay = (item: IChapterItem, index: number) => {
    if (!item.released) {
      return AlertDialog.open({
        title: 'Unreleased challenge',
        message:
          'This challenge is locked as it has not yet been released by the Oily team, please come at the end of the week',
        confirmLabel: 'Okay, I understand',
      });
    }
    if (item.locked) {
      return AlertDialog.open({
        title: 'Locked challenge',
        message: 'Complete the previous challenges to unlock.',
        confirmLabel: 'Okay, I understand',
      });
    }

    navigate(
      `/challenges/${section}/chapters/${index + 1}-${item._id}/lessons`,
      {
        state: {
          chapterTitle: item.title,
          chapterOrder: index + 1,
          chapterTotalLessons: item.total_lessons,
          chapterTotalXp: item.total_xp,
          chapterGainedXp: item.user_xp,
        },
      },
    );
  };

  useEffect(() => {
    if (chapters) {
      let sum = 0;

      for (const chapter of chapters) {
        sum += chapter.user_xp;
        if (chapter.user_xp > 0) {
          setIsNotStarted(false);
        }
      }

      setUserXp(sum);
    }
  }, [chapters]);

  return (
    <div>
      <div className="flex flex-row justify-center px-8 pt-20">
        <div className="w-full max-w-[600px]">
          <div className={clsx(['fixed left-0 right-0 top-0 -z-10'])}>
            <img src={background} className="h-full w-full" />
          </div>
          <h1 className="text-[40px] font-semibold text-icterine">
            {section === 'learn'
              ? 'Learn'
              : section === 'map'
                ? 'Map'
                : 'Recycle'}
          </h1>
          <h1 className="text-md text-[40px] font-semibold text-isabelline">
            and earn
          </h1>
          <div className="mt-8 flex flex-row items-center">
            <span className="text-md text-isabelline">
              {totalChapters} Challenges
            </span>
            <XPIndicator
              total={totalXp?.toFixed(0)}
              current={
                isLoggedIn && isVerified && isProfileCompleted
                  ? userXp?.toFixed(0)
                  : undefined
              }
              containerClassName="ml-6"
            />
          </div>

          <div className="mb-16 mt-4 text-sm text-beauBlue">
            {isNotStarted && chapters && chapters?.length > 0 && !isLoading ? (
              'Not started yet'
            ) : (
              <div className="h-4" />
            )}
          </div>

          {isLoading || !chapters || chapters.length === 0
            ? Array(5)
                .fill(true)
                .map((_, index) => (
                  <div
                    key={`loading-section-${index}`}
                    className="mb-12 animate-pulse"
                  >
                    <div className="h-4 w-32 rounded-full bg-isabelline bg-opacity-25" />
                    <div className="mt-1 flex flex-row items-start justify-between">
                      <div className="mt-1 h-8 flex-1 rounded-full bg-isabelline bg-opacity-25" />
                      <button
                        className={clsx({
                          'ml-4 flex h-[40px] w-[40px] items-center justify-center rounded-full border-2': 1,
                          'hover:border-white hover:text-white': 1,
                          'active:border-isabelline active:text-isabelline': 1,
                          'border-isabelline border-opacity-25 text-isabelline text-opacity-25': 1,
                        })}
                      >
                        <LockIcon className="h-5 w-5" />
                      </button>
                    </div>
                    <div className="mt-4 flex flex-row items-center">
                      <div className="mr-4 h-4 w-24 rounded-full bg-isabelline bg-opacity-25" />
                      <XPIndicator
                        current="∞"
                        textClassName="text-shadowBlue"
                      />
                    </div>
                  </div>
                ))
            : chapters?.map((item, index) => (
                <div key={`section-${item._id}`} className="mb-12">
                  <h6
                    className={clsx([
                      'text-sm',
                      true ? 'text-isabelline' : 'text-shadowBlue',
                    ])}
                  >
                    Challenge {index + 1}
                    {item.progress >= 100 && (
                      <span className="text-icterine"> - Completed</span>
                    )}
                  </h6>
                  <div className="mt-1 flex flex-row items-start justify-between">
                    <h2
                      className={clsx([
                        'flex-1 text-3xl',
                        item.locked ? 'text-shadowBlue' : 'text-white',
                      ])}
                    >
                      {item.title}
                    </h2>
                    <button
                      className={clsx({
                        'relative ml-4 flex h-[40px] w-[40px] items-center justify-center rounded-full': 1,
                        'hover:scale-110 active:scale-105': 1,
                        'transition-transform': 1,
                      })}
                      onClick={() => handlePlay(item, index)}
                    >
                      <CircularProgressIndicator
                        variant="gray"
                        value={item.progress}
                        className="absolute left-0 top-0 h-full w-full"
                      />
                      {item.locked ? (
                        <LockIcon className="h-5 w-5 text-purpleNavy" />
                      ) : item.progress >= 100 ? (
                        <CheckIcon className="h-5 w-5 text-icterine" />
                      ) : (
                        <PlayIcon className="h-5 w-5 text-white" />
                      )}
                    </button>
                  </div>
                  <div className="mt-4 flex flex-row items-center">
                    <span
                      className={clsx([
                        'mr-4',
                        !item.locked ? 'text-beauBlue' : 'text-shadowBlue',
                      ])}
                    >
                      {item.total_lessons} Lessons
                    </span>
                    <XPIndicator
                      current={item?.user_xp?.toFixed(0)}
                      total={item?.total_xp?.toFixed(0)}
                      textClassName={
                        item.locked ? 'text-shadowBlue' : 'text-beauBlue'
                      }
                    />
                  </div>
                </div>
              ))}

          <div className="h-40" />
        </div>
      </div>
    </div>
  );
};
