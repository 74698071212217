import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Outlet } from 'react-router-dom';
import { LeaderboardHeader } from './leaderboard-layout/LeaderboardHeader';

export const LeaderboardLayout: React.FC = () => {
  return (
    <div className="relative h-[100vh] w-full max-w-[600px] overflow-hidden bg-raisinBlack">
      <LeaderboardHeader />
      <div className="h-full w-full">
        <Scrollbars>
          <div className="flex flex-row justify-center">
            <div className="w-full max-w-[600px]">
              <Outlet />
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};
