import React, { useCallback, useEffect } from 'react';
import { Portal } from 'react-portal';
import { FilledButton, ShareEarningDialog } from '../../components';
import { ZapIcon } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IRootState } from '../../redux/store';

import bubbles from '../../assets/images/dark-bubbles.png';

export const MappingPlaceShareClaimPage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const data = useSelector((state: IRootState) =>
    id ? state.places.places[id] : null,
  );

  const handleClose = useCallback(() => {
    navigate('/mappings');
  }, []);

  const handleShare = useCallback(() => {
    if (data?.initial_xp) {
      ShareEarningDialog.open({
        amount: data?.initial_xp,
      });
    }
  }, [data?.initial_xp]);

  useEffect(() => {
    if (!id) {
      navigate('/mappings');
      return;
    }
    if (data) {
      // todo
    } else {
      navigate('/mappings');
    }
  }, [data, id]);

  return (
    <>
      <div
        className="min-h-[80vh] px-6 pt-24"
        style={{
          background: `url(${bubbles})`,
          backgroundSize: '',
          backgroundPosition: 'center right',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <h1 className="text-3xl text-isabelline">Congratulations,</h1>
        <h1 className="mt-2 text-3xl text-isabelline">You{"'"}ve claimed</h1>
        <h1 className="mt-2 text-3xl leading-normal text-beauBlue">
          {data?.name}
        </h1>
        <div className="mt-8 flex flex-row justify-end">
          <div className="relative right-0 top-[0] flex flex-row items-center justify-end">
            <ZapIcon className="mr-1 fill-icterine stroke-icterine" />
            <h1 className="mr-1 text-[100px] leading-none text-white">
              {data?.total_xp ? data.total_xp.toFixed(0) : 0}
            </h1>
            <span className="self-start pt-3 text-3xl leading-none text-isabelline">
              XP
            </span>
          </div>
        </div>
        <div className="mt-8 leading-normal text-isabelline">
          You will receive up to {data?.total_xp ? data.total_xp.toFixed(0) : 0}{' '}
          XP once the data has been verified.
        </div>
      </div>
      <Portal
        node={document && document.getElementById('mapping-details-footer')}
      >
        <FilledButton
          size="large"
          variant="secondary"
          className="flex-1"
          onClick={handleClose}
        >
          Close
        </FilledButton>
        <FilledButton
          variant="icterine"
          size="large"
          className="flex-[2]"
          onClick={handleShare}
        >
          Share
        </FilledButton>
      </Portal>
    </>
  );
};
