import React from 'react';
import clsx from 'clsx';
import { createRoot } from 'react-dom/client';
import { OutlinedButton } from '../buttons/OutlinedButton';
import { FilledButton } from '../buttons/FilledButton';

interface IAlertDialogProps {
  title?: string;
  message?: string | React.ReactNode;
  canDismiss?: boolean;
  confirmLabel?: string;
  confirmIcon?: React.FC<any>;
  confirmStyle?: 'filled' | 'outlined';
  confirmVariant?: string;
  cancelLabel?: string;
  cancelIcon?: React.FC<any>;
  id?: string | number;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

export class AlertDialog extends React.Component<
  IAlertDialogProps,
  { loaded: boolean }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      loaded: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('popstate', this.handleBackButton);

    setTimeout(() => {
      this.setState({
        loaded: true,
      });
    }, 10);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('popstate', this.handleBackButton);
  }

  handleClose() {
    this.props.onClose && this.props.onClose();
    this.setState({
      loaded: false,
    });
    setTimeout(() => {
      const root = document.getElementById('root');
      if (root) {
        const modal = document.getElementById('modal' + '-' + this.props.id);
        if (modal) {
          root.removeChild(modal);
        }
      }
    }, 300);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && this.props.canDismiss) {
      this.handleClose();
    }
  };

  handleBackButton = () => {
    if (this.props.canDismiss) {
      this.handleClose();
    }
  };

  handleYes = () => {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
    this.handleClose();
  };

  handleNo = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.handleClose();
  };

  render(): React.ReactNode {
    const ConfirmButton =
      this.props.confirmStyle === 'filled' ? FilledButton : OutlinedButton;

    return (
      <div
        className={clsx({
          'fixed bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-end bg-raisinBlack px-2 pb-2 transition-all duration-300': 1,
          'bg-opacity-0': !this.state.loaded,
          'bg-opacity-60': this.state.loaded,
        })}
        onClick={this.handleClose}
      >
        <div
          className={clsx({
            'flex min-h-80 w-full max-w-[600px] flex-col rounded-3xl bg-isabelline px-8 py-8': 1,
            'transition-all duration-300': 1,
            'translate-y-full opacity-50': !this.state.loaded,
            'translate-y-0 opacity-100': this.state.loaded,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex-1">
            {this.props.title && (
              <h1 className="text-[32px] font-semibold leading-normal text-raisinBlack">
                {this.props.title}
              </h1>
            )}
            {this.props.message && (
              <>
                {typeof this.props.message === 'string' ? (
                  <p
                    className={clsx([
                      'font-normal leading-normal text-raisinBlack',
                      this.props.title && 'mt-4',
                    ])}
                  >
                    {this.props.message}
                  </p>
                ) : (
                  this.props.message
                )}
              </>
            )}
          </div>
          <div className="mt-16 flex flex-row items-center justify-start">
            {this.props.cancelLabel && (
              <OutlinedButton
                size="large"
                variant="dark"
                startIcon={this.props.cancelIcon}
                onClick={this.handleNo}
              >
                {this.props.cancelLabel || 'Cancel'}
              </OutlinedButton>
            )}
            {this.props.confirmLabel && (
              <ConfirmButton
                size="large"
                variant={
                  this.props.confirmVariant
                    ? (this.props.confirmVariant as any)
                    : 'dark'
                }
                startIcon={this.props.confirmIcon}
                className={this.props.cancelLabel ? 'ml-4' : ''}
                onClick={this.handleYes}
              >
                {this.props.confirmLabel}
              </ConfirmButton>
            )}
          </div>
        </div>
      </div>
    );
  }

  public static open(props: IAlertDialogProps | undefined = undefined) {
    const root = document.getElementById('root');
    if (root) {
      const key = Date.now();
      const div = document.createElement('div');
      div.id = 'modal' + '-' + key;
      root.appendChild(div);
      createRoot(div).render(<AlertDialog {...props} id={key} />);
    }
  }
}
