import React from 'react';
import clsx from 'clsx';
import { CheckIcon, XIcon } from 'lucide-react';

interface IRadioGroupProps {
  items: {
    label: string;
    value: any;
    error?: boolean;
    highlight?: boolean;
  }[];
  value: any;
  onChange: (value: any) => void;
}

export const RadioGroup: React.FC<IRadioGroupProps> = ({
  items,
  value,
  onChange,
}) => {
  return (
    <div>
      {items.map((item, index) => (
        <Radio
          key={`radio-${index}`}
          label={item.label}
          checked={item.value === value}
          highlight={item.highlight}
          error={item.error}
          className="mb-2"
          value={item.value}
          onChange={(checked) => checked && onChange(item.value)}
        />
      ))}
    </div>
  );
};

interface IRadioProps {
  label: string;
  value: any;
  size?: 'small' | 'medium' | 'large';
  checked?: boolean;
  error?: boolean;
  highlight?: boolean;
  onChange?: (value: boolean) => void;
  className?: string;
}

export const Radio: React.FC<IRadioProps> = ({
  label,
  size = 'medium',
  checked = false,
  error = false,
  highlight = false,
  onChange,
  className,
}) => {
  return (
    <div
      className={clsx({
        'flex flex-row items-start justify-start gap-4 rounded-lg p-2': 1,
        'bg-red400 bg-opacity-30': error,
        'bg-icterine bg-opacity-10': highlight,
        ...(className && { [className]: 1 }),
      })}
      onClick={() => onChange && onChange(!checked)}
    >
      <button
        className={clsx({
          'flex items-center justify-center rounded-full': 1,
          'h-[24px] w-[24px]': size === 'medium',
          'translate-y-[1px] border-2': 1,
          'border-isabelline border-opacity-50': !checked,
          'hover:border-opacity-100': !checked,
          'border-icterine bg-icterine': checked,
          'border-red400 bg-red400': error,
        })}
      >
        {checked && !error && (
          <CheckIcon size={12} className="text-raisinBlack" strokeWidth={4} />
        )}
        {error && <XIcon size={12} className="text-red" />}
      </button>
      <span className="flex-1 text-lg leading-snug text-isabelline">
        {label}
      </span>
    </div>
  );
};
