import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Spinner } from '../indicators/Spinner';

interface OutlinedButtonProps {
  loading?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?: 'icterine' | 'dark' | 'light';
  disabled?: boolean;
  startIcon?: React.FC<any> | null;
  endIcon?: React.FC<any> | null;
  className?: string;
  onClick?: (...args: any) => void;
}

export const OutlinedButton: React.FC<
  PropsWithChildren<OutlinedButtonProps>
> = ({
  children,
  loading = false,
  size = 'medium',
  variant = 'icterine',
  disabled = false,
  startIcon: StartIcon,
  className,
  onClick,
}) => {
  return (
    <button
      className={clsx({
        'flex flex-row items-center justify-center': true,
        'h-[28px] px-3 py-1 text-xs': size == 'small',
        'h-[40px] px-4 py-2 text-sm': size == 'medium',
        'h-[56px] px-6 py-2 text-sm': size == 'large',
        'rounded-full': true,
        'bg-icterine text-raisinBlack hover:bg-icterineHover focus:outline focus:outline-2 focus:outline-icterine50 active:bg-icterineActive':
          variant === 'icterine' && !disabled && !loading,
        'bg-icterineDisabled': variant === 'icterine' && (disabled || loading),
        'border border-raisinBlack bg-transparent text-raisinBlack hover:border-raisinBlackHover hover:text-raisinBlackHover focus:outline focus:outline-2 focus:outline-icterine50 active:border-raisinBlackActive active:text-raisinBlackActive':
          variant === 'dark' && !disabled && !loading,
        'border-raisinBlackDisabled text-raisinBlackDisabled':
          variant === 'dark' && (disabled || loading),
        'border border-isabelline bg-transparent text-isabelline hover:border-white hover:text-white focus:outline focus:outline-2 focus:outline-icterine50 active:border-opacity-80 active:text-opacity-80':
          variant === 'light' && !disabled && !loading,
        ...(className && {
          [className]: 1,
        }),
      })}
      onClick={onClick}
    >
      {loading ? (
        <Spinner size={size} />
      ) : (
        <>
          {StartIcon ? (
            <StartIcon
              className={clsx({
                'mr-2': children ? true : false,
                'h-4 w-4': size === 'small',
                'h-5 w-5': size === 'medium',
                'h-6 w-6': size === 'large',
              })}
            />
          ) : null}
          <span className="font-medium">{children}</span>
        </>
      )}
    </button>
  );
};
