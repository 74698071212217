import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  AddRestaurantCompletionDialog,
  AddRestaurantFormDialog,
  AddRestaurantInfoDialog,
  FilledButton,
  IconButton,
  OutlinedButton,
} from '../../components';
import { ArrowLeftIcon, XIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { GoogleMap, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import { useGeolocated } from 'react-geolocated';
import { toast } from 'react-toastify';

export const MappingAddRestaurantPage: React.FC = () => {
  const navigate = useNavigate();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
  });
  const { isGeolocationEnabled, isGeolocationAvailable, coords } =
    useGeolocated();

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [zoom, setZoom] = useState(16);
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [selectedPlace, setSelectedPlace] = useState<google.maps.LatLng | null>(
    null,
  );

  const handleBack = useCallback(() => {
    navigate('/mappings');
  }, []);

  const handleClose = useCallback(() => {
    navigate('/mappings');
  }, []);

  const handleEdit = useCallback(() => {
    if (selectedPlace) {
      const distance = getDistance({
        lat: selectedPlace.lat(),
        lng: selectedPlace.lng(),
      });
      if (distance && distance <= 1) {
        AddRestaurantFormDialog.open({
          initialData: {
            latitude: selectedPlace.lat(),
            longitude: selectedPlace.lng(),
          },
          onFinish: () => {
            navigate(
              `/mappings?lat=${selectedPlace.lat()}&lng=${selectedPlace.lng()}`,
            );
            AddRestaurantCompletionDialog.open();
          },
        });
      } else {
        toast.error('You can only add restaurant within 1km radius');
      }
    }
  }, [selectedPlace, coords]);

  const onMapMount = useCallback(
    (map: google.maps.Map) => {
      setMap(map);
    },
    [center],
  );

  const onMapUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const onZoomChanged = useCallback(() => {
    if (map) {
      setZoom(map.getZoom() ?? 16);
      setCenter({
        lat: map.getCenter()?.lat() ?? 0,
        lng: map.getCenter()?.lng() ?? 0,
      });
    }
  }, [map]);

  const onCenterChanged = useCallback(() => {
    if (map) {
      setCenter({
        lat: map.getCenter()?.lat() ?? 0,
        lng: map.getCenter()?.lng() ?? 0,
      });
    }
  }, [map]);

  const getDistance = (from: { lat: number; lng: number }) => {
    if (coords && coords.latitude !== 0 && coords.longitude !== 0) {
      const R = 6371;
      const dLat = deg2rad(coords?.latitude - from.lat);
      const dLng = deg2rad(coords?.longitude - from.lng);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(from.lat)) *
          Math.cos(deg2rad(coords?.latitude)) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c;
      return distance;
    }
  };

  const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180);
  };

  const onPressMap = useCallback(
    (e: google.maps.MapMouseEvent) => {
      if (e.latLng?.lat() && e.latLng?.lng()) {
        setSelectedPlace(e.latLng);

        const distance = getDistance({
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        });
        if (distance && distance <= 1) {
          AddRestaurantFormDialog.open({
            initialData: {
              latitude: e.latLng.lat(),
              longitude: e.latLng.lng(),
            },
            onFinish: () => {
              navigate(
                `/mappings?lat=${e.latLng?.lat()}&lng=${e.latLng?.lng()}`,
              );
              AddRestaurantCompletionDialog.open();
            },
          });
        } else {
          toast.error('You can only add restaurant within 1km radius');
        }
      }
    },
    [coords],
  );

  useEffect(() => {
    if (center.lat !== 0 || center.lng !== 0) {
      return;
    }
    if (
      coords &&
      (coords.latitude !== center.lat || coords.longitude !== center.lng)
    ) {
      setCenter({
        lat: coords.latitude,
        lng: coords.longitude,
      });
    }
  }, [coords, center]);

  useEffect(() => {
    AddRestaurantInfoDialog.open();
  }, []);

  return (
    <>
      <div
        className={clsx([
          'relative h-[100vh] w-full max-w-[600px] overflow-hidden bg-white',
          'flex flex-col',
        ])}
      >
        <div
          className={clsx([
            'px-2 pb-2 pt-6',
            'flex flex-row items-center justify-between',
          ])}
        >
          <div className="p-4">
            <IconButton
              icon={ArrowLeftIcon}
              variant="ghost-dark"
              size="small"
              onClick={handleBack}
            />
          </div>
          <h6 className="leading-relaxed text-raisinBlack">
            Add a Restaurant to Map
          </h6>
          <div className="p-4">
            <IconButton
              icon={XIcon}
              variant="ghost-dark"
              size="small"
              onClick={handleClose}
            />
          </div>
        </div>
        <div className="flex-1">
          {isGeolocationAvailable ? (
            isGeolocationEnabled && isLoaded ? (
              <GoogleMap
                mapContainerClassName="w-full h-full"
                center={center}
                zoom={zoom}
                options={{
                  zoomControl: false,
                  mapTypeControl: false,
                  streetViewControl: false,
                  fullscreenControl: false,
                }}
                onLoad={onMapMount}
                onUnmount={onMapUnmount}
                onDragEnd={onCenterChanged}
                onZoomChanged={onZoomChanged}
                onClick={onPressMap}
              >
                {coords?.latitude && coords?.longitude ? (
                  <OverlayView
                    position={{
                      lat: coords.latitude,
                      lng: coords.longitude,
                    }}
                    mapPaneName="overlayMouseTarget"
                  >
                    <CenterMarker />
                  </OverlayView>
                ) : null}
                {selectedPlace ? (
                  <OverlayView
                    key={`selected-place-mark`}
                    position={selectedPlace}
                    mapPaneName="overlayMouseTarget"
                  >
                    <PlaceMarker />
                  </OverlayView>
                ) : null}
              </GoogleMap>
            ) : (
              <div className="flex h-full flex-col items-center justify-center px-8">
                <p className="pb-4 text-center text-raisinBlack">
                  Please enable geolocation service.
                </p>
              </div>
            )
          ) : (
            <div className="flex h-full flex-col items-center justify-center px-8">
              <p className="pb-4 text-center text-raisinBlack">
                Your browser does not support geolocation service.
              </p>
            </div>
          )}
        </div>
        <div
          className={clsx([
            'rounded-t-3xl px-6 pb-4 pt-10',
            'flex flex-row gap-4',
          ])}
        >
          <OutlinedButton
            variant="dark"
            size="large"
            className="flex-1"
            onClick={handleClose}
          >
            Close
          </OutlinedButton>
          <FilledButton
            variant="dark"
            size="large"
            className="flex-[2]"
            onClick={handleEdit}
          >
            Save
          </FilledButton>
        </div>
      </div>
    </>
  );
};

export const PlaceMarker: React.FC = () => {
  return (
    <div className="flex -translate-y-full cursor-pointer flex-col items-center drop-shadow-xl hover:scale-110 active:scale-105">
      <div
        className={clsx([
          'h-6 w-6 rounded-full',
          'border-4 transition-all',
          'border-white bg-red',
        ])}
      />
      <div className={clsx(['h-4 rounded-full', '-mt-1 w-1', 'bg-white'])} />
    </div>
  );
};

export const CenterMarker: React.FC = () => {
  return (
    <div className="flex -translate-y-full cursor-pointer flex-col items-center drop-shadow-xl hover:scale-110 active:scale-105">
      <div
        className={clsx([
          'h-6 w-6 rounded-full',
          'border-4 transition-all',
          'border-white bg-royalBlueDark',
        ])}
      />
      <div className={clsx(['h-4 rounded-full', '-mt-1 w-1', 'bg-white'])} />
    </div>
  );
};
