import axios from 'axios';
import { withScope, captureException } from '@sentry/react';

export const apiUploadFile = async (data: {
  key: string;
  files: File[] | Blob[];
}) => {
  try {
    const formData = new FormData();
    formData.append('key', data.key);
    for (const file of data.files) {
      formData.append('files', file);
    }
    const response = await axios.post('/api/common/v1/file/upload', formData);
    return response.data.data;
  } catch (e) {
    withScope(() => {
      captureException(e);
    });
    return null;
  }
};
