import React, { useCallback, useEffect, useState } from 'react';
import {
  LessonHeader,
  AppContent,
  FilledButton,
  AlertDialog,
} from '../../components';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  apiGetLessonPages,
  apiGetLessons,
  apiGetQuestions,
} from '../../apis/lms';
import { saveLessonProgress } from '../../redux/lessons.slice';
import { IRootState } from '../../redux/store';

export const LessonContentsPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const segments = params.segments;
  if (
    !segments ||
    !segments.includes('-') ||
    segments.split('-').length !== 2 ||
    !params.lessonId
  ) {
    return <Navigate to="/challenges" />;
  }

  const chapterId = segments.split('-')[1];
  const lessonId = params.lessonId;
  const sectionId = params.sectionId;
  const { data: pages, isLoading: isLoadingPages } = useQuery({
    queryKey: ['lms', 'chapters', chapterId, 'lessons', lessonId, 'pages'],
    queryFn: async () => {
      return await apiGetLessonPages(lessonId);
    },
  });
  const { data: questions, isLoading: isLoadingQuestions } = useQuery({
    queryKey: ['lms', 'chapters', chapterId, 'lessons', lessonId, 'questions'],
    queryFn: async () => {
      return await apiGetQuestions(lessonId);
    },
  });
  const { data: lessons } = useQuery({
    queryKey: ['lms', 'chapters', chapterId, 'lessons'],
    queryFn: async () => {
      return await apiGetLessons(chapterId);
    },
  });

  const currentPage = useSelector(
    (state: IRootState) => state.lessons.savedPages[lessonId],
  );

  const [isCompleted, setIsCompleted] = useState(false);
  const [totalXp, setTotalXp] = useState(0);
  const [currentXp, setCurrentXp] = useState(0);

  const handleCancel = useCallback(() => {
    if (isCompleted) {
      return navigate(-1);
    }

    AlertDialog.open({
      title: 'Are you sure you want to leave this lesson?',
      message: 'Rest assured, any progress you make will be saved an stored.',
      cancelLabel: 'Back',
      confirmLabel: 'Leave lesson',
      confirmStyle: 'filled',
      confirmVariant: 'pink',
      onConfirm: () => navigate(-1),
    });
  }, [isCompleted]);

  const handleContinue = useCallback(() => {
    if (pages && pages.length > 0) {
      if (currentPage < pages.length) {
        dispatch(
          saveLessonProgress({
            lessonId: lessonId,
            page: currentPage + 1,
          }),
        );
      } else {
        if (isCompleted) {
          navigate(-1);
        } else {
          navigate(
            `/challenges/${sectionId}/chapters/${segments}/lessons/${lessonId}/questions`,
            {
              replace: true,
            },
          );
        }
      }
    }
  }, [currentPage, pages, isCompleted]);

  useEffect(() => {
    if (lessons) {
      for (const lesson of lessons) {
        if (lesson._id === lessonId) {
          setTotalXp(lesson.total_xp);
          setCurrentXp(lesson.user_xp);
          return;
        }
      }
    }
  }, [lessons]);

  useEffect(() => {
    if (questions) {
      let completed = true;
      for (const item of questions) {
        if (!item.answered) {
          completed = false;
          break;
        }
      }
      setIsCompleted(completed);
      if (completed) {
        dispatch(
          saveLessonProgress({
            lessonId: lessonId,
            page: 1,
          }),
        );
      }
    }
  }, [questions, lessonId]);

  useEffect(() => {
    if (!currentPage) {
      dispatch(
        saveLessonProgress({
          lessonId: lessonId,
          page: 1,
        }),
      );
    }
  }, [currentPage]);

  return (
    <div className="relative min-h-[100vh] w-full max-w-[600px] bg-raisinBlack">
      <LessonHeader
        currentPage={currentPage}
        currentXp={currentXp}
        totalPages={pages ? pages.length : 0}
        totalXp={pages ? totalXp : 0}
        onClose={handleCancel}
      />
      <AppContent className="pt-[90px]">
        <div
          className="flex flex-col"
          style={{
            minHeight: 'calc(100vh - 90px)',
          }}
        >
          {isLoadingPages || isLoadingQuestions ? (
            <>
              <div className="h-[300px] w-full animate-pulse bg-isabelline bg-opacity-25" />
              <div className="mt-8 animate-pulse px-8">
                <div className="h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/2 rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/4 rounded-full bg-isabelline bg-opacity-25" />
              </div>
              <div className="mt-8 animate-pulse px-8">
                <div className="h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/2 rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/4 rounded-full bg-isabelline bg-opacity-25" />
              </div>
              <div className="mt-8 animate-pulse px-8">
                <div className="h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/2 rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                <div className="mt-2 h-5 w-1/4 rounded-full bg-isabelline bg-opacity-25" />
              </div>
            </>
          ) : (
            <>
              {pages && pages.length > 0 ? (
                <>
                  {pages &&
                    pages[currentPage - 1] &&
                    pages[currentPage - 1].image && (
                      <div className="p-2">
                        <img
                          src={pages[currentPage - 1].image}
                          className="w-full overflow-hidden rounded-3xl object-cover"
                          style={{
                            aspectRatio: '2/1',
                          }}
                        />
                      </div>
                    )}
                  <div className="mt-8 flex-1">
                    {pages[currentPage - 1] ? (
                      <h1 className="px-8 text-3xl text-white">
                        {pages[currentPage - 1].title}
                      </h1>
                    ) : null}
                    {pages[currentPage - 1] ? (
                      <div
                        className="mt-4 px-8 leading-relaxed text-isabelline"
                        dangerouslySetInnerHTML={{
                          __html: pages[currentPage - 1].content,
                        }}
                      />
                    ) : null}
                  </div>
                </>
              ) : (
                <>
                  <></>
                </>
              )}
            </>
          )}

          <div className="flex flex-row items-center gap-4 p-8">
            <FilledButton
              size="large"
              variant="secondary"
              className="flex-1"
              onClick={handleCancel}
            >
              Close
            </FilledButton>
            <FilledButton
              size="large"
              variant="icterine"
              className="flex-[2]"
              disabled={isCompleted && currentPage === pages?.length}
              onClick={handleContinue}
            >
              Continue
            </FilledButton>
          </div>
        </div>
      </AppContent>
    </div>
  );
};
