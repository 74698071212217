import React, { PropsWithChildren } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import clsx from 'clsx';

export interface IAppContentProps {
  className?: string;
}

export const AppContent: React.FC<PropsWithChildren<IAppContentProps>> = ({
  children,
  className,
}) => {
  return (
    <div
      className={clsx([
        'absolute left-0 top-0 z-10 h-[100vh] w-full bg-transparent',
        className,
      ])}
    >
      <Scrollbars
        autoHide
        renderTrackVertical={() => (
          <div className="fixed right-0 top-0 h-full w-1 bg-transparent"></div>
        )}
        renderThumbVertical={() => (
          <div className="h-full w-1 rounded-full bg-isabelline bg-opacity-40"></div>
        )}
        style={{
          paddingTop: 56,
        }}
      >
        {children}
      </Scrollbars>
    </div>
  );
};
