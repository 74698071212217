import axios from 'axios';
import {
  IChapterItem,
  ILessonItem,
  ILessonPageItem,
  IQuestionItem,
} from './types';
import { store } from '../redux/store';
import { setL2ESummary } from '../redux/app.slice';
import { apiGetProfile } from './auth';
import { apiRunFingerprintjs } from './fingerprintjs';

export const apiGetChallengesSummary = async () => {
  try {
    const response = await axios.get<{
      message: string;
      total_xp: number;
      total_chapters: number;
    }>('/api/client/v1/l2e/info');

    store.dispatch(
      setL2ESummary({
        summary: {
          totalXp: response.data.total_xp,
          totalChapters: response.data.total_chapters,
        },
      }),
    );

    return {
      totalXp: response.data.total_xp,
      totalChapters: response.data.total_chapters,
    };
  } catch {
    return {
      totalXp: 0,
      totalChapters: 0,
    };
  }
};

export const apiGetChapters = async (): Promise<IChapterItem[]> => {
  try {
    const response = await axios.get<{
      message: string;
      data: IChapterItem[];
    }>('/api/client/v1/l2e/chapters');

    return response.data.data.sort((a, b) => (a.order > b.order ? 1 : -1));
  } catch (e) {
    return [];
  }
};

export const apiGetLessons = async (
  chapter: string,
): Promise<ILessonItem[]> => {
  try {
    const response = await axios.get<{
      message: string;
      data: ILessonItem[];
    }>(`/api/client/v1/l2e/lessons/${chapter}`);

    return response.data.data.sort((a, b) => (a.order > b.order ? 1 : -1));
  } catch (e) {
    return [];
  }
};

export const apiGetLessonPages = async (
  lesson: string,
): Promise<ILessonPageItem[]> => {
  try {
    const response = await axios.get<{
      message: string;
      data: ILessonPageItem[];
    }>(`/api/client/v1/l2e/pages/${lesson}`);

    return response.data.data;
  } catch (e) {
    return [];
  }
};

export const apiGetQuestions = async (
  lesson: string,
): Promise<IQuestionItem[]> => {
  try {
    const response = await axios.get<{
      message: string;
      data: IQuestionItem[];
    }>(`/api/client/v1/l2e/questions/${lesson}`);

    return response.data.data.sort((a, b) => (a._id > b._id ? 1 : -1));
  } catch (e) {
    return [];
  }
};

export const apiAnswerQuestion = async (data: {
  questionId: string;
  answers: {
    _id: string;
    checked: boolean;
  }[];
}) => {
  return new Promise<boolean>((resolve) => {
    grecaptcha.enterprise.ready(async () => {
      try {
        const token = await grecaptcha.enterprise.execute(
          process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
          { action: 'answer' },
        );
        const response = await axios.post(`/api/client/v1/l2e/answer`, {
          ...data,
          recaptcha_token: token,
        });

        await apiGetProfile({
          need_balance: false,
          need_ratio: false,
        });
        await apiRunFingerprintjs();
        return resolve(!!response.data.data?.correct);
      } catch {
        return resolve(false);
      }
    });
  });
};
