import React, { useCallback, useEffect, useMemo } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import clsx from 'clsx';
import {
  AlertDialog,
  FilledButton,
  XPIndicator,
  ConnectOptionsDialog,
} from '../../components';
import { Img } from 'react-image';
import {
  ArrowRightIcon,
  CircleCheckIcon,
  CircleXIcon,
  ZapIcon,
} from 'lucide-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useWallet } from '../../providers/WalletProvider';
import moment from 'moment';
import ordinal from 'ordinal';
import { apiGetChapters } from '../../apis/lms';
import { apiGetArticles } from '../../apis/articles';
import { apiGetLeaderboardData } from '../../apis/leaderboard';
import { apiGetMyPlaces, apiGetMyWMOs } from '../../apis/mapping';
import { apiRunFingerprintjs } from '../../apis/fingerprintjs';
import { IRootState } from '../../redux/store';
import { ICreatedPlaceItem } from '../../apis/types';

export const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { connectWallet } = useWallet();

  const { totalXp } = useSelector((state: IRootState) => state.app.summary);
  const { m2eCreateWmoXp, m2eCreatePlaceXp } = useSelector(
    (state: IRootState) => state.app,
  );
  const {
    isVerified,
    isLoggedIn,
    isProfileCompleted,
    accessToken,
    xp,
    reward,
    ratio,
  } = useSelector((state: IRootState) => state.auth);
  const { data: chapters } = useQuery({
    queryKey: ['lms', 'chapters'],
    queryFn: async () => {
      return await apiGetChapters();
    },
    enabled: isVerified && isLoggedIn && isProfileCompleted,
  });
  const { data: places } = useQuery({
    queryKey: ['m2e', 'my-places'],
    queryFn: async () => {
      return await apiGetMyPlaces();
    },
    enabled: isVerified && isLoggedIn && isProfileCompleted,
  });
  const { data: wmos } = useQuery({
    queryKey: ['m2e', 'my-wmos'],
    queryFn: async () => {
      return await apiGetMyWMOs();
    },
  });
  const { data: articles, isLoading: isLoadingArticles } = useQuery({
    queryKey: ['articles'],
    queryFn: async () => {
      return await apiGetArticles({
        limit: 10,
        skip: 0,
      });
    },
  });
  const { data: ranking, isLoading: isLoadingRanking } = useQuery({
    queryKey: [
      'leaderboard',
      moment().year(),
      moment().month(),
      'current-ranking',
    ],
    queryFn: async () => {
      const result = await apiGetLeaderboardData({
        year: moment().year(),
        month: moment().month() + 1,
        limit: 10,
        skip: 0,
      });
      return result.me;
    },
    enabled: isVerified && isLoggedIn,
  });

  const handleRedeem = useCallback(() => {
    navigate('/redeem');
  }, []);

  const handlePlace = useCallback((place: ICreatedPlaceItem) => {
    if (place.status === 'pending') {
      navigate(`/mappings?lat=${place.latitude}&lng=${place.longitude}`);
    }
    if (place.status === 'approved') {
      navigate('/mappings');
    }
    if (place.status === 'pending') {
      navigate(`/mappings?lat=${place.latitude}&lng=${place.longitude}`);
    }
  }, []);

  const handleWmo = useCallback(() => {
    navigate('/mappings/add/wmo');
  }, []);

  const EngageCard = useMemo(() => {
    let cardTitle = 'Your first challenge';
    let cardSubtitle = 'Onboarding';
    let cardDescription = 'Tell us about you';
    let cardLessons: string | number = 0;
    let cardUserXp = 0;
    let cardTotalXp = 90;
    let buttonText = 'Start now';

    let handler: any;

    if (isVerified && isLoggedIn) {
      if (isProfileCompleted) {
        if (chapters) {
          let done = false;
          for (let i = 0; i < chapters.length; i++) {
            const item = chapters[i];

            if (item.progress < 100) {
              cardTitle =
                item.progress > 0 ? 'Resume challenge' : 'Your next challenge';
              cardSubtitle = 'Learn to earn';
              cardDescription = item.title;
              cardLessons =
                item.progress === 0
                  ? item.total_lessons
                  : `${Math.round((item.total_lessons * item.progress) / 100)}/${item.total_lessons}`;
              cardUserXp = item.user_xp;
              cardTotalXp = item.total_xp;

              if (item.progress > 0) {
                buttonText = 'Resume';
              }

              handler = () => {
                if (item.released) {
                  navigate(
                    `/challenges/learn/chapters/${i + 1}-${item._id}/lessons`,
                  );
                } else {
                  AlertDialog.open({
                    title: 'Unreleased challenge',
                    message:
                      'This challenge is locked as it has not yet been released by the Oily team, please come at the end of the week',
                    canDismiss: true,
                    confirmLabel: 'Okay, I understand',
                    confirmStyle: 'outlined',
                  });
                }
              };

              done = true;
              break;
            }
          }

          if (!done) {
            return null;
          }
        }
      } else {
        cardTitle = 'Your first challenge';
        cardSubtitle = 'Complete it';
        cardDescription = 'now and earn';
        cardLessons = 0;
        cardTotalXp = 90;

        handler = () => {
          navigate('/profile');
        };
      }
    } else {
      cardTitle = 'Create your account';
      cardSubtitle = 'Start earning';
      cardDescription = 'XPs';
      cardLessons = 0;
      cardTotalXp = 90;

      handler = () => {
        if (!isLoggedIn || !isVerified) {
          ConnectOptionsDialog.open({
            onChooseVechain: () => {
              connectWallet('vechain');
            },
            onChooseXDC: () => {
              connectWallet('xdc');
            },
          });
          return;
        }
      };
    }

    return (
      <div className="mx-8 rounded-3xl bg-icterine px-6 py-8">
        <h5 className="text-2xl font-medium text-royalBlueDark">{cardTitle}</h5>
        <h1 className="mt-1 text-5xl font-medium leading-tight text-royalBlueDark">
          <span className="text-purpleNavy">{cardSubtitle}</span>
          <br />
          <span className="line-clamp-2">{cardDescription}</span>
        </h1>
        <div className="mt-2 flex flex-row items-center gap-3">
          {cardLessons ? (
            <>
              <span className="font-medium text-royalBlueDark">
                {cardLessons} Lessons
              </span>
              <span className="font-medium text-royalBlueDark">-</span>
            </>
          ) : null}
          <XPIndicator
            current={cardUserXp ? cardUserXp.toFixed(0) : undefined}
            total={cardTotalXp?.toFixed(0)}
            iconClassName="stroke-royalBlueDark fill-royalBlueDark text-royalBlueDark"
            textClassName="text-royalBlueDark"
          />
        </div>
        <div className="mt-6">
          <button
            className={clsx([
              'flex flex-row items-center gap-3 text-3xl font-medium text-royalBlueDark',
              'hover:underline active:text-opacity-60 active:underline',
            ])}
            onClick={handler}
          >
            <span>{buttonText}</span>
            <ArrowRightIcon />
          </button>
        </div>
      </div>
    );
  }, [
    isVerified,
    isLoggedIn,
    totalXp,
    chapters,
    open,
    accessToken,
    isProfileCompleted,
  ]);

  useEffect(() => {
    apiRunFingerprintjs();
  }, []);

  return (
    <div>
      <div className="flex flex-row justify-center">
        <div className="w-full max-w-[600px]">
          {!isLoggedIn || !isVerified ? (
            <>
              <h1 className="mt-[10vh] px-8 text-[32px]">
                Welcome to the Oily Community dApp by UCO Network
              </h1>
              <h2 className="mb-8 mt-8 px-8 text-right text-[40px] font-medium">
                Complete challenges and earn redeemable XP&apos;s!
              </h2>
            </>
          ) : (
            <div className="mt-[10vh] px-8">
              {isLoadingRanking ? (
                <>
                  <div className="h-[32px] w-1/2 animate-pulse rounded-full bg-beauBlue bg-opacity-30" />
                  <div className="mb-10 mt-2 h-[52px] w-2/3 animate-pulse rounded-full bg-beauBlue bg-opacity-30" />
                </>
              ) : (
                <>
                  {ranking && ranking.rank ? (
                    <>
                      <h2 className="text-2xl font-medium leading-tight text-beauBlue">
                        This months rank
                      </h2>
                      <div className="flex flex-row items-start">
                        <h1 className="mb-10 mt-2 text-[75px] leading-none text-white">
                          {ranking.rank}
                        </h1>
                        <h6 className="ml-2 pt-1 text-[42px] text-white">
                          {ordinal(ranking.rank).replace(/[0-9]+/g, '')}
                        </h6>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          )}
          {EngageCard}
          {places && (
            <div className="mt-8">
              {places.length > 0 ? (
                <Scrollbars autoHeight autoHeightMax={600}>
                  <div className="flex flex-row">
                    <div className="min-w-8" />
                    {places.slice(0, 10).map((place) => (
                      <div
                        key={`place-${place._id}`}
                        className={clsx([
                          'box-border rounded-2xl px-6 pb-6 pt-8',
                          'flex w-[310px] min-w-[310px] flex-col items-start justify-start',
                          'mr-4',
                          place.status === 'pending' && 'bg-yellow400',
                          place.status === 'approved' && 'bg-green400',
                          place.status === 'disapproved' && 'bg-red400',
                        ])}
                      >
                        <div
                          className={clsx([
                            'rounded border p-2',
                            'flex flex-row items-center gap-1',
                            place.status === 'pending' &&
                              'border-gray800 bg-gray200 text-gray800',
                            place.status === 'approved' &&
                              'border-green bg-green300 text-green600',
                            place.status === 'disapproved' &&
                              'border-gray500 bg-gray300 text-gray800',
                          ])}
                        >
                          {place.status === 'disapproved' ? (
                            <CircleXIcon className="size-[16px]" />
                          ) : (
                            <CircleCheckIcon className="size-[16px]" />
                          )}
                          <span className="text-sm">
                            {place.status === 'pending'
                              ? 'Under review'
                              : place.status === 'approved'
                                ? 'Restaurant approved'
                                : 'Restaurant disapproved'}
                          </span>
                        </div>
                        {place.status === 'approved' && (
                          <>
                            <h3 className="mt-4 text-3xl leading-tight text-raisinBlack">
                              Congratulations
                            </h3>
                            <h3 className="text-3xl leading-tight text-shadowBlue">
                              You&apos;ve earned
                            </h3>
                            <div className="mt-4 flex w-full flex-row justify-end">
                              <XPIndicator
                                current={m2eCreatePlaceXp ?? 0}
                                iconClassName="stroke-raisinBlack fill-raisinBlack size-[32px]"
                                textClassName="text-[50px] text-raisinBlack"
                              />
                            </div>
                          </>
                        )}
                        <h5 className="mt-4 text-xl font-medium leading-normal text-royalBlueDark">
                          Restaurant added to map
                        </h5>
                        <h2 className="pb-2 text-4xl font-medium text-royalBlueDark">
                          {place.name}
                        </h2>
                        <div className="mt-2 flex-1 leading-relaxed text-raisinBlack">
                          {place.status === 'pending' && (
                            <>
                              Your restaurant has been added to the map and is
                              under review, which may take up to <b>72 hours</b>
                              .
                            </>
                          )}
                          {place.status === 'approved' && (
                            <>
                              Your restaurant has been added to the map and you
                              now have <b>48 hours</b> to map it and earn more
                              XP.
                            </>
                          )}
                          {place.status === 'disapproved' &&
                            'Your restaurant has been added to the map and is under review, which may take up to 72 hours.'}
                        </div>
                        <div className="mt-8">
                          <button
                            className={clsx([
                              'flex flex-row items-center gap-3 text-2xl font-medium text-royalBlueDark',
                              'hover:underline active:text-opacity-60 active:underline',
                            ])}
                            onClick={() => handlePlace(place)}
                          >
                            <span>
                              {place.status === 'pending' && 'View on map'}
                              {place.status === 'approved' && 'Map it now'}
                              {place.status === 'disapproved' &&
                                'Add to map again'}
                            </span>
                            <ArrowRightIcon />
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="min-w-8" />
                  </div>
                </Scrollbars>
              ) : (
                <div className="px-8">
                  <div
                    className={clsx([
                      'box-border rounded-2xl px-6 pb-6 pt-8',
                      'flex w-full flex-col items-start justify-start',
                      'mr-4 bg-gray200',
                    ])}
                  >
                    <h2 className="text-3xl leading-tight text-shadowBlue">
                      Earn now
                    </h2>
                    <div className="mt-4 flex w-full flex-row justify-end">
                      <XPIndicator
                        current={m2eCreatePlaceXp}
                        iconClassName="stroke-raisinBlack fill-raisinBlack size-[32px]"
                        textClassName="text-[50px] text-raisinBlack"
                      />
                    </div>
                    <div className="mt-8">
                      <button
                        className={clsx([
                          'flex flex-row items-center gap-3 text-2xl font-medium text-royalBlueDark',
                          'hover:underline active:text-opacity-60 active:underline',
                        ])}
                        onClick={() => navigate('/mappings/add/restaurant')}
                      >
                        <span>Add a Restaurant</span>
                        <ArrowRightIcon />
                      </button>
                    </div>
                    <p className="mt-1 leading-relaxed text-royalBlueDark">
                      Help us map the unmapped restaurants
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
          {wmos && (
            <div className="mt-8">
              {wmos.length > 0 ? (
                <Scrollbars autoHeight autoHeightMax={600}>
                  <div className="flex flex-row">
                    <div className="min-w-8" />
                    {wmos.slice(0, 10).map((item) => (
                      <div
                        key={`place-${item._id}`}
                        className={clsx([
                          'box-border rounded-2xl px-6 pb-6 pt-8',
                          'flex w-[310px] min-w-[310px] flex-col items-start justify-start',
                          'mr-4',
                          item.status === 'pending' && 'bg-yellow400',
                          item.status === 'approved' && 'bg-green400',
                          item.status === 'disapproved' && 'bg-red400',
                        ])}
                      >
                        <div
                          className={clsx([
                            'rounded border p-2',
                            'flex flex-row items-center gap-1',
                            item.status === 'pending' &&
                              'border-gray800 bg-gray200 text-gray800',
                            item.status === 'approved' &&
                              'border-green bg-green300 text-green600',
                            item.status === 'disapproved' &&
                              'border-gray500 bg-gray300 text-gray800',
                          ])}
                        >
                          {item.status === 'disapproved' ? (
                            <CircleXIcon className="size-[16px]" />
                          ) : (
                            <CircleCheckIcon className="size-[16px]" />
                          )}
                          <span className="text-sm">
                            {item.status === 'pending'
                              ? 'Under review'
                              : item.status === 'approved'
                                ? 'WMO approved'
                                : 'WMO disapproved'}
                          </span>
                        </div>
                        {item.status === 'approved' && (
                          <>
                            <h3 className="mt-4 text-3xl leading-tight text-raisinBlack">
                              Congratulations
                            </h3>
                            <h3 className="text-3xl leading-tight text-shadowBlue">
                              You&apos;ve earned
                            </h3>
                            <div className="mt-4 flex w-full flex-row justify-end">
                              <XPIndicator
                                current={m2eCreateWmoXp ?? 0}
                                iconClassName="stroke-raisinBlack fill-raisinBlack size-[32px]"
                                textClassName="text-[50px] text-raisinBlack"
                              />
                            </div>
                          </>
                        )}
                        <h5 className="mt-4 text-xl font-medium leading-normal text-royalBlueDark">
                          WMO that was added
                        </h5>
                        <h2 className="pb-2 text-4xl font-medium text-royalBlueDark">
                          {item.collector_name}
                        </h2>
                        <div className="mt-2 flex-1 leading-relaxed text-raisinBlack">
                          {item.status === 'pending' && (
                            <>
                              The indicated WMO has been added to the platform
                              and is being analyzed, which can take up to{' '}
                              <b>72 hours</b>.
                            </>
                          )}
                          {item.status === 'approved' && <></>}
                          {item.status === 'disapproved' && (
                            <>
                              The indicated WMO has been added to the platform
                              and is being analyzed, which can take up to{' '}
                              <b>72 hours</b>.
                            </>
                          )}
                        </div>
                        <div className="mt-8">
                          <button
                            className={clsx([
                              'flex flex-row items-center gap-3 text-2xl font-medium text-royalBlueDark',
                              'hover:underline active:text-opacity-60 active:underline',
                            ])}
                            onClick={handleWmo}
                          >
                            <span>Add another WMO</span>
                            <ArrowRightIcon />
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="min-w-8" />
                  </div>
                </Scrollbars>
              ) : (
                <div className="px-8">
                  <div
                    className={clsx([
                      'box-border rounded-2xl px-6 pb-6 pt-8',
                      'flex w-full flex-col items-start justify-start',
                      'mr-4 bg-gray200',
                    ])}
                  >
                    <h2 className="text-3xl leading-tight text-shadowBlue">
                      Earn now
                    </h2>
                    <div className="mt-4 flex w-full flex-row justify-end">
                      <XPIndicator
                        current={m2eCreateWmoXp}
                        iconClassName="stroke-raisinBlack fill-raisinBlack size-[32px]"
                        textClassName="text-[50px] text-raisinBlack"
                      />
                    </div>
                    <div className="mt-8">
                      <button
                        className={clsx([
                          'flex flex-row items-center gap-3 text-2xl font-medium text-royalBlueDark',
                          'hover:underline active:text-opacity-60 active:underline',
                        ])}
                        onClick={() => navigate('/mappings/add/wmo')}
                      >
                        <span>Add a WMO</span>
                        <ArrowRightIcon />
                      </button>
                    </div>
                    <p className="mt-1 leading-relaxed text-royalBlueDark">
                      Give us a direct contact of a WMO
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="mt-10 px-8">
            <div className="flex flex-row items-center justify-between">
              <h6 className="text-[16px] font-bold text-white">
                Current balance
              </h6>
              {isVerified && isLoggedIn && (
                <FilledButton size="small" onClick={handleRedeem}>
                  Redeem
                </FilledButton>
              )}
            </div>
            <div className="mt-2 flex flex-row items-center">
              <ZapIcon className="mr-1 fill-icterine stroke-icterine" />
              <h1 className="ml-1 text-5xl text-white">
                {xp?.toFixed(0) ?? '0'} XP
              </h1>
            </div>
            <div className="mt-2 text-sm leading-relaxed text-beauBlue">
              ~ {Number(reward ?? 0).toLocaleString()} B3TR
            </div>
            <div className="text-xs leading-relaxed text-shadowBlue">
              {(1 / (ratio ? ratio / 100 : 1)).toLocaleString()} XP per 1 B3TR
            </div>
          </div>
          <div className="mt-10 min-h-[70vh] bg-raisinBlack pb-40 pt-10">
            <div className="mb-4 flex flex-row items-center justify-between px-8">
              <h1 className="text-2xl text-isabelline">Latest articles</h1>
              <NavLink
                className="text-sm text-beauBlue underline hover:text-isabelline active:text-isabelline active:text-opacity-60"
                to="/articles"
              >
                View all
              </NavLink>
            </div>
            {isLoadingArticles
              ? Array(10)
                  .fill(1)
                  .map((_, index) => (
                    <div
                      key={`loading-article-${index}`}
                      className={clsx(['flex flex-row items-center px-8 py-4'])}
                    >
                      <div className="flex-1">
                        <div className="h-4 w-full animate-pulse rounded-full bg-isabelline bg-opacity-30" />
                        <div className="mt-1 h-4 w-2/3 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
                        <div className="mt-2 h-3 w-full animate-pulse rounded-full bg-isabelline bg-opacity-30" />
                        <div className="mt-1 h-3 w-full animate-pulse rounded-full bg-isabelline bg-opacity-30" />
                        <div className="mt-1 h-3 w-1/3 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
                      </div>
                      <div className="ml-4 h-[80px] w-[80px] animate-pulse rounded-lg bg-isabelline bg-opacity-30" />
                    </div>
                  ))
              : articles?.map((article, index) => (
                  <NavLink
                    key={`article-${index}`}
                    to={article.url}
                    target="_blank"
                  >
                    <div
                      className={clsx([
                        'flex flex-row items-center px-8 py-4',
                        'hover:bg-isabelline hover:bg-opacity-10',
                        'active:bg-isabelline active:bg-opacity-5',
                      ])}
                    >
                      <div className="flex-1">
                        <div className="line-clamp-2 overflow-hidden text-ellipsis text-isabelline">
                          {article.title}
                        </div>
                        <div className="mt-2 line-clamp-2 flex-1 overflow-hidden text-ellipsis text-sm text-spanishGray">
                          {article.description}...
                        </div>
                      </div>
                      {article.image_url ? (
                        <Img
                          src={[article.image_url, '/logo512.png']}
                          className="ml-4 aspect-square h-[80px] w-[80px] rounded-lg object-cover"
                        />
                      ) : null}
                    </div>
                  </NavLink>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};
