import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import clsx from 'clsx';
import { Carousel } from 'react-responsive-carousel';
import { FilledButton } from '../components';
import { useDispatch, useSelector } from 'react-redux';
import { useWallet } from './WalletProvider';
import { updateAppState } from '../redux/app.slice';
import { logout } from '../redux/auth.slice';
import { apiGetChallengesSummary } from '../apis/lms';
import { apiGetProfile, apiRequestToken } from '../apis/auth';
import { IRootState } from '../redux/store';

import bg from '../assets/backgrounds/background2.png';
import logo from '../assets/logos/dark-brand.png';
import branding1 from '../assets/images/branding-guides-1.png';
import branding2 from '../assets/images/branding-guides-2.png';
import branding3 from '../assets/images/branding-guides-3.png';
import branding4 from '../assets/images/branding-guides-4.png';

export const BootstrapProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const { isFirstLaunch } = useSelector((state: IRootState) => state.app);
  const { address, chain } = useSelector((state: IRootState) => state.auth);
  const { disconnectWallet } = useWallet();

  const [step, setStep] = useState(0);

  const handleCompleteOnboardingGuides = useCallback(() => {
    dispatch(
      updateAppState({
        isFirstLaunch: false,
      }),
    );
  }, []);

  const handleContinue = useCallback(() => {
    if (step < 3) {
      setStep(step + 1);
    } else {
      handleCompleteOnboardingGuides();
    }
  }, [step]);

  const getBrandingImage = useCallback(() => {
    if (step === 0) {
      return branding1;
    } else if (step === 1) {
      return branding2;
    } else if (step === 2) {
      return branding3;
    } else if (step === 3) {
      return branding4;
    }

    return branding1;
  }, [step]);

  const getButtonText = useCallback(() => {
    if (step === 0) {
      return 'I want in!';
    } else if (step === 1) {
      return "Let's do it!";
    } else if (step === 2) {
      return "Let's Go!";
    } else if (step === 3) {
      return "I'm ready!";
    }

    return 'Continue';
  }, [step]);

  useEffect(() => {
    dispatch(
      updateAppState({
        isAppReady: false,
      }),
    );

    (async () => {
      await apiGetChallengesSummary();

      if (address && chain) {
        const result = await apiRequestToken({
          chain: chain,
          wallet_address: address,
        });
        if (result === 'verified') {
          await apiGetProfile({
            need_balance: true,
            need_ratio: true,
          });
        } else {
          disconnectWallet();
          dispatch(logout());
        }
      } else {
        disconnectWallet();
        dispatch(logout());
      }

      setTimeout(() => {
        const el = document.getElementById('loader');
        if (el) {
          el.remove();
        }
      }, 1500);

      dispatch(
        updateAppState({
          isAppReady: true,
        }),
      );
    })();
  }, []);

  return (
    <>
      <>{children}</>
      {isFirstLaunch && (
        <div
          className="fixed bottom-0 left-0 right-0 top-0 z-[999] flex flex-col overflow-hidden"
          style={{
            background: `url(${bg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="flex flex-row items-center justify-between p-8">
            <img src={logo} alt="Logo" width={106} height={32} />
            <button
              className="text-raisinBlack hover:underline"
              onClick={handleCompleteOnboardingGuides}
            >
              Skip
            </button>
          </div>
          <div className="flex flex-1 flex-col">
            <div className="flex flex-1 flex-row justify-center py-8">
              <div className="flex w-full max-w-[600px] flex-row justify-center overflow-hidden">
                <img
                  src={getBrandingImage()}
                  className={clsx({
                    'h-full transition-all': 1,
                    'translate-x-1/2': step === 0,
                    'translate-x-1/4': step === 1,
                    '-translate-x-1/4': step === 2,
                    '-translate-x-1/2': step === 3,
                  })}
                />
              </div>
            </div>
            <div className="flex-1 pt-8">
              <Carousel
                emulateTouch
                showArrows={false}
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
                selectedItem={step}
                onChange={(index) => setStep(index)}
              >
                <div className="flex flex-row justify-center text-raisinBlack">
                  <div className="w-full max-w-[600px]">
                    <h1 className="px-8 text-left text-3xl leading-normal">
                      Hey, there, I&apos;m Oily!
                      <br />
                      Ready to make a difference and be rewarded?
                    </h1>
                    <div className="mr-8 mt-8 px-8 text-right">
                      Be part of the solution!
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-center text-raisinBlack">
                  <div className="w-full max-w-[600px]">
                    <h1 className="px-8 text-left text-3xl leading-normal">
                      Learn about
                      <br />
                      Blockchain, Vechain
                      <br />
                      and UCO.Network!
                    </h1>
                    <div className="mr-8 mt-8 px-8 pl-[30%] text-right leading-normal">
                      Learn while you earn!
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-center text-raisinBlack">
                  <div className="w-full max-w-[600px]">
                    <h1 className="px-8 text-left text-3xl leading-normal">
                      Help us map oil producing places around the world!
                    </h1>
                    <div className="mr-8 mt-8 px-8 pl-[30%] text-right leading-normal">
                      And get rewards for it!
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-center text-raisinBlack">
                  <div className="w-full max-w-[600px]">
                    <h1 className="px-8 text-left text-3xl leading-normal">
                      Join UCO Network in creating a sustainable tomorrow for
                      all of us!
                    </h1>
                    <div className="mr-8 mt-8 px-8 pl-[30%] text-right leading-normal">
                      Be part of the new economy!
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center px-8 pb-8">
            <div className="my-8">
              <div className="flex flex-row justify-center">
                {[0, 1, 2, 3].map((index) => (
                  <div
                    key={index}
                    className={clsx({
                      'mx-1 h-2 rounded-full transition-all': 1,
                      'w-2 bg-shadowBlue': step !== index,
                      'w-5 bg-royalBlueDark': step === index,
                    })}
                  />
                ))}
              </div>
            </div>
            <FilledButton
              size="large"
              className="w-[140px]"
              onClick={handleContinue}
            >
              {getButtonText()}
            </FilledButton>
          </div>
        </div>
      )}
    </>
  );
};
