import React, { useCallback } from 'react';
import clsx from 'clsx';
import { SecondaryLayout, XPIndicator } from '../../components';
import { XIcon } from 'lucide-react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IRootState } from '../../redux/store';

const MenuButton: React.FC<{
  title: string;
  subtitle: string;
  xp: number;
  to: string;
}> = ({ title, subtitle, xp, to }) => (
  <NavLink to={to} className="mt-8 w-full">
    <button
      className={clsx([
        'w-full px-4 py-3',
        'rounded-2xl bg-shadowBlue bg-opacity-20',
        'hover:bg-opacity-30 focus:bg-opacity-15 active:bg-opacity-15',
        'flex flex-col items-start',
      ])}
    >
      <h2 className="text-2xl leading-normal text-white">{title}</h2>
      <p className="leading-relaxed text-beauBlue">{subtitle}</p>
      <div className="mt-2">
        <XPIndicator current={xp} textClassName="text-spanishGray" />
      </div>
    </button>
  </NavLink>
);

export const MappingAddOptionsPage: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const { m2eCreateWmoXp, m2eCreatePlaceXp } = useSelector(
    (state: IRootState) => state.app,
  );

  const handleBack = useCallback(() => {
    onClose();
  }, []);

  return (
    <SecondaryLayout>
      <SecondaryLayout.Header
        hideStartIcon
        endIcon={XIcon}
        onEndIconClick={handleBack}
      />
      <SecondaryLayout.Content>
        <div className="px-6 pt-40">
          <MenuButton
            title="Add a Restaurant to Map"
            subtitle="Help us map the unmapped restaurants"
            xp={m2eCreatePlaceXp ?? 0}
            to="/mappings/add/restaurant"
          />
          <div className="h-2" />
          <MenuButton
            title="Add a WMO"
            subtitle="Give us a direct contact of a WMO"
            xp={m2eCreateWmoXp ?? 0}
            to="/mappings/add/wmo"
          />
        </div>
      </SecondaryLayout.Content>
    </SecondaryLayout>
  );
};
