import React from 'react';
import clsx from 'clsx';

interface IIconButtonProps {
  icon: React.FC<any>;
  variant?: 'ghost' | 'ghost-dark' | 'dark';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  onClick?: () => void;
}

export const IconButton: React.FC<IIconButtonProps> = ({
  icon: Icon,
  variant = 'ghost',
  size = 'medium',
  className,
  onClick,
}) => {
  return (
    <button
      className={clsx({
        'flex flex-row items-center justify-center rounded-full': true,
        'h-[40px] w-[40px]': size === 'medium',
        'h-[28px] w-[28px]': size === 'small',

        'bg-transparent text-white hover:bg-white hover:bg-opacity-15 active:bg-white active:bg-opacity-10':
          variant === 'ghost',
        'bg-transparent text-raisinBlack hover:bg-raisinBlack hover:bg-opacity-15 active:bg-raisinBlack active:bg-opacity-10':
          variant === 'ghost-dark',
        'bg-raisinBlack hover:bg-raisinBlackHover active:bg-raisinBlackActive disabled:bg-raisinBlackDisabled':
          variant === 'dark',

        ...(className && {
          [className]: true,
        }),
      })}
      onClick={onClick}
    >
      <Icon size={size === 'small' ? 16 : 24} />
    </button>
  );
};
