import React from 'react';
import { Oval } from 'react-loader-spinner';
import { colors } from '../../config/theme';

interface SpinnerProps {
  size?: number | 'small' | 'medium' | 'large';
  variant?: 'dark' | 'icterine';
}

const getSize = (size: any) => {
  if (size === 'small') {
    return 16;
  }
  if (size === 'medium') {
    return 24;
  }
  if (size === 'large') {
    return 32;
  }

  if (size && typeof size === 'number') {
    return size;
  }

  return 24;
};

export const Spinner: React.FC<SpinnerProps> = ({
  size = 24,
  variant = 'dark',
}) => {
  return (
    <Oval
      width={getSize(size)}
      height={getSize(size)}
      color={variant === 'dark' ? colors.raisinBlack : colors.icterine}
      secondaryColor={variant === 'dark' ? colors.raisinBlack : colors.icterine}
      strokeWidth={4}
    />
  );
};
