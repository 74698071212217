import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  XPIndicator,
  CircularProgressIndicator,
  AlertDialog,
} from '../../components';
import { CheckIcon, LockIcon, PlayIcon } from 'lucide-react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { IRootState } from '../../redux/store';
import { apiGetChapters, apiGetLessons } from '../../apis/lms';
import { IChapterItem, ILessonItem } from '../../apis/types';

import background from '../../assets/backgrounds/background3.png';

export const LessonsPage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const segments = params.segments;
  if (
    !segments ||
    !segments.includes('-') ||
    segments.split('-').length !== 2
  ) {
    return <Navigate to="/challenges" />;
  }

  const [chapter, setChapter] = useState<IChapterItem>();
  const [chapterOrder, chapterId] = segments.split('-');
  const [summary, setSummary] = useState({
    totalXp: 0,
    totalLessons: 0,
    currentXp: 0,
  });
  const { isLoggedIn, isVerified, isProfileCompleted } = useSelector(
    (state: IRootState) => state.auth,
  );

  const { data: lessons, isLoading: isLoadingLessons } = useQuery({
    queryKey: ['lms', 'chapters', chapterId, 'lessons'],
    queryFn: async () => {
      return await apiGetLessons(chapterId);
    },
    enabled: isVerified && isLoggedIn && isProfileCompleted,
  });
  const { data: chapters, isLoading: isLoadingChapters } = useQuery({
    queryKey: ['lms', 'chapters'],
    queryFn: async () => {
      return await apiGetChapters();
    },
    enabled: isVerified && isLoggedIn && isProfileCompleted,
  });

  const handlePlay = (lesson: ILessonItem) => {
    if (lesson.locked) {
      AlertDialog.open({
        title: 'Locked lesson',
        message: 'Complete the previous lesson to unlock.',
        canDismiss: true,
        confirmLabel: 'Okay, I understand',
      });
      return;
    }

    navigate(`${lesson._id}/contents`);
  };

  useEffect(() => {
    if (lessons) {
      let totalXp = 0;
      let currentXp = 0;
      for (const lesson of lessons) {
        totalXp += lesson.total_xp;
        currentXp += lesson.user_xp;
      }
      setSummary({
        totalXp: totalXp,
        totalLessons: lessons.length,
        currentXp,
      });
    }
  }, [lessons]);

  useEffect(() => {
    if (chapters) {
      for (const item of chapters) {
        if (item._id === chapterId) {
          setChapter(item);
          if (!item.released || item.locked) {
            return navigate(-1);
          }
          break;
        }
      }
    }
  }, [chapterId, chapters]);

  return (
    <div>
      <div className="flex flex-row justify-center px-8 pt-20">
        <div className="w-full max-w-[600px]">
          <div className={clsx(['fixed left-0 right-0 top-0 -z-10'])}>
            <img src={background} className="h-full w-full" />
          </div>
          <div className="mb-20">
            <h6 className={clsx(['text-sm', 'text-isabelline'])}>
              Challenge {chapterOrder}
            </h6>
            <div className="mt-1 flex flex-row items-start justify-between">
              {isLoadingChapters ? (
                <div className="h-9 w-1/2 animate-pulse rounded-full bg-isabelline bg-opacity-30" />
              ) : (
                <h2 className={clsx(['flex-1 text-3xl text-white'])}>
                  {chapter?.title}
                </h2>
              )}
              <button className="relative ml-4 flex h-[40px] w-[40px] items-center justify-center">
                <CircularProgressIndicator
                  value={chapter ? chapter.progress : 0}
                  size="medium"
                  variant="light"
                  className="absolute left-0 top-0"
                />
                {chapter && chapter.progress >= 100 ? (
                  <CheckIcon className="h-5 w-5 text-icterine" />
                ) : null}
              </button>
            </div>
            <div className="mt-4 flex flex-row items-center">
              {isLoadingLessons ? (
                <div className="h-4 w-32 animate-pulse rounded-full bg-isabelline bg-opacity-25" />
              ) : (
                <>
                  <span className={clsx(['mr-4 text-beauBlue'])}>
                    {summary.totalLessons} Lessons
                  </span>
                  <XPIndicator
                    current={summary.currentXp.toFixed(0)}
                    total={summary.totalXp.toFixed(0)}
                    textClassName={'text-beauBlue'}
                  />
                </>
              )}
            </div>
          </div>
          {isLoadingLessons
            ? Array(5)
                .fill(true)
                .map((_, index) => (
                  <div
                    key={`loading-lesson-${index}`}
                    className={clsx({
                      'mb-[56px] flex flex-row items-center gap-12': 1,
                    })}
                  >
                    <button className="relative h-[72px] w-[72px] transition-transform hover:scale-110 active:scale-95">
                      <div className="absolute bottom-0 left-0 right-0 top-0 scale-125 rounded-full bg-white bg-opacity-15" />
                      <CircularProgressIndicator
                        value={0}
                        size="large"
                        variant="dark"
                        className="absolute left-0 top-0"
                      />
                      <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center" />
                    </button>
                    <div className="flex-1 animate-pulse">
                      <div className="h-4 w-32 rounded-full bg-isabelline bg-opacity-25" />
                      <div className="mt-1 h-5 w-full rounded-full bg-isabelline bg-opacity-25" />
                      <div className="mt-1 h-4 w-16 rounded-full bg-isabelline bg-opacity-25" />
                    </div>
                  </div>
                ))
            : lessons?.map((lesson, index) => (
                <div
                  key={`lesson-${lesson._id}`}
                  className={clsx({
                    'mb-[56px] flex flex-row items-center gap-12': 1,
                  })}
                >
                  <button
                    className="relative h-[72px] w-[72px] transition-transform hover:scale-110 active:scale-95"
                    onClick={() => handlePlay(lesson)}
                  >
                    <div className="absolute bottom-0 left-0 right-0 top-0 scale-125 rounded-full bg-white bg-opacity-15" />
                    {lesson.progress >= 100 ? (
                      <div className="absolute left-0 top-0 h-full w-full rounded-full border-2 border-dashed border-icterine" />
                    ) : (
                      <CircularProgressIndicator
                        value={lesson.progress}
                        size="large"
                        variant="dark"
                        className="absolute left-0 top-0"
                      />
                    )}
                    <div
                      className={clsx([
                        'absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center',
                        !lesson.locked && 'drop-shadow-active-icon',
                      ])}
                    >
                      {!lesson.locked ? (
                        lesson.progress >= 100 ? (
                          <CheckIcon className="text-icterine" />
                        ) : (
                          <PlayIcon className="text-icterine" />
                        )
                      ) : (
                        <LockIcon className="text-shadowBlue" />
                      )}
                    </div>
                  </button>
                  <div className="flex-1">
                    <h6
                      className={clsx({
                        'text-sm': 1,
                        'text-icterine': !lesson.locked,
                        'text-shadowBlue': lesson.locked,
                      })}
                    >
                      Lesson {index + 1} -{' '}
                      {lesson.progress >= 100
                        ? 'Completed'
                        : lesson.progress === 0
                          ? 'Get started'
                          : 'On going'}
                    </h6>
                    <h5
                      className={clsx({
                        'mt-1 font-semibold': 1,
                        'text-white': !lesson.locked,
                        'text-shadowBlue': lesson.locked,
                      })}
                    >
                      {lesson.title}
                    </h5>
                    {!lesson.locked && (
                      <div className="mt-4 flex flex-row">
                        <XPIndicator
                          current={lesson.user_xp.toFixed(0)}
                          total={lesson.total_xp.toFixed(0)}
                          textClassName="text-shadowBlue"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}

          <div className="h-[200px]" />
        </div>
      </div>
    </div>
  );
};
