import React, { useCallback, useState } from 'react';
import {
  AlertDialog,
  DailyWmoLimitDialog,
  FilledButton,
  IconButton,
  OutlinedButton,
  RadioGroup,
  SecondaryLayout,
  StandardInput,
} from '../../components';
import { ArrowLeftIcon, InfoIcon, XIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { apiAddWmo } from '../../apis/mapping';

const scheme = yup.object({
  type: yup.string().required('Please select a type'),
  collector_name: yup.string().required('Please fill collector name'),
  collector_email: yup
    .string()
    .email('Please fill valid email address')
    .required('Please fill collector email address'),
  collector_phone_number: yup
    .string()
    .required('Please fill collector phone number'),
  count_vehicles: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue === '' || Number.isNaN(Number(originalValue))
        ? 0
        : value;
    })
    .min(1, 'Please fill count of vehicles')
    .required('Please fill count of vehicles'),
});

interface IAddWmoFormData {
  type: string;
  collector_name: string;
  collector_email: string;
  collector_phone_number: string;
  count_vehicles: number;
}

export const MappingAddWmoPage: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<IAddWmoFormData>({
    defaultValues: {
      type: 'company',
      collector_name: '',
      collector_email: '',
      collector_phone_number: '',
      count_vehicles: 0,
    },
    resolver: yupResolver(scheme),
  });

  const handleBack = useCallback(() => {
    navigate('/mappings');
  }, []);

  const handleClose = useCallback(() => {
    navigate('/mappings');
  }, []);

  const handleShowWMOGuide = useCallback(() => {
    AlertDialog.open({
      title: 'What is a Waste Management Organization?',
      message:
        'Waste Management Organizations (WMO), are individuals or entities responsible for the systematic collection, transportation, processing, recycling, or disposal of waste materials.',
      canDismiss: true,
      confirmLabel: 'Close',
      confirmStyle: 'outlined',
    });
  }, []);

  const handleSave = useCallback(async (data: IAddWmoFormData) => {
    setLoading(true);
    const result = await apiAddWmo(data);
    setLoading(false);
    if (result) {
      if (result === 'DAILY_LIMIT_REACHED') {
        DailyWmoLimitDialog.open();
        navigate('/mappings');
      } else {
        navigate('/mappings');
      }
    }
  }, []);

  return (
    <SecondaryLayout>
      <SecondaryLayout.Header
        title="Add a WMO"
        startIcon={ArrowLeftIcon}
        endIcon={XIcon}
        onStartIconClick={handleBack}
        onEndIconClick={handleClose}
      />
      <SecondaryLayout.Content>
        <div className="flex min-h-[100vh] flex-col px-8 pb-8 pt-36">
          <div className="flex flex-row">
            <h1 className="flex-1 text-3xl text-isabelline">
              Waste Management Organization (WMO)
            </h1>
            <IconButton
              icon={InfoIcon}
              variant="ghost"
              size="small"
              onClick={handleShowWMOGuide}
            />
          </div>
          <div className="mb-8 flex-1">
            <Controller
              control={control}
              name="type"
              render={({ field: { value, onChange } }) => (
                <div className="mt-12">
                  <RadioGroup
                    items={[
                      { label: 'Is it a company?', value: 'company' },
                      { label: 'Is it an individual?', value: 'individual' },
                    ]}
                    value={value}
                    onChange={onChange}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="collector_name"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <div className="mt-8">
                  <div className="mb-2 text-xs text-isabelline">
                    COLLECTOR&apos;S NAME <span className="text-red">*</span>
                  </div>
                  <StandardInput
                    type="text"
                    variant="gray"
                    placeholder="E.g.: John Smith"
                    error={!!error?.message}
                    helperText={error?.message}
                    value={value}
                    onChange={onChange}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="collector_email"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <div className="mt-8">
                  <div className="mb-2 text-xs text-isabelline">
                    COLLECTOR&apos;S EMAIL ADDRESS{' '}
                    <span className="text-red">*</span>
                  </div>
                  <StandardInput
                    type="text"
                    variant="gray"
                    placeholder="E.g.: email@domain.com"
                    error={!!error?.message}
                    helperText={error?.message}
                    value={value}
                    onChange={onChange}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="collector_phone_number"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <div className="mt-8">
                  <div className="mb-2 text-xs text-isabelline">
                    COLLECTOR&apos;S PHONE NUMBER{' '}
                    <span className="text-red">*</span>
                  </div>
                  <StandardInput
                    type="tel"
                    variant="gray"
                    placeholder="E.g.: +351 555 258 856"
                    error={!!error?.message}
                    helperText={error?.message}
                    value={value}
                    onChange={onChange}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="count_vehicles"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <div className="mt-8">
                  <div className="mb-2 text-xs text-isabelline">
                    HOW MANY VEHICLES IN THE FLEET?{' '}
                    <span className="text-red">*</span>
                  </div>
                  <StandardInput
                    type="text"
                    variant="gray"
                    placeholder="E.g.: 5"
                    error={!!error?.message}
                    helperText={error?.message}
                    value={value ? value.toString() : ''}
                    onChange={onChange}
                  />
                </div>
              )}
            />
          </div>
          <div className="flex flex-row items-center justify-between">
            <OutlinedButton size="large" variant="light" className="flex-1">
              Close
            </OutlinedButton>
            <FilledButton
              size="large"
              variant="light"
              loading={loading}
              className="ml-4 flex-[3]"
              onClick={handleSubmit(handleSave)}
            >
              Save
            </FilledButton>
          </div>
        </div>
      </SecondaryLayout.Content>
    </SecondaryLayout>
  );
};
