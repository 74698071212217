import React, { useState } from 'react';
import clsx from 'clsx';
import {
  AlertDialog,
  CompleteProfileDialog,
  FilledButton,
  OutlinedButton,
  ProfileLayout,
  StandardInput,
} from '../../components';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { IRootState } from '../../redux/store';
import { apiUpdateProfile } from '../../apis/auth';

interface IZealyIdProfileData {
  zealy_id?: string;
}

const schema = yup
  .object({
    zealy_id: yup.string().optional(),
  })
  .required();

export const EditZealyIdProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const auth = useSelector((state: IRootState) => state.auth);

  const [loading, setLoading] = useState(false);

  const { handleSubmit, control } = useForm<IZealyIdProfileData>({
    defaultValues: {
      zealy_id: auth.zealyId ?? '',
    },
    resolver: yupResolver(schema),
  });

  const handleUpdateProfile = async (values: IZealyIdProfileData) => {
    setLoading(true);
    const result = await apiUpdateProfile({
      ...values,
    });
    if (result) {
      CompleteProfileDialog.open({
        amount: result,
        onProfile: () => {
          navigate('/profile');
        },
        onChallenge: () => {
          navigate('/challenges/learn/chapters');
        },
      });
    } else {
      AlertDialog.open({
        message: 'Updated the behavior info successfully.',
        confirmLabel: 'Confirm',
        onConfirm: () => {
          navigate(-1);
        },
      });
    }
    setLoading(false);
  };

  const handleCancel = () => {
    navigate('/profile');
  };

  return (
    <ProfileLayout title="Zealy ID" style="light" showBackButton>
      <div className="flex flex-row justify-center">
        <div className="flex w-full max-w-[600px] flex-col px-8">
          <h1 className="mt-12 text-3xl font-semibold text-raisinBlack">
            Zealy Id
          </h1>
          <Controller
            control={control}
            name="zealy_id"
            render={({ field, fieldState: { error } }) => (
              <div className="mt-8">
                <div
                  className={clsx([
                    'text-xs',
                    error ? 'text-red' : 'text-raisinBlack',
                  ])}
                >
                  ID
                </div>
                <StandardInput
                  variant="beauBlue"
                  placeholder="E.g.: 8e28623f-0769-50f-ac38-b6a959de4a85"
                  error={!!error}
                  helperText={error ? error.message : ''}
                  value={field.value}
                  onChangeText={field.onChange}
                />
              </div>
            )}
          />
          <div className="h-40" />
          <div className="fixed bottom-0 left-0 right-0 flex flex-row justify-center">
            <div className="flex w-full max-w-[600px] flex-row gap-4 bg-isabelline px-8 pb-8 pt-4">
              <OutlinedButton
                variant="dark"
                size="large"
                className="flex-1"
                onClick={handleCancel}
              >
                Cancel
              </OutlinedButton>
              <FilledButton
                variant="dark"
                size="large"
                className="flex-[2]"
                loading={loading}
                onClick={handleSubmit(handleUpdateProfile)}
              >
                Save
              </FilledButton>
            </div>
          </div>
        </div>
      </div>
    </ProfileLayout>
  );
};
