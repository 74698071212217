import React from 'react';
import clsx from 'clsx';

interface ISwitchProps {
  checked: boolean;
  className?: string;
  onChange: (arg: boolean) => void;
}

export const Switch: React.FC<ISwitchProps> = ({
  checked,
  className,
  onChange,
}) => {
  return (
    <button
      className={clsx([
        'inline-block',
        'relative h-[24px] w-[56px] rounded-full transition-all ease-in-out',
        'cursor-pointer',
        checked ? 'bg-icterine' : 'bg-charcoal',
        className ?? '',
      ])}
      onClick={onChange ? () => onChange(!checked) : undefined}
    >
      <div
        className={clsx([
          'absolute rounded-full transition-all ease-in-out',
          'h-[20px] w-[20px]',
          'left-0 top-1/2 -translate-y-1/2',
          checked ? 'bg-raisinBlack' : 'bg-isabelline',
          checked ? ' translate-x-[32px]' : 'translate-x-[2px]',
        ])}
      />
      <div
        className={clsx([
          'absolute transition-all ease-in-out',
          'left-1.5 top-1/2 -translate-y-1/2 text-sm font-bold',
          'text-raisinBlack',
          checked ? 'opacity-100' : 'opacity-0',
        ])}
      >
        Yes
      </div>
      <div
        className={clsx([
          'absolute transition-all ease-in-out',
          'right-1.5 top-1/2 -translate-y-1/2 text-sm font-bold',
          'text-spanishGray',
          checked ? 'opacity-0' : 'opacity-100',
        ])}
      >
        Off
      </div>
    </button>
  );
};
