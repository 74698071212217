import React, { useCallback, useEffect, useState } from 'react';
import { Portal } from 'react-portal';
import {
  Switch,
  FilledButton,
  OutlinedButton,
  StandardInput,
} from '../../components';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { IRootState } from '../../redux/store';
import { updatePlace } from '../../redux/places.slice';

interface IGeneralFormData {
  contact_name: string;
  contact_email: string;
  contact_phone_number: string;
  charge_name: string;
  charge_email: string;
  spoken_language: string;
}

const schema = yup
  .object({
    contact_name: yup.string().required('Please fill contact name'),
    contact_email: yup
      .string()
      .email('Please fill valid email address')
      .required(),
    contact_phone_number: yup
      .string()
      .matches(/^\+?[0-9]+$/, 'Please enter a valid phone number')
      .required('Please fill contact phone number'),
    charge_name: yup.string().required('Please fill person name'),
    charge_email: yup
      .string()
      .email('Please fill valid email address')
      .required('Please fill email address'),
    spoken_language: yup.string().required('Please fill spoken language'),
  })
  .required();

export const MappingPlaceGeneralDataPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const data = useSelector((state: IRootState) =>
    id ? state.places.places[id] : null,
  );

  const { reset, control, handleSubmit, setValue, watch } =
    useForm<IGeneralFormData>({
      defaultValues: {
        contact_name: '',
        contact_email: '',
        contact_phone_number: '',
        charge_email: '',
        charge_name: '',
        spoken_language: '',
      },
      resolver: yupResolver(schema),
    });

  const [isSameContactPerson, setIsSameContactPerson] = useState(true);
  const [isEnglishSpeaker, setIsEnglishSpeaker] = useState(true);
  const [contact_name, contact_email] = watch([
    'contact_name',
    'contact_email',
  ]);

  const handleDiscard = useCallback(() => {
    navigate(-1);
  }, []);

  const handleSave = useCallback(
    (values: IGeneralFormData) => {
      if (!id) {
        return;
      }

      dispatch(
        updatePlace({
          id: id,
          general: {
            contact_name: values.contact_name,
            contact_email: values.contact_email,
            contact_phone_number: values.contact_phone_number,
            charge_name: values.charge_name,
            charge_email: values.charge_email,
            spoken_language: values.spoken_language,
          },
        }),
      );
      navigate(-1);
    },
    [id],
  );

  useEffect(() => {
    if (!id) {
      navigate('/mappings');
      return;
    }
    if (data) {
      reset({
        contact_name: data.general?.contact_name ?? '',
        contact_email: data.general?.contact_email ?? '',
        contact_phone_number: data.general?.contact_phone_number ?? '',
        charge_name: data.general?.charge_name ?? '',
        charge_email: data.general?.charge_email ?? '',
        spoken_language: data.general?.spoken_language ?? '',
      });
      if (
        data.general?.contact_name &&
        data.general.contact_name === data.general?.charge_name
      ) {
        if (
          data.general?.contact_email &&
          data.general.contact_email === data.general?.charge_email
        ) {
          setIsSameContactPerson(true);
        }
      }
      if (data.general?.spoken_language) {
        if (data.general.spoken_language === 'English') {
          setIsEnglishSpeaker(true);
        } else {
          setIsEnglishSpeaker(false);
        }
      }
    } else {
      navigate('/mappings');
    }
  }, [data, id]);

  useEffect(() => {
    if (isSameContactPerson) {
      setValue('charge_name', contact_name);
      setValue('charge_email', contact_email);
    } else {
      setValue('charge_name', '');
      setValue('charge_email', '');
    }
  }, [isSameContactPerson, contact_name, contact_email]);

  useEffect(() => {
    if (isEnglishSpeaker) {
      setValue('spoken_language', 'English');
    } else {
      setValue('spoken_language', '');
    }
  }, [isEnglishSpeaker]);

  return (
    <div className="px-6 pt-24">
      <div>
        <h1 className="text-3xl text-isabelline">Contact</h1>
        <Controller
          name="contact_name"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className="mt-8">
              <div className="mb-2 text-xs text-isabelline">CONTACT PERSON</div>
              <StandardInput
                type="text"
                variant="gray"
                placeholder="E.g.: John Smith"
                error={!!error?.message}
                helperText={error?.message}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          name="contact_email"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className="mt-8">
              <div className="mb-2 text-xs text-isabelline">
                RESTAURANT EMAIL ADDRESS
              </div>
              <StandardInput
                type="text"
                variant="gray"
                placeholder="E.g.: hello@rest.com"
                error={!!error?.message}
                helperText={error?.message}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          name="contact_phone_number"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className="mt-8">
              <div className="mb-2 text-xs text-isabelline">
                RESTAURANT PHONE NUMBER
              </div>
              <StandardInput
                type="text"
                variant="gray"
                placeholder="E.g.: +18812458478"
                error={!!error?.message}
                helperText={error?.message}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
      </div>
      <div className="mt-12">
        <h1 className="text-3xl text-isabelline">Person in charge</h1>
        <div className="mt-8 flex flex-row items-center justify-between">
          <div className="text-lg">Same as contact person?</div>
          <Switch
            checked={isSameContactPerson}
            onChange={(value) => setIsSameContactPerson(value)}
          />
        </div>
        {isSameContactPerson ? null : (
          <>
            <Controller
              name="charge_name"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <div className="mt-8">
                  <div className="mb-2 text-xs text-isabelline">
                    PERSON IN CHARGE
                  </div>
                  <StandardInput
                    type="text"
                    variant="gray"
                    placeholder="E.g.: John Smith"
                    error={!!error?.message}
                    helperText={error?.message}
                    value={value}
                    onChange={onChange}
                  />
                </div>
              )}
            />
            <Controller
              name="charge_email"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <div className="mt-8">
                  <div className="mb-2 text-xs text-isabelline">
                    EMAIL PERSON IN CHARGE
                  </div>
                  <StandardInput
                    type="text"
                    variant="gray"
                    placeholder="E.g.: hello@rest.com"
                    error={!!error?.message}
                    helperText={error?.message}
                    value={value}
                    onChange={onChange}
                  />
                </div>
              )}
            />
          </>
        )}
      </div>
      <div className="mt-12">
        <h1 className="text-3xl text-isabelline">Language</h1>
        <div className="mt-8 flex flex-row items-center justify-between">
          <div className="text-lg">English speaker?</div>
          <Switch
            checked={isEnglishSpeaker}
            onChange={(value) => setIsEnglishSpeaker(value)}
          />
        </div>
        {!isEnglishSpeaker && (
          <Controller
            name="spoken_language"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div className="mt-8">
                <div className="mb-2 text-xs text-isabelline">
                  SPOKEN LANGUAGE
                </div>
                <StandardInput
                  type="text"
                  variant="gray"
                  placeholder="E.g.: French, Italian"
                  error={!!error?.message}
                  helperText={error?.message}
                  value={value}
                  onChange={onChange}
                />
              </div>
            )}
          />
        )}
      </div>
      <div className="h-52" />
      <Portal
        node={document && document.getElementById('mapping-details-footer')}
      >
        <OutlinedButton
          size="large"
          variant="light"
          className="flex-1"
          onClick={handleDiscard}
        >
          Discard
        </OutlinedButton>
        <FilledButton
          variant="light"
          size="large"
          className="flex-[2]"
          onClick={handleSubmit(handleSave)}
        >
          Save
        </FilledButton>
      </Portal>
    </div>
  );
};
