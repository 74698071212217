import axios from 'axios';
import { IArticleItem } from './types';

export const apiGetArticles = async (params: {
  skip: number;
  limit: number;
}) => {
  try {
    const response = await axios.post<{
      message: string;
      data: IArticleItem[];
    }>('/api/client/v1/user/news', params);

    return response.data.data;
  } catch {
    return [];
  }
};
