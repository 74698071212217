import React, { useCallback } from 'react';
import clsx from 'clsx';
import { IconButton } from '../../buttons/IconButton';
import { XIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export const LeaderboardHeader: React.FC = () => {
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <div
      className={clsx([
        'absolute left-0 right-0 top-0 z-20 flex flex-row justify-center bg-raisinBlack px-4 py-6',
      ])}
    >
      <div className="flex w-full max-w-[600px] flex-row items-center justify-between">
        <IconButton
          variant="dark"
          size="small"
          icon={XIcon}
          onClick={handleBack}
        />
        <span className="text-sm text-white">Leaderboard</span>
        <div className="size-[28px]" />
      </div>
    </div>
  );
};
