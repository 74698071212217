import React, { useState } from 'react';
import clsx from 'clsx';
import {
  AlertDialog,
  FilledButton,
  OutlinedButton,
  ProfileLayout,
  StandardInput,
  StandardSelect,
} from '../../components';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCountryData } from 'countries-list';
import * as yup from 'yup';
import { IRootState } from '../../redux/store';
import { apiUpdateProfile } from '../../apis/auth';

interface IGeneralProfileData {
  username: string;
  age_gap: string;
  gender: string;
  country: string;
  city: string;
}

const schema = yup
  .object({
    username: yup.string().required('Please fill your unique username'),
    age_gap: yup.string().required('Please select your age'),
    gender: yup.string().required('Please select your gender'),
    country: yup.string().required('Please select your country'),
    city: yup.string().required('Please type your city'),
  })
  .required();

export const EditGeneralProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state: IRootState) => state.auth);

  const [loading, setLoading] = useState(false);
  const { handleSubmit, control, getValues } = useForm<IGeneralProfileData>({
    defaultValues: {
      username: location.state?.username ?? auth.username ?? '',
      age_gap: location.state?.age_gap ?? auth.age ?? '18_35',
      gender: location.state?.gender ?? auth.gender ?? 'male',
      city: location.state?.city ?? auth.city ?? '',
      country: location.state?.country ?? auth.country ?? '',
    },
    resolver: yupResolver(schema),
  });

  const handleUpdateProfile = async (values: IGeneralProfileData) => {
    setLoading(true);
    const result = await apiUpdateProfile({
      ...values,
    });
    if (result === false) {
      return;
    } else {
      AlertDialog.open({
        message: 'Updated the behavior info successfully.',
        confirmLabel: 'Confirm',
        onConfirm: () => {
          navigate(-1);
        },
      });
    }
    setLoading(false);
  };

  const handleCancel = () => {
    navigate('/profile');
  };

  const handlePickCountry = () => {
    navigate('/profile/general/country', {
      state: getValues(),
      replace: true,
    });
  };

  return (
    <ProfileLayout title="Profile" style="light" showBackButton>
      <div className="flex flex-row justify-center">
        <div className="flex w-full max-w-[600px] flex-col px-8">
          <h1 className="mt-12 text-3xl font-semibold text-raisinBlack">
            Identification
          </h1>
          <Controller
            control={control}
            name="username"
            render={({ field, fieldState: { error } }) => (
              <div className="mt-8">
                <div
                  className={clsx([
                    'text-xs',
                    error ? 'text-red' : 'text-raisinBlack',
                  ])}
                >
                  NAME
                </div>
                <StandardInput
                  variant="beauBlue"
                  placeholder="John Smith"
                  error={!!error}
                  helperText={error ? error.message : ''}
                  value={field.value}
                  onChangeText={field.onChange}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="age_gap"
            render={({ field, fieldState: { error } }) => (
              <div className="mt-8">
                <div
                  className={clsx([
                    'text-xs',
                    error ? 'text-red' : 'text-raisinBlack',
                  ])}
                >
                  AGE
                </div>
                <StandardSelect
                  type="text"
                  variant="beauBlue"
                  placeholder="Select your age"
                  error={!!error?.message}
                  helperText={error?.message}
                  value={field.value}
                  onChange={field.onChange}
                >
                  <option value="18_35">18 to 35 years old</option>
                  <option value="35_60">35 to 60 years old</option>
                  <option value="gt_60">more than 60 years old</option>
                </StandardSelect>
              </div>
            )}
          />
          <Controller
            control={control}
            name="gender"
            render={({ field, fieldState: { error } }) => (
              <div className="mt-8">
                <div
                  className={clsx([
                    'text-xs',
                    error ? 'text-red' : 'text-raisinBlack',
                  ])}
                >
                  GENDER
                </div>
                <StandardSelect
                  type="text"
                  variant="beauBlue"
                  placeholder="Select your gender"
                  error={!!error?.message}
                  helperText={error?.message}
                  value={field.value}
                  onChange={field.onChange}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </StandardSelect>
              </div>
            )}
          />
          <h1 className="mt-12 text-3xl font-semibold text-raisinBlack">
            Location
          </h1>
          <Controller
            control={control}
            name="city"
            render={({ field, fieldState: { error } }) => (
              <div className="mt-8">
                <div
                  className={clsx([
                    'text-xs',
                    error ? 'text-red' : 'text-raisinBlack',
                  ])}
                >
                  CITY
                </div>
                <StandardInput
                  variant="beauBlue"
                  placeholder="New York"
                  error={!!error}
                  helperText={error ? error.message : ''}
                  value={field.value}
                  onChangeText={field.onChange}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="country"
            render={({ field, fieldState: { error } }) => (
              <div className="mt-8">
                <div
                  className={clsx([
                    'text-xs',
                    error ? 'text-red' : 'text-raisinBlack',
                  ])}
                >
                  COUNTRY
                </div>
                <StandardInput
                  variant="beauBlue"
                  placeholder="Select country"
                  error={!!error}
                  helperText={error ? error.message : ''}
                  value={
                    field.value ? getCountryData(field.value as any).name : ''
                  }
                  onChangeText={field.onChange}
                  readonly
                  onClick={handlePickCountry}
                />
              </div>
            )}
          />
          <div className="h-40" />
          <div className="fixed bottom-0 left-0 right-0 flex flex-row justify-center">
            <div className="flex w-full max-w-[600px] flex-row gap-4 bg-isabelline px-8 pb-8 pt-4">
              <OutlinedButton
                variant="dark"
                size="large"
                className="flex-1"
                onClick={handleCancel}
              >
                Cancel
              </OutlinedButton>
              <FilledButton
                variant="dark"
                size="large"
                className="flex-[2]"
                loading={loading}
                onClick={handleSubmit(handleUpdateProfile)}
              >
                Save
              </FilledButton>
            </div>
          </div>
        </div>
      </div>
    </ProfileLayout>
  );
};
