import React, { useState } from 'react';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import Scrollbars from 'react-custom-scrollbars-2';
import {
  CheckIcon,
  CloudUploadIcon,
  CopyIcon,
  EditIcon,
  ImageIcon,
  InfoIcon,
  Link2OffIcon,
} from 'lucide-react';
import {
  AlertDialog,
  FilledButton,
  IconButton,
  ProfileLayout,
  XPIndicator,
} from '../../components';
import { getCountryData } from 'countries-list';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useWallet } from '../../providers/WalletProvider';
import { toast } from 'react-toastify';
import { IRootState } from '../../redux/store';
import { apiUploadFile } from '../../apis/files';
import { apiGetProfile, apiUpdateProfile } from '../../apis/auth';
import { logout } from '../../redux/auth.slice';

export const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { disconnectWallet } = useWallet();
  const {
    address,
    chain,
    username,
    country,
    avatar,
    referralCode,
    telegramId,
    age,
    gender,
    city,
    ucoProductionPeriod,
    ucoRecycling,
    ucoOilToCook,
    zealyId,
  } = useSelector((state: IRootState) => state.auth);

  const [uploading, setUploading] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDropAccepted: async (acceptedFiles) => {
      if (!acceptedFiles || acceptedFiles.length === 0) {
        return;
      }

      setUploading(true);
      const file = acceptedFiles[0];
      const urls = await apiUploadFile({
        key: 'avatars',
        files: [file],
      });
      if (urls) {
        const [url] = urls;
        const result = await apiUpdateProfile({
          profile_image: url,
        });
        if (result) {
          const updated = await apiGetProfile();
          setUploading(false);
          if (!updated) {
            toast('Failed to load your profile');
          }
        } else {
          setUploading(false);
          toast('Failed to update your profile');
        }
      } else {
        setUploading(false);
        toast('Failed to upload the selected image');
      }
    },
  });

  const handleEditProfile = () => {
    navigate('/profile/general');
  };

  const handleEditBehavior = () => {
    navigate('/profile/behavior');
  };

  const handleEditZealyId = () => {
    navigate('/profile/zealy');
  };

  const handleCopyWalletAddress = () => {
    if (address) {
      copy(address);
      toast('Wallet address copied to clipboard');
    }
  };

  const handleCopyReferralUrl = () => {
    if (referralCode) {
      copy(``);
      toast('Your referral URL copied to clipboard');
    }
  };

  const handleShowReferralInfo = () => {
    AlertDialog.open({
      title: 'Your Referral link is personal & unique!',
      message:
        'Invite your friends to Oily & get rewarded when they complete the first challenge!',
      canDismiss: true,
      cancelLabel: 'Close',
    });
  };

  const handleDisconnect = () => {
    disconnectWallet();
    dispatch(logout());
  };

  return (
    <Scrollbars
      style={{
        width: '100%',
        height: '100vh',
      }}
    >
      <ProfileLayout title="Settings" style="light">
        <div className="flex flex-row justify-center">
          <div className="w-full max-w-[600px] px-8">
            <div className="mt-6 flex flex-row justify-center">
              <div className="relative flex h-[160px] w-[160px] items-center justify-center rounded-full bg-royalBlueDark p-2">
                {avatar ? (
                  <img
                    src={avatar ?? ''}
                    className="aspect-square h-full w-full rounded-full object-cover"
                  />
                ) : (
                  <ImageIcon className="text-spanishGray" />
                )}
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <FilledButton
                    variant="beauBlue"
                    endIcon={CloudUploadIcon}
                    loading={uploading}
                    className="absolute bottom-[-10px] left-1/2 w-[130px] -translate-x-1/2"
                    size="small"
                    enableEventPropagation
                  >
                    Upload image
                  </FilledButton>
                </div>
              </div>
            </div>
            <h1 className="mt-14 text-4xl font-semibold leading-snug text-raisinBlack">
              Hi, {username}
            </h1>
            <p className="mt-2 text-lg leading-snug text-raisinBlack">
              As your first challenge, complete this onboarding over 3 lessons
              and earn 90 XP:
            </p>
            <div className="pt-8">
              <div className="relative flex h-[86px] w-full flex-row">
                <StepBadge status="completed">
                  <StepConnector color="green" />
                </StepBadge>
                <div className="ml-4 flex-1">
                  <h6 className="font-bold leading-relaxed text-charcoal">
                    Connect Telegram
                  </h6>
                  <div className="mt-1 text-sm leading-relaxed text-charcoal">
                    @{telegramId}
                  </div>
                </div>
              </div>
              <div className="relative flex min-h-[86px] w-full flex-row">
                <StepBadge
                  status={
                    username && age && address && gender && country
                      ? 'completed'
                      : 'pending'
                  }
                >
                  <StepConnector
                    color={
                      username && age && address && gender && country
                        ? 'green'
                        : 'gray'
                    }
                  />
                </StepBadge>
                <div className="ml-4 flex-1">
                  <div className="flex flex-row items-center">
                    <h6
                      className={clsx([
                        'mr-4 flex-1 font-bold leading-relaxed',
                        username && age && address && gender && country
                          ? 'text-charcoal'
                          : 'text-green600',
                      ])}
                    >
                      Profile
                    </h6>
                    <XPIndicator
                      current={30}
                      iconClassName="fill-charcoal"
                      textClassName="text-charcoal"
                      containerClassName="mr-4"
                    />
                    <IconButton
                      icon={EditIcon}
                      variant="ghost-dark"
                      size="small"
                      onClick={handleEditProfile}
                    />
                  </div>
                  <div
                    className={clsx([
                      'mt-1 pb-8 text-sm leading-relaxed',
                      username && age && address && gender && country
                        ? 'text-charcoal'
                        : 'text-spanishGray',
                    ])}
                  >
                    {username && age && address && gender && country ? (
                      <>
                        <div>{username}</div>
                        <div className="mt-1">
                          {age === '18_35'
                            ? '18 to 35 years old'
                            : age === '35_60'
                              ? '35 to 60 years old'
                              : age === 'gt_60'
                                ? 'more than 60 years old'
                                : ''}
                        </div>
                        <div className="mt-1">
                          {city ? city : ''}{' '}
                          {country ? getCountryData(country as any).name : ''}
                        </div>
                        <div className="mt-1">
                          {gender === 'male' && 'Male'}
                          {gender === 'female' && 'Female'}
                          {gender === 'other' && 'Other'}
                        </div>
                      </>
                    ) : (
                      'Undefined'
                    )}
                  </div>
                </div>
              </div>
              <div className="relative flex min-h-[86px] w-full flex-row">
                <StepBadge
                  status={
                    ucoProductionPeriod
                      ? 'completed'
                      : username && age && address && country && gender
                        ? 'pending'
                        : 'inactive'
                  }
                >
                  <StepConnector
                    color={
                      ucoOilToCook && ucoProductionPeriod && ucoRecycling
                        ? 'green'
                        : 'gray'
                    }
                  />
                </StepBadge>
                <div className="ml-4 flex-1">
                  <div className="flex flex-row items-center">
                    <h6
                      className={clsx([
                        'mr-4 flex-1 font-bold leading-relaxed',
                        ucoProductionPeriod
                          ? 'text-charcoal'
                          : username && age && address && country && gender
                            ? 'text-green600'
                            : 'text-spanishGray',
                      ])}
                    >
                      UCO Details
                    </h6>
                    {username && age && address && country && gender && (
                      <>
                        <XPIndicator
                          current={30}
                          iconClassName="fill-charcoal"
                          textClassName="text-charcoal"
                          containerClassName="mr-4"
                        />
                        <IconButton
                          icon={EditIcon}
                          variant="ghost-dark"
                          size="small"
                          onClick={handleEditBehavior}
                        />
                      </>
                    )}
                  </div>
                  <div
                    className={clsx([
                      'mt-1 pb-8 text-sm leading-relaxed',
                      username && age && address && gender && country
                        ? 'text-charcoal'
                        : 'text-spanishGray',
                    ])}
                  >
                    <div
                      className={
                        ucoProductionPeriod
                          ? 'text-raisinBlack'
                          : 'text-spanishGray'
                      }
                    >
                      {ucoProductionPeriod
                        ? ucoProductionPeriod === 'lt_1'
                          ? '0 to 1 litres of cooking oil per month'
                          : ucoProductionPeriod === '1_5'
                            ? '1 to 5 litres of cooking oil per month'
                            : ucoProductionPeriod === '5_10'
                              ? '5 to 10 litres of cooking oil per month'
                              : 'More than 10 litres of cooking oil per month'
                        : 'Undefined'}
                    </div>
                    {ucoProductionPeriod && (
                      <>
                        <div
                          className={clsx([
                            'mt-2',
                            ucoOilToCook
                              ? 'text-raisinBlack'
                              : 'text-spanishGray',
                          ])}
                        >
                          {ucoOilToCook === true || ucoOilToCook === false
                            ? ucoOilToCook === true
                              ? 'Uses cooking oil to prepare food'
                              : 'Does not use cooking oil to prepare food'
                            : 'Undefined'}
                        </div>
                        <div
                          className={clsx([
                            'mt-2',
                            ucoRecycling
                              ? 'text-raisinBlack'
                              : 'text-spanishGray',
                          ])}
                        >
                          {ucoRecycling === true || ucoRecycling === false
                            ? ucoRecycling === true
                              ? 'Recycles used cooking oil'
                              : 'Does not recycles used cooking oil'
                            : 'Undefined'}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="relative flex min-h-[86px] w-full flex-row">
                <StepBadge
                  status={
                    zealyId
                      ? 'completed'
                      : ucoProductionPeriod
                        ? 'pending'
                        : 'inactive'
                  }
                />
                <div className="ml-4 flex-1">
                  <div className="flex flex-row items-center">
                    <h6
                      className={clsx([
                        'mr-4 flex-1 font-bold leading-relaxed',
                        zealyId
                          ? 'text-charcoal'
                          : ucoProductionPeriod
                            ? 'text-green600'
                            : 'text-spanishGray',
                      ])}
                    >
                      Zealy ID
                    </h6>
                    {ucoProductionPeriod && (
                      <>
                        <XPIndicator
                          current={30}
                          iconClassName="fill-charcoal"
                          textClassName="text-charcoal"
                          containerClassName="mr-4"
                        />
                        <IconButton
                          icon={EditIcon}
                          variant="ghost-dark"
                          size="small"
                          onClick={handleEditZealyId}
                        />
                      </>
                    )}
                  </div>
                  <div
                    className={clsx([
                      'mt-1 text-sm leading-relaxed',
                      ucoProductionPeriod
                        ? 'text-charcoal'
                        : 'text-spanishGray',
                    ])}
                  >
                    <div
                      className={
                        zealyId ? 'text-raisinBlack' : 'text-spanishGray'
                      }
                    >
                      {zealyId ? zealyId : 'Undefined'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="flex flex-row items-center justify-between">
                <div className="font-semibold text-raisinBlack">
                  Referral URL
                </div>
                <IconButton
                  icon={InfoIcon}
                  variant="ghost-dark"
                  size="small"
                  onClick={handleShowReferralInfo}
                />
              </div>
              <div
                className={clsx({
                  'mr-20 mt-1 flex flex-row break-words leading-normal': 1,
                  'text-spanishGray': !referralCode,
                  'text-raisinBlack': !!referralCode,
                })}
              >
                {referralCode
                  ? `${window.location.origin}/referral?code=${referralCode}`
                  : 'Undefined'}
                {referralCode ? (
                  <IconButton
                    icon={InfoIcon}
                    variant="ghost-dark"
                    size="small"
                    onClick={handleCopyReferralUrl}
                  />
                ) : null}
              </div>
            </div>
            <div className="mt-8">
              <div className="font-semibold text-raisinBlack">
                Wallet Address
              </div>
              <div className="mb-2 mt-4 flex flex-row">
                <div className="rounded-lg bg-raisinBlack bg-opacity-60 px-4 py-1 text-sm font-medium text-white">
                  {chain === 'vechain' && 'Vechain Network'}
                  {chain === 'xdc' && 'XDC Network'}
                </div>
              </div>
              <div
                className={clsx({
                  'mr-20 mt-1 break-words leading-normal': 1,
                  'flex w-full flex-row': 1,
                  'text-spanishGray': !address,
                  'text-raisinBlack': !!address,
                })}
              >
                <span className="mr-4 flex-1 break-all">
                  {address ? address : 'Undefined'}
                </span>
                <IconButton
                  icon={CopyIcon}
                  variant="ghost-dark"
                  size="small"
                  onClick={handleCopyWalletAddress}
                />
              </div>
            </div>
            <div className="mt-8">
              <FilledButton
                variant="pink"
                startIcon={Link2OffIcon}
                onClick={handleDisconnect}
              >
                Disconnect Wallet
              </FilledButton>
            </div>
            <div className="h-32" />
          </div>
        </div>
      </ProfileLayout>
    </Scrollbars>
  );
};

const StepBadge: React.FC<{
  status: 'completed' | 'pending' | 'inactive';
  children?: React.ReactNode;
}> = ({ status, children }) => {
  return (
    <div
      className={clsx([
        'size-[40px] rounded-full',
        'flex flex-row items-center justify-center',
        status === 'completed' && 'bg-green600',
        status === 'pending' && 'border-2 border-green600',
        status === 'inactive' && 'border-2 border-spanishGray',
      ])}
    >
      {status === 'completed' && <CheckIcon className="text-white" />}
      {status === 'pending' && (
        <div className="size-[16px] rounded-full bg-green600" />
      )}
      {children}
    </div>
  );
};

const StepConnector: React.FC<{
  color: 'green' | 'gray';
}> = ({ color }) => {
  return (
    <div
      className={clsx([
        'absolute left-[20px] top-[40px] min-h-[48px] w-[3px] -translate-x-1/2',
        color === 'green' ? 'bg-green600' : 'bg-spanishGray',
      ])}
      style={{
        height: 'calc(100% - 40px)',
      }}
    />
  );
};
