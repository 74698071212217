import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IAppState {
  version: string;
  isAppReady: boolean;
  isFirstLaunch: boolean;

  summary: {
    totalXp: number;
    totalChapters: number;
  };

  lastRequestUCOInfoModalShownAt: string | null;
  disableRequestUCOInfoModal: boolean;

  m2eCreateWmoXp: number;
  m2eCreatePlaceXp: number;
}

const initialState: IAppState = {
  version: '1.2',
  isAppReady: false,
  isFirstLaunch: true,

  summary: {
    totalXp: 0,
    totalChapters: 0,
  },

  lastRequestUCOInfoModalShownAt: null,
  disableRequestUCOInfoModal: false,

  m2eCreatePlaceXp: 0,
  m2eCreateWmoXp: 0,
};

export const appSlice = createSlice({
  name: 'app',
  initialState: initialState as IAppState,
  reducers: {
    setAppState(state, action: PayloadAction<IAppState>) {
      return action.payload;
    },
    setL2ESummary(state, action: PayloadAction<Partial<IAppState>>) {
      if (action.payload.summary) {
        state.summary = action.payload.summary;
      }
    },
    setM2ESummary(state, action: PayloadAction<Partial<IAppState>>) {
      if (action.payload.m2eCreatePlaceXp) {
        state.m2eCreatePlaceXp = action.payload.m2eCreatePlaceXp;
      }
      if (action.payload.m2eCreateWmoXp) {
        state.m2eCreateWmoXp = action.payload.m2eCreateWmoXp;
      }
    },
    updateAppState(state, action: PayloadAction<Partial<IAppState>>) {
      return { ...state, ...action.payload };
    },
    setLastRequestUCOInfoModalShownAt(state, action: PayloadAction<string>) {
      console.log(action.payload);
      state.lastRequestUCOInfoModalShownAt = action.payload;
    },
    setDisableRequestUCOInfoModal(state, action: PayloadAction<boolean>) {
      console.log(action.payload);
      state.disableRequestUCOInfoModal = action.payload;
    },
  },
});

export const {
  setAppState,
  updateAppState,
  setL2ESummary,
  setM2ESummary,
  setLastRequestUCOInfoModalShownAt,
  setDisableRequestUCOInfoModal,
} = appSlice.actions;
